import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import {
  TAG_MANAGEMENT,
  TAG_MANAGEMENT_PV,
  TAG_MANAGEMENT_CONTENT_CATEGORY,
  TEMPLATE_CSV_TAG_MANAGEMENT_PV,
  TEMPLATE_CSV_TAG_MANAGEMENT_PV_LOG,
  TEMPLATE_CSV_TAG_MANAGEMENT_CONTENT_CATEGORY,
} from 'services/routes/constants';
import {
  TABS,
  TAG_MANAGEMENT_TAB,
  MODAL_ID,
  PV_MAXIMUM_LINE_CSV,
  FIELD_IMPORT_CSV_PV,
  FIELD_IMPORT_CSV_CONTENT_CATEGORY,
  CONTENT_CATEGORY_MAXIMUM_LINE_CSV,
} from 'domain/tag-management/consts';
import { saveAs } from 'services/utils';
import { handleErrorResponse } from 'services/tag-management/tagManagementServices';
import useCheckPermissionLog from 'services/custom-hooks/useCheckPermissionLog';
import useModalController from 'services/custom-hooks/useModalController';
import useToggleDownload from 'services/custom-hooks/useToggleDownload';
import filterSelectors from 'store/filters/selectors';
import tagSelectors from 'store/tag-management/selectors';
import tagActions from 'store/tag-management/actions';
import loggerActions from 'store/logger/actions';
import masterDataActions from 'store/master-data/actions';
import { getAgentFlag } from 'store/auth/selectors';
import { FormWrapper } from 'views/molecules/Form';
import Tabs from 'views/molecules/Tabs';
import ControlBar from 'views/molecules/control-bar';
import EbisPagination from 'views/molecules/stateless-pagination';
import GridTableTemplate from 'views/templates/GridTableTemplate';
import DeleteConfirmModal from 'views/molecules/modal/DeleteConfirmModal';
import ViewDetailModal from 'views/pages/tag-management/components/ViewDetail/ViewDetailModal';
import CommonTag from 'views/pages/tag-management/components/CommonTag';
import ConversionSettingsModal from 'views/pages/tag-management/components/ConversionSettingsModal';
import PageViewSettingsModal from 'views/pages/tag-management/components/PageViewSettingsModal';
import ContentCategoryCsvUploadModal from 'views/molecules/modal/ContentCategoryCsvUploadModal';
import UploadCsvModal from 'views/organism/UploadCsvModal';
import UploadSelectors from 'store/upload/selectors';
import {
  STATUS_ERROR,
  STATUS_FAIL,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
} from 'store/helper';
import { CSV_DOWNLOAD_TYPE } from 'domain/consts';
import ContentCategorySettingModal from './components/ContentCategorySettingModal';
import SortListContentCategoryModal from './components/SortListContentCategoryModal';

import './tag-management.scss';

const { CREATE, UPDATE } = MODAL_ID;

function TagManagementContainer() {
  const dispatch = useDispatch();
  const { hasContractLog } = useCheckPermissionLog();
  const tabs = TABS.filter(
    (tab) => tab.key === TAG_MANAGEMENT_TAB.CV || hasContractLog
  );
  const {
    isLoading,
    currentTab,
    headers,
    dataList,
    pagination,
    sort,
    detail,
    statusDetail,
    errorsDetail,
    commonTag,
  } = useSelector(tagSelectors.getStates, shallowEqual);

  const {
    list: listDataConfirm,
    statusUpload: isUploading,
    statusOpenConfirmUpload: isOpenConfirmUploadModal,
  } = useSelector(tagSelectors.getStatesUpload, shallowEqual);

  const [contentCategory, filters, selectedRows] = [
    useSelector(tagSelectors.getContentCategory, shallowEqual),
    useSelector(filterSelectors.getSettings, shallowEqual),
    useSelector(tagSelectors.getSelectedRows, shallowEqual),
  ];
  const isUserAgent = useSelector(getAgentFlag, shallowEqual);
  const statusCsv = useSelector(UploadSelectors.getStatus, shallowEqual);
  const {
    contentCategories: { data: contentCategories },
    statusContentCategories,
  } = useSelector(tagSelectors.getStatesContentCategories, shallowEqual);

  const [infoDelete, setInfoDelete] = useState({
    isLoading: false,
    title: '',
    content: '',
    force: '',
    items: [],
    subContent: '',
    hideText: '',
    isShowButtonSubmit: true,
    subItems: [],
    subItemsNotDelete: [],
    subItemsLineAddFriendNotDelete: [],
    callback: () => {},
  });

  const [errorCsv, setErrorCsv] = useState([]);
  const { openModal, closeModals, isOpen } = useModalController({
    ids: Object.values(MODAL_ID),
  });

  const isOpenSetting = isOpen(CREATE) || isOpen(UPDATE);
  const isContentCategoryTab =
    currentTab === TAG_MANAGEMENT_TAB.CONTENT_CATEGORY;

  const getList = useCallback(() => {
    dispatch(tagActions.getList());
  }, [dispatch]);

  const searchContentCategory = useCallback(
    (search = '') => {
      dispatch(masterDataActions.searchContentCategory({ search }));
    },
    [dispatch]
  );

  const createContentCategory = useCallback(
    (value) => {
      dispatch(
        tagActions.createContentCategory({ content_category_name: value })
      );
    },
    [dispatch]
  );

  const handleSort = useCallback(
    (sortColumn) => {
      const { field, direction } = sortColumn;
      dispatch(tagActions.updateSort(field, direction));
      getList();
    },
    [dispatch, getList]
  );

  const handleChangePage = useCallback(
    (currentPage) => {
      dispatch(tagActions.updatePage(currentPage));
      getList();
    },
    [dispatch, getList]
  );

  const handleResponse = useCallback(
    (handleError = () => {}) => ({ action, isError = false, errors = [] }) => {
      // Handle response when create/update/delete
      const {
        title,
        content,
        isCloseModal,
        isErrorCommon,
        isErrorSystem,
        isReloadList,
        isErrorConflictData,
      } = handleErrorResponse(currentTab, action, isError, errors);

      const callback = isReloadList ? getList : () => {};
      if (isCloseModal) {
        closeModals();
      }

      if (isErrorSystem) return;

      if (isErrorCommon) {
        dispatch(
          loggerActions.logConfirmModal({
            title,
            content,
            callback,
            confirmText: isErrorConflictData ? '閉じる' : 'OK',
            confirmBtnType: isErrorConflictData ? 'link' : 'secondary',
          })
        );
      } else if (isReloadList) {
        getList();
      } else {
        handleError(errors);
      }
    },
    [dispatch, closeModals, getList, currentTab]
  );

  const handleViewDetail = (pageId) => {
    dispatch(tagActions.getDetail(pageId, handleResponse()));
    openModal(MODAL_ID.VIEW_DETAIL);
  };

  const handleSelectRow = useCallback(
    (ids) => dispatch(tagActions.selectRows(ids)),
    [dispatch]
  );

  const handleSubmitDelete = useCallback(
    (listItemDelete) => () => {
      setInfoDelete((prevInfo) => ({
        ...prevInfo,
        isLoading: true,
      }));
      dispatch(tagActions.deleteItems(listItemDelete, handleResponse()));
    },
    [dispatch, handleResponse]
  );

  const handleShowUploadModal = (file) => {
    if (file) {
      dispatch(
        tagActions.uploadCsv({
          screenId:
            currentTab === TAG_MANAGEMENT_TAB.PV
              ? TAG_MANAGEMENT_PV
              : TAG_MANAGEMENT_CONTENT_CATEGORY,
          file,
        })
      );
    }
  };

  const handleConfirmDelete = useCallback(() => {
    let titleDelete = 'ページIDの削除を実行してもよろしいですか？';
    let contentDelete = '';
    let forceDelete = '';
    const itemsDelete = [];
    const itemsNotDelete = [];
    const itemsCapiNotDelete = [];
    const itemsLineAddFriendNotDelete = [];
    let isShowButtonSubmit = true;
    let listItemDelete = [];
    let contentItemNotDelete = '';
    let contentItemNotDeleteCapi = '';
    let hideText = 'キャンセル';

    if (currentTab === TAG_MANAGEMENT_TAB.CV) {
      const listItemSeleted = dataList.filter((row) =>
        selectedRows.includes(row.page_id)
      );
      listItemSeleted.forEach((item, index) => {
        if (item.has_postback && isUserAgent) {
          itemsNotDelete.push({
            order: index,
            value: `${item.page_title}（対応ページID = ${item.page_id}）`,
          });
        } else if (item.has_capi) {
          itemsCapiNotDelete.push({
            order: index,
            value: `${item.page_title}（対応ページID = ${item.page_id}）`,
          });
        } else if (item.has_line_add_friend) {
          itemsLineAddFriendNotDelete.push({
            order: index,
            value: `${item.page_title}（対応ページID = ${item.page_id}）`,
          });
        } else {
          itemsDelete.push({
            order: index,
            value: `${item.page_title}（対応ページID = ${item.page_id}）`,
          });
          listItemDelete.push(item.page_id);
        }
      });
      if (itemsDelete.length > 0) {
        titleDelete = 'ページIDを削除しますか？';
        contentDelete = (
          <>
            <span className="color-bad">
              下記のコンバージョン名に紐づくデータが分析画面とダッシュボードから削除
            </span>
            されます。 <br />
            一度削除した設定は二度と復元できません。
          </>
        );
        forceDelete =
          'コンバージョンに紐づくデータが削除されても問題ありません';
        if (itemsNotDelete.length > 0) {
          contentItemNotDelete = (
            <div className="pt-10">
              以下のページIDは
              <span className="font-weight-bold">
                ポストバック設定がされているため、削除されません。
              </span>
            </div>
          );
        }
        if (itemsCapiNotDelete.length > 0) {
          contentItemNotDeleteCapi = (
            <div className="pt-10">
              以下のページIDは
              <span className="font-weight-bold">
                CAPiCO設定がされているため、削除されません。
              </span>
            </div>
          );
        }
      }
      if (itemsDelete.length < 1) {
        isShowButtonSubmit = false;
        hideText = '閉じる';
        titleDelete = 'ページIDの削除が行えません';
        if (itemsNotDelete.length > 0) {
          contentItemNotDelete = (
            <>
              以下のページIDはポストバック設定がされているため、削除されません。
              <br />
              設定を解除してから、再度削除を行ってください。
            </>
          );
        }
        if (itemsCapiNotDelete.length > 0) {
          contentItemNotDelete = (
            <>
              以下のページIDはCAPiCO設定がされているため、削除されません。
              <br />
              設定を解除してから、再度削除を行ってください
            </>
          );
        }
        if (itemsLineAddFriendNotDelete.length > 0) {
          contentItemNotDelete = (
            <>
              以下のページIDはLINE友だち追加計測設定がされているため、削除されません。
              <br />
              設定を解除してから、再度削除を行ってください
            </>
          );
        }
        if (
          itemsCapiNotDelete.length > 0 &&
          itemsLineAddFriendNotDelete.length > 0
        ) {
          contentItemNotDelete = (
            <>
              以下のページIDはCAPiCO設定とLINE友だち追加計測設定がされているため、削除されません。
              <br />
              設定を解除してから、再度削除を行ってください
            </>
          );
        }
      }
    } else if (currentTab === TAG_MANAGEMENT_TAB.PV) {
      contentDelete =
        'ページIDを削除すると、対象のページIDで計測したPV数やUU数などが閲覧できなくなります。ページIDを削除してもよろしいですか？';
      titleDelete = 'PV計測タグを削除しますか？';
      listItemDelete = selectedRows;
    } else {
      titleDelete = 'コンテンツカテゴリを削除しますか？';
      listItemDelete = selectedRows;
    }

    setInfoDelete({
      isLoading: false,
      title: `${titleDelete}`,
      content: contentDelete,
      force: forceDelete,
      items: itemsDelete,
      subContent: contentItemNotDelete,
      subItems: itemsNotDelete,
      subItemsNotDelete: itemsCapiNotDelete,
      subItemsLineAddFriendNotDelete: itemsLineAddFriendNotDelete,
      contentItemNotDelete: contentItemNotDeleteCapi,
      isShowButtonSubmit,
      hideText,
      callback: handleSubmitDelete(listItemDelete),
    });

    openModal(MODAL_ID.CONFIRM_DELETE);
  }, [
    currentTab,
    dataList,
    handleSubmitDelete,
    openModal,
    selectedRows,
    isUserAgent,
  ]);

  const handleHideConfirmUpload = () => {
    dispatch(tagActions.setStatusOpenUploadConfirm(false));
  };
  const handleConfirmSubmitCsv = () => {
    dispatch(tagActions.submitCsv());
  };
  const handleOpenCommonTag = () => openModal(MODAL_ID.COMMON_TAG);

  const handleOpenSettings = useCallback(
    (id) => {
      if (id) {
        // get new data detail
        dispatch(tagActions.getDetail(id, handleResponse()));
        openModal(UPDATE);
      } else {
        // Clear old data if any
        dispatch(tagActions.setDataDetail({}));
        openModal(CREATE);
      }
    },
    [dispatch, openModal, handleResponse]
  );

  const handleCloseSettings = useCallback(
    (isReload = false) => {
      closeModals();
      // Clear current data if any
      dispatch(tagActions.setDataDetail({}));
      if (isReload) {
        getList();
      }
    },
    [dispatch, getList, closeModals]
  );

  const handleSubmitSettings = useCallback(
    (data, callback, id) => {
      if (id) {
        dispatch(tagActions.update(id, data, handleResponse(callback)));
      } else {
        dispatch(tagActions.create(data, handleResponse(callback)));
      }
    },
    [dispatch, handleResponse]
  );

  const handleGetContentCategories = useCallback(
    (params) => {
      dispatch(tagActions.getContentCategories(params));
    },
    [dispatch]
  );

  const handleOpenSortList = () => {
    handleGetContentCategories({});
    setErrorCsv([]);
    openModal(MODAL_ID.SORT_LIST);
  };

  const handleCloseSortList = () => {
    closeModals();
    dispatch(tagActions.setDataContentCategories({}));
  };

  const handleSubmitSortList = (originData, data) => {
    dispatch(
      tagActions.updateRankPriorityContentCategory(
        originData,
        data,
        handleResponse()
      )
    );
  };

  const handleDownloadTemplateCsv = () => {
    let uri = '';
    if (currentTab === TAG_MANAGEMENT_TAB.CONTENT_CATEGORY) {
      uri = TEMPLATE_CSV_TAG_MANAGEMENT_CONTENT_CATEGORY;
    } else {
      uri = hasContractLog
        ? TEMPLATE_CSV_TAG_MANAGEMENT_PV_LOG
        : TEMPLATE_CSV_TAG_MANAGEMENT_PV;
    }

    const filename = uri.split('/').pop();

    saveAs(uri, filename);
  };

  const handleOpenUploadCsv = () => openModal(MODAL_ID.UPLOAD_CSV);

  const handleDowloadFileCsv = () => {
    dispatch(tagActions.downloadCsv(CSV_DOWNLOAD_TYPE.CONTENT_CATEGORY));
  };

  const [toggleCsv] = useToggleDownload(TAG_MANAGEMENT);

  useEffect(() => {
    toggleCsv(!isLoading && pagination.totalItems > 0);
  }, [toggleCsv, isLoading, pagination.totalItems]);

  useEffect(() => {
    getList();
  }, [getList, currentTab, filters]);

  useEffect(() => {
    if ([STATUS_SUCCEEDED, STATUS_ERROR].includes(statusCsv?.status)) {
      closeModals();
    }
    if (statusCsv.status === STATUS_FAIL) {
      setErrorCsv(statusCsv.error);
    }
  }, [statusCsv.error, statusCsv.status]);

  return (
    <div className="tag-management h-100">
      <GridTableTemplate
        isSortable
        isLoading={isLoading}
        pageId={TAG_MANAGEMENT}
        currentTab={currentTab}
        variant={currentTab}
        header={headers}
        rows={dataList}
        sortColumn={sort}
        onSortColumn={handleSort}
        onViewDetail={handleViewDetail}
        onSelectRow={handleSelectRow}
        onEditRow={handleOpenSettings}
        tableControl={[
          <Tabs items={tabs} />,
          <div className="settings">
            <ControlBar
              page={
                currentTab === TAG_MANAGEMENT_TAB.PV
                  ? TAG_MANAGEMENT_PV
                  : TAG_MANAGEMENT_CONTENT_CATEGORY
              }
              currentItems={pagination.totalItems}
              maximumItems={pagination.maxItems}
              checkedItemsNum={selectedRows.length}
              handleShowSettingModal={handleOpenSettings}
              onDownloadTemplate={handleDownloadTemplateCsv}
              handleShowUploadModal={
                currentTab === TAG_MANAGEMENT_TAB.PV && handleShowUploadModal
              }
              csvConfig={{
                isMergeError: true,
                field: FIELD_IMPORT_CSV_PV,
                maxLine: PV_MAXIMUM_LINE_CSV,
              }}
              setShowConfirmModal={handleConfirmDelete}
              handleSortList={
                currentTab === TAG_MANAGEMENT_TAB.CONTENT_CATEGORY &&
                handleOpenSortList
              }
            />
            {!isContentCategoryTab && (
              <Button
                variant="secondary"
                size="xs"
                className="btn--settings float-right"
                onClick={handleOpenCommonTag}
              >
                共通タグを表示
              </Button>
            )}
            <div className="paginations">
              <EbisPagination
                visiblePageNum={currentTab !== TAG_MANAGEMENT_TAB.CV}
                totalItems={pagination.totalItems}
                currentPage={pagination.currentPage}
                note={pagination.note}
                changePage={handleChangePage}
              />
            </div>
          </div>,
        ]}
      />
      {isOpen(MODAL_ID.VIEW_DETAIL) && (
        <ViewDetailModal
          openDetail={isOpen(MODAL_ID.VIEW_DETAIL)}
          handleCloseModal={closeModals}
          infoDetail={detail}
          currentTab={currentTab}
          isLoading={statusDetail}
          errors={errorsDetail}
        />
      )}
      {isOpen(MODAL_ID.COMMON_TAG) && (
        <CommonTag
          show={isOpen(MODAL_ID.COMMON_TAG)}
          handleCloseModal={closeModals}
          commonTag={commonTag}
        />
      )}
      {isOpenSetting && currentTab === TAG_MANAGEMENT_TAB.CV && (
        <FormWrapper>
          <ConversionSettingsModal
            isLoading={statusDetail}
            isEditing={isOpen(UPDATE)}
            detail={detail}
            onSubmit={handleSubmitSettings}
            onHide={handleCloseSettings}
          />
        </FormWrapper>
      )}
      {isOpenSetting && currentTab === TAG_MANAGEMENT_TAB.PV && (
        <PageViewSettingsModal
          isLoading={statusDetail}
          isEditing={isOpen(UPDATE)}
          detail={detail}
          contentCategory={contentCategory}
          onSearchContentCategory={searchContentCategory}
          onCreateContentCategory={createContentCategory}
          onSubmit={handleSubmitSettings}
          onHide={handleCloseSettings}
        />
      )}
      {isOpenSetting && currentTab === TAG_MANAGEMENT_TAB.CONTENT_CATEGORY && (
        <ContentCategorySettingModal
          isShow={isOpenSetting}
          isAdd={isOpen(CREATE)}
          loading={statusDetail}
          detail={detail}
          onSubmit={handleSubmitSettings}
          onHide={handleCloseSettings}
        />
      )}
      {isOpen(MODAL_ID.CONFIRM_DELETE) && (
        <DeleteConfirmModal
          isShow
          isLoading={infoDelete.isLoading}
          title={infoDelete.title}
          content={infoDelete.content}
          items={infoDelete.items}
          subContent={infoDelete.subContent}
          subItems={infoDelete.subItems}
          subItemsNotDelete={infoDelete.subItemsNotDelete}
          subItemsLineAddFriendNotDelete={
            infoDelete.subItemsLineAddFriendNotDelete
          }
          contentItemNotDelete={infoDelete.contentItemNotDeleteCapi}
          forceText={infoDelete.force}
          hideText={infoDelete.hideText}
          onSubmit={infoDelete.callback}
          isShowButtonSubmit={infoDelete.isShowButtonSubmit}
          onHide={closeModals}
        />
      )}
      {isOpenConfirmUploadModal && (
        <ContentCategoryCsvUploadModal
          isShow={isOpenConfirmUploadModal}
          loading={isUploading}
          list={listDataConfirm}
          onHide={handleHideConfirmUpload}
          onSubmit={handleConfirmSubmitCsv}
        />
      )}
      {isOpen(MODAL_ID.SORT_LIST) && (
        <SortListContentCategoryModal
          isShow
          loading={statusContentCategories}
          onSubmit={handleSubmitSortList}
          onHide={handleCloseSortList}
          list={contentCategories}
          handleOpenUploadCsv={handleOpenUploadCsv}
        />
      )}
      {isOpen(MODAL_ID.UPLOAD_CSV) && (
        <UploadCsvModal
          show
          loading={statusCsv.status === STATUS_LOADING}
          errors={errorCsv}
          onHide={handleCloseSettings}
          onShowHistory={null}
          onSend={handleShowUploadModal}
          screenId={TAG_MANAGEMENT_CONTENT_CATEGORY}
          config={{
            isMergeError: true,
            field: FIELD_IMPORT_CSV_CONTENT_CATEGORY,
            maxLine: CONTENT_CATEGORY_MAXIMUM_LINE_CSV,
          }}
          onDownloadTemplate={handleDowloadFileCsv}
          onBack={handleOpenSortList}
        />
      )}
    </div>
  );
}

export default TagManagementContainer;
