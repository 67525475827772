import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
  func,
  bool,
  array,
  string,
  oneOfType,
  number,
  shape,
  node,
} from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
  getTemplatePathByScreenId,
  getUploadModalTitle,
  getUploadModalNote,
  getNoteUpload,
  getSubjectStr,
  getDowloadTitle,
} from 'domain/utils';
import { CSV_MAX_SIZE, UNLIMITED_VALUE } from 'domain/consts';
import {
  msgValidateFileCsvError,
  msgValidateContentCsvError,
  fields,
} from 'domain/ltv/settings/errorMessages';
import {
  msgLineCsvError,
  msgMediaFileCsvError,
} from 'domain/ad-management/media-group/errorMessages';
import {
  contentCategoryCsvfields,
  msgLineTagManagementCsvError,
} from 'domain/tag-management/errorMessages';
import {
  requiredUpload,
  csvFile,
  maxFileSize,
  minFileSize,
  checkOverLineAccepted,
} from 'services/validations/commonValidations';
import * as messageError from 'services/validations/messageErrorByCodeForCsv';
import {
  checkFormatErrorByLine,
  getErrorMessageByCode,
  formatErrorValidate,
  formatErrorValidateMessage,
} from 'services/utils';
import {
  COLUMNS_TABLE_CONFIRM_CONTENT_INVALID,
} from 'services/consts';
import FileUpload from 'views/molecules/FileUpload';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import TableConfirmDetail from 'views/organism/TableConfirmDetail';
import {
  LTV_SETTINGS_ORDER,
  LTV_SETTINGS_PRODUCT,
  LTV_SETTINGS_OFFER,
  LTV_SETTINGS_CONDITION,
  LTV_SETTINGS_AD,
  AD_MANAGEMENT_MEDIA_TYPE,
  AD_MANAGEMENT_AD_GROUP_1,
  AD_MANAGEMENT_AD_GROUP_2,
  TAG_MANAGEMENT_CONTENT_CATEGORY,
} from 'services/routes/constants';
import useCheckPermissionLog from 'services/custom-hooks/useCheckPermissionLog';
import { getColumnsTableConfirm } from 'services/tag-management/tagManagementServices';
import './UploadCsvModal.scss';
import { shallowEqual, useSelector } from 'react-redux';
import masterDataSelectors from 'store/master-data/selectors';
import Service from 'services/ad-management/adMediaGroupService';

const linesMediaGroup = [
  AD_MANAGEMENT_MEDIA_TYPE,
  AD_MANAGEMENT_AD_GROUP_1,
  AD_MANAGEMENT_AD_GROUP_2,
];

const linesLtv = [
  LTV_SETTINGS_ORDER,
  LTV_SETTINGS_PRODUCT,
  LTV_SETTINGS_OFFER,
  LTV_SETTINGS_CONDITION,
  LTV_SETTINGS_AD,
];

const linesTagManagement = [TAG_MANAGEMENT_CONTENT_CATEGORY];

const getMessageCsvFileErrors = (screenId) => {
  if (linesLtv.includes(screenId)) return msgValidateFileCsvError;
  if (linesMediaGroup.includes(screenId)) return msgMediaFileCsvError;
  return messageError;
};

const UploadCsvModal = ({
  show,
  onHide,
  onBack,
  onSend,
  loading,
  errors,
  screenId,
  config,
  note,
  onShowHistory,
  onDownloadTemplate,
  customSubjectText,
  uploadModalOptionNode,
  disableDefaultTemplateBtn,
  messageValidate,
}) => {
  const masterData = useSelector(masterDataSelectors.getDisplay, shallowEqual);
  const [file, setFile] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorConfirm, setErrorConfirm] = useState([]);
  const [infoFile, setInfoFile] = useState({ totalLine: 0 });

  const { hasContractLog } = useCheckPermissionLog();

  const {
    field = {},
    maxLine = UNLIMITED_VALUE,
    maxSize = CSV_MAX_SIZE,
    minSize = null,
  } = config;

  const isMergeError =
    config.isMergeError || [...linesLtv, ...linesMediaGroup].includes(screenId);

  const isTagManagement = [...linesTagManagement].includes(screenId);

  const isCheckMaxLine = maxLine > UNLIMITED_VALUE;

  const onFileUploadAccepted = (uploadedFile) => {
    setFile(uploadedFile[0]);
    if (isCheckMaxLine) {
      const reader = new FileReader();
      reader.onload = () => {
        const totalLine = reader.result.match(
          /(?:"(?:[^"]|"")*"|[^,\n]*)(?:,(?:"(?:[^"]|"")*"|[^,\n]*))*\n/g
        ).length;

        setInfoFile((prevInfo) => ({ ...prevInfo, totalLine }));
      };
      reader.readAsText(uploadedFile[0], 'Shift_JIS');
    }
    setErrorMessage(null);
    setErrorConfirm([]);
  };

  const onFileUploadClear = () => {
    setFile(null);
    setErrorMessage(null);
    setErrorConfirm([]);
  };

  const msgFileCsvError = getMessageCsvFileErrors(screenId);

  const validateFile = () => {
    const rules = [
      requiredUpload(messageError.FILE_REQUIRED),
      csvFile(messageError.FILE_EXTENSION_CHECK),
      maxFileSize(maxSize, messageValidate?.maxSize || messageError.FILE_SIZE_CHECK),
    ];
    if (minSize) {
      rules.push(
        minFileSize(minSize, messageValidate?.minSize)
      );
    }
    if (isCheckMaxLine) {
      rules.push(
        checkOverLineAccepted(
          maxLine,
          infoFile.totalLine - 1, // 1 => row header
          messageError.FILE_MAX_LINE_CHECK
        )
      );
    }
    return rules
      .map((rule) => rule({ value: file, label: 'CSV' }))
      .filter((rule) => !isEmpty(rule));
  };

  const handleSubmit = () => {
    const fileErrors = validateFile();
    if (isEmpty(fileErrors)) {
      onSend(file);
    } else {
      onFileUploadClear();
      setErrorMessage(fileErrors[0]);
    }
  };

  const handleCancel = () => {
    setFile(null);
    onHide();
  };

  const handleBack = () => {
    setFile(null);
    onBack();
  };

  useEffect(() => {
    if (isEmpty(errors)) return;
    setFile(null);
    const titlePage = customSubjectText || getSubjectStr(screenId);
    const isFormatErrorByLine = checkFormatErrorByLine(errors[0]);

    if (isFormatErrorByLine) {
      const errorCount = errors.reduce((acc, obj) => acc + obj.count, 0);
      const validate = {};

      if (isMergeError || isTagManagement) {
        validate.mainMessage = isTagManagement
          ? `並べ替えエラーが${Service.formatNumber(errorCount)}件あります。`
          : `${titlePage}登録エラーが${Service.formatNumber(
              errorCount
            )}件あります。対応方法を参考に、修正したCSVをアップロードしてください。`;

        if (linesLtv.includes(screenId)) {
          validate.lines = msgValidateContentCsvError;
          validate.fields = fields;
        } else if (linesMediaGroup.includes(screenId)) {
          validate.lines = msgLineCsvError;
          validate.fields = Service.getFieldLabel(masterData)[screenId];
        } else if (linesTagManagement.includes(screenId)) {
          validate.lines = msgLineTagManagementCsvError;
          validate.fields = contentCategoryCsvfields;
        } else {
          validate.lines = messageError;
          validate.fields = field;
        }
        validate.formattedError = formatErrorValidateMessage(
          errors,
          validate.lines,
          validate.fields
        );
      } else {
        validate.mainMessage = `${titlePage}登録エラーが${errors.length}件あります。対応方法を参考に、修正したCSVをアップロードしてください。`;

        validate.formattedError = formatErrorValidate(
          errors,
          msgValidateContentCsvError,
          fields
        );
      }
      setErrorMessage(validate.mainMessage);
      setErrorConfirm(validate.formattedError);
    } else {
      const options = { label: titlePage };
      if (
        [
          'INVALID_HEADER',
          'FILE_HEADER_INVALID',
          'FILE_HEADER_VALID_CHECK',
        ].includes(errors[0].code)
      ) {
        options.link = getTemplatePathByScreenId(screenId, hasContractLog);
      }
      if (
        errors[0].code === 'FILE_OVER_MAX_RECORD' ||
        errors[0].code === 'FILE_OVER_MAX_LINE'
      ) {
        options.length = Service.formatNumber(errors[0].metadata.param[0]);
      }
      const error = getErrorMessageByCode(errors[0], msgFileCsvError, options);
      setErrorMessage(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, screenId]);

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="w800"
      className="upload-csv-modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>
          {getUploadModalTitle(screenId, customSubjectText)}
        </Modal.Title>
        {onDownloadTemplate && (
          <Button
            variant="secondary"
            size="xs"
            className="btn-icon--with-text"
            onClick={(e) => onDownloadTemplate(e)}
          >
            <i className="far fa-arrow-to-bottom" />
            {getDowloadTitle(screenId)}
          </Button>
        )}
        {!onDownloadTemplate && !disableDefaultTemplateBtn && (
          <Button
            as="a"
            href={getTemplatePathByScreenId(screenId, hasContractLog)}
            variant="secondary"
            size="xs"
            className="btn-icon--with-text"
          >
            <i className="far fa-arrow-to-bottom" />
            CSVフォーマット
          </Button>
        )}

        {onShowHistory && (
          <Button
            as="a"
            size="xs"
            variant="link"
            className="order-history"
            onClick={(e) => onShowHistory(e)}
          >
            アップロード履歴を表示
          </Button>
        )}
        <div
          className="w-100 txt-note color-gray-dark"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: getUploadModalNote(screenId) }}
        />
      </Modal.Header>
      <Modal.Body>
        <DataSyncLoader isLoading={loading}>
          {errorMessage && (
            <div
              className="color-bad txt-standard error-message"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            />
          )}
          {!isEmpty(errorConfirm) && (
            <TableConfirmDetail
              maxContent={297}
              columns={
                isMergeError || isTagManagement
                  ? getColumnsTableConfirm(isTagManagement)
                  : COLUMNS_TABLE_CONFIRM_CONTENT_INVALID
              }
              rows={errorConfirm}
            />
          )}
          {uploadModalOptionNode}
          <FileUpload
            isClear={!file}
            onFileUploadAccepted={onFileUploadAccepted}
            onClear={onFileUploadClear}
            note={note || getNoteUpload(screenId)}
            screenId={screenId}
          />
        </DataSyncLoader>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="link"
          disabled={loading}
          onClick={handleCancel}
        >
          キャンセル
        </Button>
        {onBack && (
          <Button
            size="sm"
            variant="link"
            disabled={loading}
            onClick={handleBack}
          >
            戻る
          </Button>
        )}
        <Button
          size="sm"
          variant="secondary"
          onClick={handleSubmit}
          disabled={loading || !file}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

UploadCsvModal.propTypes = {
  show: bool,
  loading: bool,
  screenId: string,
  note: string,
  errors: oneOfType([array]),
  onSend: func,
  onHide: func,
  onBack: func,
  onShowHistory: func,
  onDownloadTemplate: func,
  customSubjectText: string,
  config: shape({
    isMergeError: bool,
    maxLine: number,
    maxSize: number,
    field: shape({}),
    minSize: number,
  }),
  uploadModalOptionNode: node,
  disableDefaultTemplateBtn: bool,
  messageValidate: shape({
    maxSize: string,
    minSize: string,
  }),
};

UploadCsvModal.defaultProps = {
  show: false,
  loading: false,
  screenId: '',
  note: '',
  errors: [],
  onSend: () => ({}),
  onHide: () => ({}),
  onBack: null,
  onShowHistory: () => ({}),
  onDownloadTemplate: () => ({}),
  customSubjectText: null,
  config: {
    isMergeError: false,
    maxLine: UNLIMITED_VALUE,
    maxSize: CSV_MAX_SIZE,
    field: {}, // { <key>: { label: '', message: {} } }
    minSize: null,
  },
  uploadModalOptionNode: <></>,
  disableDefaultTemplateBtn: false,
  messageValidate: {},
};

export default UploadCsvModal;
