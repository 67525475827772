// チャネル種別
import {
  LOG_CONTRACT_PERMISSIONS,
  AGENCY_CONTRACT_PERMISSIONS,
  MEDIA_SYNC_PERMISSIONS,
  CROSS_DEVICE_PERMISSIONS,
  LPO_PERMISSIONS,
  COST_ALLOCATION_PERMISSIONS,
  SEO_CONTRACT_DISPLAY_SEARCH_CONSOLE,
} from 'domain/permissions/contractGroups';

export const CHANNEL = 'channel_access_type'; // channel
// カテゴリ
export const CATEGORY = 'category';
// 広告グループ１
export const AD_GROUP1 = 'ad_group1';
// 広告グループ２
export const AD_GROUP2 = 'ad_group2';
// 媒体キャンペーン
export const MEDIA_SIDE_CAMPAIGN = 'media_side_campaign';
// 媒体広告グループ
export const MEDIA_SIDE_GROUP = 'media_side_group';
// (媒体シンク)媒体
export const SYNC_CATEGORY = 'sync_category';
// (媒体シンク)広告名
export const MEDIA_SIDE_AD_NAME = 'media_side_ad_name';
// (媒体シンク)広告ID
export const MEDIA_SIDE_AD_ID = 'media_side_ad_id';
// カンマ区切りの広告ID
export const OPTIONAL_AD_ID = 'optional_ad_id';
// カンマ区切りの広告ID
export const OPTIONAL_MEDIA_SIDE_AD_ID = 'optional_media_side_ad_id';

// 表示回数
export const IMPRESSION = 'impression';
// クリック／流入回数 クリック数
export const CNT_ACCESS = 'cnt_access';
// CTR
export const CTR = 'ctr';
// セッション数/UU
export const CNT_SESSION = 'cnt_session';
// SU割合
export const PER_SESSION = 'per_session';
// 総PV
export const CNT_PV = 'cnt_pv';
// PV割合
export const PER_PV = 'per_pv';
// 平均PV
export const PV_AVG = 'per_page_view'; // pv_avg
// 直帰数
export const CNT_RETURN = 'cnt_return';
// 直帰率
export const PER_RETURN = 'per_return';
// 総滞在時間
export const TOTAL_STAY_TIME = 'total_stay_time';
// 平均滞在時間
export const TOTAL_STAY_TIME_AVG = 'total_stay_time_avg';
// 総潜伏期間
export const TOTAL_LATENCY_TIME = 'total_latency_time';
// 平均潜伏期間
export const LATENCY_TIME_AVG = 'latency_time_avg';
// CV（CV別）
export const CNT_CV_DETAIL = 'cnt_direct_cv'; // cnt_cv_detail
// CV（合計）
export const CNT_CV_TOTAL = 'cv_total'; // cnt_cv_total
// CV（合計）紐づけ前
export const CV_TOTAL_CROSS_LINKED_BEFORE = 'cv_total_cross_linked_before';
// CV（合計）紐づけ後
export const CV_TOTAL_CROSS_LINKED_AFTER = 'cv_total';
// CV（合計）差分
export const CV_TOTAL_CROSS_DIFFERENCE = 'cv_total_cross_difference';
// CV（合計）比率
export const CV_TOTAL_CROSS_RATE = 'cv_total_cross_rate';
// CVR（CV別）
export const CVR_DETAIL = 'cnt_direct_rate_cv'; // cvr_detail
// CVR（合計）
export const CVR_TOTAL = 'cvr_total';
// 間接効果(2-10) 間接効果2
export const CNT_INDIRECT2 = 'cnt_indirect_2'; // cnt_indirect2
// 間接効果(2-10) 間接効果3
export const CNT_INDIRECT3 = 'cnt_indirect_3'; // cnt_indirect3
// 間接効果(2-10) 間接効果4
export const CNT_INDIRECT4 = 'cnt_indirect_4'; // cnt_indirect4
// 間接効果(2-10) 間接効果5
export const CNT_INDIRECT5 = 'cnt_indirect_5'; // cnt_indirect5
// 間接効果(2-10) 間接効果6-10
export const CNT_INDIRECT_OTHER = 'cnt_indirect_other';
// 間接効果（合計） 間接効果（合計）
export const INDIRECT_TOTAL = 'indirect_total';
// 直間比率 直接
export const DIRECT_RATE = 'direct_rate';
// 直間比率 間接
export const INDIRECT_RATE = 'indirect_rate';
// 初回接触
export const CNT_FIRST = 'cnt_first';
// 初回接触 紐づけ前
export const CNT_FIRST_CROSS_LINKED_BEFORE = 'cnt_first_cross_linked_before';
// 初回接触 紐づけ後
export const CNT_FIRST_CROSS_LINKED_AFTER = 'cnt_first';
// 初回接触 差分
export const CNT_FIRST_CROSS_DIFFERENCE = 'cnt_first_cross_difference';
// 初回接触 比率
export const CNT_FIRST_CROSS_RATE = 'cnt_first_cross_rate';
// 再配分CV
export const RCV = 'rcv';
// 再配分CV 紐づけ前
export const RCV_CROSS_LINKED_BEFORE = 'rcv_cross_linked_before';
// 再配分CV 紐づけ後
export const RCV_CROSS_LINKED_AFTER = 'rcv';
// 再配分CV 差分
export const RCV_CROSS_DIFFERENCE = 'rcv_cross_difference';
// 再配分CV 比率
export const RCV_CROSS_RATE = 'rcv_cross_rate';
// 売上総額
export const AMOUNT = 'amount';
// 再配分売上
export const RAMOUNT = 'ramount';
// 再配分売上 紐づけ前
export const RAMOUNT_CROSS_LINKED_BEFORE = 'ramount_cross_linked_before';
// 再配分売上 紐づけ後
export const RAMOUNT_CROSS_LINKED_AFTER = 'ramount';
// 再配分売上 差分
export const RAMOUNT_CROSS_DIFFERENCE = 'ramount_cross_difference';
// 再配分売上 比率
export const RAMOUNT_CROSS_RATE = 'ramount_cross_rate';
// 広告コスト
export const COST = 'cost';
export const AD_COST = 'ad_cost';
// CPC
export const CPC = 'cpc';
// CPA
export const CPA = 'cpa';
// TCPA
export const TCPA = 'tcpa';
// ROAS
export const ROAS = 'roas';
// TROAS
export const TROAS = 'troas';
// 広告ID
export const AD_ID = 'ad_id';
// 名称
export const AD_NAME = 'ad_name';
// 担当代理店
export const AGENCY = 'agency';
// 備考
export const AD_NOTE = 'ad_note';
// 出稿期間
export const AD_DATE = 'ad_date';
// デバイス
export const TERMINAL_TYPE = 'terminal_type';
// 計測方式
export const MEASUREMENT_TYPE = 'measurement_type';
// ランディングページドメイン
export const LANDING_PAGE_DOMAIN = 'landing_page_domain'; // landing_page_domain
// ランディングページURL
export const LANDING_PAGE_URL = 'landing_page_url';
// Search Console
export const SEARCH_WORD = 'search_word';
// 期間（日／月／曜日／時間）
export const PERIOD = 'date'; // period
// CV名
export const CV_NAME = 'cv_name';
// CV時間
export const CV_ACCESS_TIME = 'cv_access_time'; // cv_access_time
// ユーザーID
export const TRACKING_DATA = 'tracking_data';
// ユーザー名
export const MEMBER_NAME = 'member_name';
// 売上金額
// export const AMOUNT = 'amount';
// 項目1
export const OTHER1 = 'other1';
// 項目2
export const OTHER2 = 'other2';
// 項目3
export const OTHER3 = 'other3';
// 項目4
export const OTHER4 = 'other4';
// 項目5
export const OTHER5 = 'other5';
// 潜伏期間
export const CV_CONTACT_LATENCY_TIME_AVG = 'cv_contact_latency_time_avg'; // cv_contact_latency_time_avg
// 接触回数
export const CNT_CONTACT = 'cnt_contact';
// 直接効果コンバージョンに至るまでの接触履歴
export const CONTACT_DIRECT = 'contact_direct'; // contact_direct
// 間接効果 間接効果2 コンバージョンに至るまでの接触履歴
export const CONTACT_INDIRECT2 = 'contact_indirect2'; // contact_indirect2
// 間接効果 間接効果3 コンバージョンに至るまでの接触履歴
export const CONTACT_INDIRECT3 = 'contact_indirect3'; // contact_indirect3
// 間接効果 間接効果4 コンバージョンに至るまでの接触履歴
export const CONTACT_INDIRECT4 = 'contact_indirect4'; // contact_indirect4
// 間接効果 間接効果5 コンバージョンに至るまでの接触履歴
export const CONTACT_INDIRECT5 = 'contact_indirect5'; // contact_indirect5
// 間接効果 間接効果6 コンバージョンに至るまでの接触履歴
export const CONTACT_INDIRECT6 = 'contact_indirect6'; // contact_indirect6
// 間接効果 間接効果7 コンバージョンに至るまでの接触履歴
export const CONTACT_INDIRECT7 = 'contact_indirect7'; // contact_indirect7
// 間接効果 間接効果8 コンバージョンに至るまでの接触履歴
export const CONTACT_INDIRECT8 = 'contact_indirect8'; // contact_indirect8
// 間接効果 間接効果9 コンバージョンに至るまでの接触履歴
export const CONTACT_INDIRECT9 = 'contact_indirect9'; // contact_indirect9
// 間接効果 間接効果10 コンバージョンに至るまでの接触履歴
export const CONTACT_INDIRECT10 = 'contact_indirect10'; // contact_indirect10
// 初回接触コンバージョンに至るまでの接触履歴
export const CONTACT_FIRST = 'contact_first'; // contact_first
// 発生日時
export const CONTACT_ACCESS_TIME = 'access_time'; // contact_access_time
// チャネル種別 接触履歴に表示する項目
export const CONTACT_CHANNEL = 'contact_channel';
// カテゴリ 媒体種別 接触履歴に表示する項目
export const CONTACT_CATEGORY = 'contact_category';
// デバイス 接触履歴に表示する項目
export const CONTACT_DEVICE = 'contact_device';
export const DEVICE = 'device';
// 広告グループ１ 接触履歴に表示する項目
export const CONTACT_AD_GROUP1 = 'contact_ad_group1';
// 広告グループ２ 接触履歴に表示する項目
export const CONTACT_AD_GROUP2 = 'contact_ad_group2';
// 広告ID 接触履歴に表示する項目
export const CONTACT_AD_ID = 'contact_ad_id';
// 名称 広告名 接触履歴に表示する項目
export const CONTACT_AD_NAME = 'contact_ad_name';
// 担当代理店 接触履歴に表示する項目
export const CONTACT_AGENCY = 'contact_agency';
// コンテンツカテゴリ 接触履歴に表示する項目
export const CONTACT_CONTENT_CATEGORY = 'contact_content_category';
// ランディングページドメイン 接触履歴に表示する項目
export const CONTACT_LANDING_PAGE_DOMAIN = 'contact_landing_page_domain';
// 媒体種別 接触履歴に表示する項目
export const CONTACT_SYNC_CATEGORY = 'contact_sync_category';
// 媒体キャンペーン 接触履歴に表示する項目
export const CONTACT_MEDIA_SIDE_CAMPAIGN = 'contact_media_side_campaign';
// 媒体広告グループ 接触履歴に表示する項目
export const CONTACT_MEDIA_SIDE_GROUP = 'contact_media_side_group';
// (媒体シンク)広告ID 接触履歴に表示する項目
export const CONTACT_MEDIA_SIDE_AD_ID = 'contact_media_side_ad_id';
// (媒体シンク)名称 接触履歴に表示する項目
export const CONTACT_MEDIA_SIDE_AD_NAME = 'contact_media_side_ad_name';
// エビス軸優先時に出力する媒体広告ID
export const CONTACT_OPTIONAL_MEDIA_SIDE_AD_ID =
  'contact_optional_media_side_ad_id';
// 媒体軸優先時に出力する広告ID
export const CONTACT_OPTIONAL_AD_ID = 'contact_optional_ad_id';

// ランディングページURL 接触履歴に表示する項目
export const CONTACT_LANDING_PAGE_URL = 'contact_landing_page_url';
// 平均接触履歴回数
export const CONTACT_AVG = 'contact_avg'; // contact_avg
// 平均売上
export const AMOUNT_AVG = 'amount_avg';
// 再配分CV割合
export const RCV_RATE = 'reallocation_cv_ratio'; // rcv_rate
// 生産性（再配分CV）
export const PRODUCTIVITY_RCV = 'rcv_cost_effectiveness'; // productivity_rcv
// 再配分売上割合
export const RAMOUNT_RATE = 'reallocation_sales_ratio'; // ramount_rate
// 生産性（再配分売上）
export const PRODUCTIVITY_AMOUNT = 'ramount_cost_effectiveness'; // productivity_amount
// 広告コスト割合
export const COST_RATE = 'ad_cost_ratio'; // cost_rate
// Cv
export const CV = 'cnt_cv_flow';
// 商品
export const ITEM = 'item';
// オファー
export const OFFER = 'offer';
// 新規CV（CV別）
export const CNT_NEW_CV_DETAIL = 'cnt_new_cv';
// 新規CVR（CV別）
export const CNT_NEW_CV_TOTAL = 'cnt_new_cv_total';
// 新規CV（合計）
export const NEW_CVR_DETAIL = 'new_cvr';
// 新規CVR（合計）
export const NEW_CVR_TOTAL = 'new_cvr_total';
// 新規CPA：実績
export const NEW_CPA_ACTUAL_RESULTS = 'new_cpa_actual_results';
// 新規CPA：上限
export const NEW_CPA_UPPER_LIMIT = 'new_cpa_upper_limit';
// 新規CPA：差分
export const NEW_CPA_DIFFERENCE = 'new_cpa_difference';
// F2転換CPA
export const F2_CPA = 'f2_cpa';
// F1平均売上
export const F1_AVERAGE_AMOUNT = 'f1_average_amount';
// F2平均売上
export const F2_AVERAGE_AMOUNT = 'f2_average_amount';
// F３以降平均売上
export const F3_OVER_AVERAGE_AMOUNT = 'f3_over_average_amount';
// F2転換率
export const F2_CV_RATIO = 'f2_cv_ratio';
// F３転換率
export const F3_CV_RATIO = 'f3_cv_ratio';
// 投資回収期間：目標
export const PAYBACK_PERIOD_TARGET = 'payback_period_target';
// 投資回収期間：予測
export const PAYBACK_PERIOD_PREDICTION = 'payback_period_prediction';
// 投資回収期間：差分
export const PAYBACK_PERIOD_DIFFERENCE = 'payback_period_difference';
// LTV売上
export const LTV_AMOUNT = 'ltv_amount';
// LTV売上(合計）
export const LTV_AMOUNT_TOTAL = 'ltv_amount_total';
// LTV利益
export const LTV_PROFIT = 'ltv_profit';
// LTV利益(合計）
export const LTV_PROFIT_TOTAL = 'ltv_profit_total';
// 限界利益
export const MARGINAL_PROFIT = 'marginal_profit';
// ROI
export const ROI = 'roi';
// F2転換対象期間
export const F2_CV_PERIOD_DURATION_DAYS = 'f2_cv_period_duration_days';
// F3転換対象期間
export const F3_CV_PERIOD_DURATION_DAYS = 'f3_cv_period_duration_days';
// LTV期間
export const LTV_PERIOD_DURATION_MONTHS = 'ltv_period_duration_months';
// コンテンツカテゴリ
export const CONTENT_CATEGORY = 'content_category';
// クロスデバイス差分比較
export const CROSSDEVICE_DIFF_COMPARISON = 'crossdevice_diff_comparison';
// リンク先URL
export const LINK_URL = 'link_url';
// リンク先タイトル
export const LINK_PAGE_TITLE = 'link_page_title';
// 遷移割合
export const TRANSITION_RATE = 'transition_rate';

// 30日
export const THIRTY_DAY = 'thirty_day';
// 60日
export const SIXTY_DAY = 'sixty_day';
// 90日
export const NINETY_DAY = 'ninety_day';
// 120日
export const ONE_HUNDRED_TWENTY_DAY = 'one_hundred_twenty_day';
// 6ヶ月
export const HALF_YEAR = 'half_year';
// 12ヶ月
export const ONE_YEAR = 'one_year';
// 24ヶ月
export const TWE_YEAR = 'twe_year';
// 36ヶ月
export const THREE_YEAR = 'three_year';

// 分類
// 集計軸
export const DISPLAY_GROUP_AGGREGATION_AXIS = 'aggregation_axis';
// 項目
export const DISPLAY_GROUP_ITEM = 'item';
export const DISPLAY_GROUP_DETAIL = 'detail';
export const DISPLAY_GROUP_DISPLAY_CONDITIONS = 'display_conditions';
export const DISPLAY_GROUP_RADIO_OTIONS = 'options';
// コンバージョン属性
export const DISPLAY_GROUP_CONVERSION_ATTRIBUTES = 'conversion_attributes';
// コンバージョンに至るまでの接触履歴
export const DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY =
  'conversion_contact_history';
// 接触履歴に表示する項目
export const DISPLAY_GROUP_ITEMS_CONTACT_HISTORY = 'items_contact_history';
// 非表示
export const DISPLAY_GROUP_HIDE = 'hide';

// 項目編集仕様

// 小数点
// 文字列
export const DATA_TYPE_STRING = 'string';
// 数値
export const DATA_TYPE_NUMERIC = 'numeric';
// パーセンテージ
export const DATA_TYPE_PERCENT = 'percent';
// 期間
export const DATA_TYPE_PERIOD = 'period';
// 金額
export const DATA_TYPE_MONEY = 'money';
// 日付 ~ 日付
export const DATA_TYPE_DATE = 'date';
// 日時
export const DATA_TYPE_DATETIME = 'datetime';
// URL
export const DATA_TYPE_URL = 'URL';
// 頻度
export const DATA_TYPE_FREQUENCY = 'frequency';
// ページID
export const PAGE_ID = 'page_id';
// ページタイトル
export const PAGE_TITLE = 'page_title';
// ページURL
export const PAGE_URL = 'page_url';
// ディレクトリ
export const DIRECTORY = 'directory';
// UU
export const CNT_UU = 'cnt_uu';
// UU割合
export const PER_UU = 'per_uu';
// 流入数
export const CNT_ENTRANCE = 'cnt_entrance';
// 離脱数
export const CNT_EXIT = 'cnt_exit';
// CV貢献度(CV別)
export const CNT_CV_CONTRIBUTE = 'cnt_cv_contribute';
// CV貢献率(CV別)
export const CNT_CVR_CONTRIBUTE = 'cnt_cvr_contribute';
// CV貢献度(合計)
export const CV_CONTRIBUTE_TOTAL = 'cv_contribute_total';
// CV貢献率(合計)
export const CVR_CONTRIBUTE_TOTAL = 'cvr_contribute_total';
// 優先順位
export const RANK = 'rank';
// 経路件数
export const CNT = 'cnt';
// 件数割合
export const RATE = 'rate';

export const LANDING_PAGE_TITLE = 'landing_page_title';

// 通貨記号
export const CSV_CURRENCY_SYMBOL = 'currencySymbol';
// 旧ソート順
export const CSV_OLD_SORT_ORDER = 'csvOldSortOrder';

export const SORT_DIRECTION_ASC = 'asc';
export const SORT_DIRECTION_DESC = 'desc';
export const SORT_DIRECTION_NONE = 'none';
export const SORT_DIRECTION_OTHER = 'other';

// Function code
export const FUNC_CODE_CATEGORY_ANALYSIS = 'f02';
export const FUNC_CODE_PERIOD_ANALYSIS = 'f03';
export const FUNC_CODE_COST_ALLOCATION_ANALYSIS = 'f04';
export const FUNC_CODE_DASHBOARD_ANALYSIS = 'f05';
export const FUNC_CODE_CV_FLOW_ANALYSIS = 'f06';
export const FUNC_CODE_CV_ATTR_ANALYSIS = 'f07';
export const FUNC_CODE_DETAIL_ANALYSIS = 'f08';
export const FUNC_CODE_USER_SETTINGS = 'f09';
export const FUNC_CODE_INFLOW_ANALYSIS = 'f10'; // Share display settings between category/period/cost allocation analysis
export const FUNC_CODE_AD_MANAGEMENT = 'f11'; // Ad management (all tabs)
export const FUNC_CODE_LTV_ANALYSIS = 'f12';
export const FUNC_CODE_LANDING_PAGE_ANALYZE = 'f13';
export const FUNC_CODE_LOG_PAGE_ANALYZE = 'f14';
export const FUNC_CODE_LOG_PERIOD_ANALYZE = 'f15';
export const FUNC_CODE_LOG_ROUTE_ANALYZE = 'f16';
export const FUNC_CODE_LPO_LINK = 'f17';
export const FUNC_CODE_LPO_PERIOD = 'f18';
export const FUNC_CODE_CSV_SETTING = 'f100';

// デフォルト値
// LTV期間
export const LTV_PERIOD_DURATION_MONTHS_DEFULT_VALUE = 12;
// F2転換対象期間
export const F2_CV_PERIOD_DURATION_DAYS_DEFULT_VALUE = 30;
// F3転換対象期間
export const F3_CV_PERIOD_DURATION_DAYS_DEFULT_VALUE = 60;

// Column group labels
export const NEW_CPA_COLUMN_GROUP_LABEL = '新規CPA';
export const PAYBACK_PERIOD_COLUMN_GROUP_LABEL = '投資回収期間';

const defaultItem = {
  title: null,
  // 分類
  syncGroup: DISPLAY_GROUP_ITEM,
  ebisGroup: DISPLAY_GROUP_ITEM,
  // 表示
  disabled: true,
  // 表示形式
  dataType: DATA_TYPE_STRING,
  // 小数点
  decimalPoint: null,
  // belong to report settings
  // 列幅固定内
  displayFreeze: false,
  // 表の表示順
  order: null,
  displayDefault: false,
  sortDisabled: false,
  sortDefault: SORT_DIRECTION_NONE,
  ebisRequired: false,
  syncRequired: DISPLAY_GROUP_ITEM,
  permissionSet: [],
};

export const initBase = {
  [CHANNEL]: {
    title: 'チャネル種別',
    group: DISPLAY_GROUP_AGGREGATION_AXIS,
  },
  [CONTENT_CATEGORY]: {
    title: 'コンテンツカテゴリ',
    permissionSet: LOG_CONTRACT_PERMISSIONS,
  },
  [CATEGORY]: {
    title: 'カテゴリ',
    // group: DISPLAY_GROUP_AGGREGATION_AXIS,
  },
  // dynamic value, get from setting
  [AD_GROUP1]: {
    title: '広告グループ１',
    // group: DISPLAY_GROUP_AGGREGATION_AXIS,
  },
  [AD_GROUP2]: {
    title: '広告グループ２',
    // group: DISPLAY_GROUP_AGGREGATION_AXIS,
  },
  [SYNC_CATEGORY]: {
    title: 'カテゴリ',
    permissionSet: MEDIA_SYNC_PERMISSIONS,
  },
  [MEDIA_SIDE_CAMPAIGN]: {
    title: '媒体キャンペーン',
    permissionSet: MEDIA_SYNC_PERMISSIONS,
  },
  [MEDIA_SIDE_GROUP]: {
    title: '媒体広告グループ',
    permissionSet: MEDIA_SYNC_PERMISSIONS,
  },
  [MEDIA_SIDE_AD_ID]: {
    title: '媒体広告ID',
    permissionSet: MEDIA_SYNC_PERMISSIONS,
  },
  [MEDIA_SIDE_AD_NAME]: {
    title: '名称',
    permissionSet: MEDIA_SYNC_PERMISSIONS,
  },
  [OPTIONAL_AD_ID]: {
    title: '広告ID',
    permissionSet: MEDIA_SYNC_PERMISSIONS,
  },
  [OPTIONAL_MEDIA_SIDE_AD_ID]: {
    title: '媒体広告ID',
    permissionSet: MEDIA_SYNC_PERMISSIONS,
  },
  [IMPRESSION]: {
    title: '表示回数',
    dataType: DATA_TYPE_NUMERIC,
  },
  [CNT_ACCESS]: {
    title: 'クリック／流入回数',
    dataType: DATA_TYPE_NUMERIC,
  },
  [CTR]: {
    title: 'CTR',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: '2:4',
  },
  [CNT_SESSION]: {
    title: 'セッション数',
    dataType: DATA_TYPE_NUMERIC,
    permissionSet: LOG_CONTRACT_PERMISSIONS,
  },
  [PER_SESSION]: {
    title: 'SU割合',
  },
  [CNT_PV]: {
    title: '総PV',
    dataType: DATA_TYPE_NUMERIC,
    permissionSet: LOG_CONTRACT_PERMISSIONS,
  },
  [PER_PV]: {
    title: 'PV割合',
    dataType: DATA_TYPE_NUMERIC,
    permissionSet: LOG_CONTRACT_PERMISSIONS,
  },
  [PV_AVG]: {
    title: '平均PV',
    dataType: DATA_TYPE_NUMERIC,
    permissionSet: LOG_CONTRACT_PERMISSIONS,
  },
  [CNT_RETURN]: {
    title: '直帰数',
    dataType: DATA_TYPE_NUMERIC,
    permissionSet: LOG_CONTRACT_PERMISSIONS,
  },
  [PER_RETURN]: {
    title: '直帰率',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: 4,
    permissionSet: LOG_CONTRACT_PERMISSIONS,
  },
  [TOTAL_STAY_TIME]: {
    title: '総滞在時間',
    dataType: DATA_TYPE_PERIOD,
    permissionSet: LOG_CONTRACT_PERMISSIONS,
  },
  [TOTAL_STAY_TIME_AVG]: {
    title: '平均滞在時間',
    dataType: DATA_TYPE_PERIOD,
    permissionSet: LOG_CONTRACT_PERMISSIONS,
  },
  [TOTAL_LATENCY_TIME]: {
    title: '総潜伏期間',
    dataType: DATA_TYPE_PERIOD,
  },
  [LATENCY_TIME_AVG]: {
    title: '平均潜伏期間',
    dataType: DATA_TYPE_PERIOD,
  },
  [CNT_CV_DETAIL]: {
    title: 'CV（CV別）',
    titleTable: 'CV',
    dataType: DATA_TYPE_NUMERIC,
  },
  [CNT_CV_TOTAL]: {
    title: 'CV（合計）',
    dataType: DATA_TYPE_NUMERIC,
  },
  [CV_TOTAL_CROSS_LINKED_BEFORE]: {
    title: '紐づけ前',
    dataType: DATA_TYPE_NUMERIC,
  },
  [CV_TOTAL_CROSS_DIFFERENCE]: {
    title: '差分',
    dataType: DATA_TYPE_NUMERIC,
  },
  [CV_TOTAL_CROSS_RATE]: {
    title: '比率',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: 4,
  },
  [CVR_DETAIL]: {
    title: 'CVR（CV別）',
    titleTable: 'CVR',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: 4,
  },
  [CVR_TOTAL]: {
    title: 'CVR（合計）',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: 4,
  },
  [CNT_INDIRECT2]: {
    title: '間接効果2',
    dataType: DATA_TYPE_NUMERIC,
  },
  [CNT_INDIRECT3]: {
    title: '間接効果3',
    dataType: DATA_TYPE_NUMERIC,
  },
  [CNT_INDIRECT4]: {
    title: '間接効果4',
    dataType: DATA_TYPE_NUMERIC,
  },
  [CNT_INDIRECT5]: {
    title: '間接効果5',
    dataType: DATA_TYPE_NUMERIC,
  },
  [CNT_INDIRECT_OTHER]: {
    title: '間接効果6-10',
    dataType: DATA_TYPE_NUMERIC,
  },
  [INDIRECT_TOTAL]: {
    title: '間接効果（合計）',
    dataType: DATA_TYPE_NUMERIC,
  },
  [DIRECT_RATE]: {
    title: '直間比率（直接）',
    titleTable: '直接',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: '2:4',
  },
  [INDIRECT_RATE]: {
    title: '直間比率（間接）',
    titleTable: '間接',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: '2:4',
  },
  [CNT_FIRST]: {
    title: '初回接触',
    dataType: DATA_TYPE_NUMERIC,
  },
  [CNT_FIRST_CROSS_LINKED_BEFORE]: {
    title: '紐づけ前',
    dataType: DATA_TYPE_NUMERIC,
  },
  [CNT_FIRST_CROSS_DIFFERENCE]: {
    title: '差分',
    dataType: DATA_TYPE_NUMERIC,
  },
  [CNT_FIRST_CROSS_RATE]: {
    title: '比率',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: 4,
  },
  [RCV]: {
    title: '再配分CV',
    dataType: DATA_TYPE_NUMERIC,
    decimalPoint: '2',
  },
  [RCV_CROSS_LINKED_BEFORE]: {
    title: '紐づけ前',
    dataType: DATA_TYPE_NUMERIC,
    decimalPoint: '2',
  },
  [RCV_CROSS_DIFFERENCE]: {
    title: '差分',
    dataType: DATA_TYPE_NUMERIC,
    decimalPoint: '2',
  },
  [RCV_CROSS_RATE]: {
    title: '比率',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: 4,
  },
  [AMOUNT]: {
    title: '売上総額',
    dataType: DATA_TYPE_MONEY,
  },
  [RAMOUNT]: {
    title: '再配分売上',
    dataType: DATA_TYPE_MONEY,
  },
  [RAMOUNT_CROSS_LINKED_BEFORE]: {
    title: '紐づけ前',
    dataType: DATA_TYPE_NUMERIC,
  },
  [RAMOUNT_CROSS_DIFFERENCE]: {
    title: '差分',
    dataType: DATA_TYPE_NUMERIC,
  },
  [RAMOUNT_CROSS_RATE]: {
    title: '比率',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: 4,
  },
  [COST]: {
    title: '広告コスト',
    dataType: DATA_TYPE_MONEY,
  },
  [CPC]: {
    title: 'CPC',
    dataType: DATA_TYPE_MONEY,
  },
  [CPA]: {
    title: 'CPA',
    dataType: DATA_TYPE_MONEY,
  },
  [TCPA]: {
    title: 'TCPA',
    dataType: DATA_TYPE_MONEY,
  },
  [ROAS]: {
    title: 'ROAS',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: 4,
  },
  [TROAS]: {
    title: 'TROAS',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: 4,
  },
  [AD_ID]: {
    title: '広告ID',
  },
  [AD_NAME]: {
    title: '名称',
  },
  [AGENCY]: {
    title: '担当代理店',
    permissionSet: {
      ad: AGENCY_CONTRACT_PERMISSIONS,
      all: AGENCY_CONTRACT_PERMISSIONS,
      view: AGENCY_CONTRACT_PERMISSIONS,
    },
    // group: DISPLAY_GROUP_AGGREGATION_AXIS,
  },
  [AD_NOTE]: {
    title: '備考',
  },
  [AD_DATE]: {
    title: '出稿期間',
    dataType: DATA_TYPE_DATE,
  },
  [PAGE_ID]: {
    title: 'ページID',
  },
  [PAGE_TITLE]: {
    title: 'ページタイトル',
  },
  [PAGE_URL]: {
    title: 'ページURL',
  },
  [DIRECTORY]: {
    title: 'ディレクトリ',
  },
  [TERMINAL_TYPE]: {
    title: 'デバイス',
  },
  [MEASUREMENT_TYPE]: {
    title: '計測方式',
  },
  [LANDING_PAGE_DOMAIN]: {
    title: 'ランディングページドメイン',
    dataType: DATA_TYPE_URL,
  },
  [LANDING_PAGE_URL]: {
    title: 'ランディングページURL',
    dataType: DATA_TYPE_URL,
  },
  [SEARCH_WORD]: {
    title: '検索ワード',
    permissionSet: SEO_CONTRACT_DISPLAY_SEARCH_CONSOLE,
  },
  [PERIOD]: {
    title: '期間',
    dataType: DATA_TYPE_DATETIME,
    // group: DISPLAY_GROUP_AGGREGATION_AXIS,
  },
  [CV_NAME]: {
    title: 'CV名',
    // group: DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
  },
  [CV_ACCESS_TIME]: {
    title: 'CV時間',
    // group: DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
  },
  [TRACKING_DATA]: {
    title: 'ユーザーID',
    // group: DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
  },
  [MEMBER_NAME]: {
    title: 'ユーザー名',
    // group: DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
  },
  // TODO: confirm name conflict?!
  // [AMOUNT]: {
  //   title: '売上金額',
  // },
  // dynamic value, get from setting
  [OTHER1]: {
    title: '項目1',
    // group: DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
  },
  [OTHER2]: {
    title: '項目2',
    // group: DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
  },
  [OTHER3]: {
    title: '項目3',
    // group: DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
  },
  [OTHER4]: {
    title: '項目4',
    // group: DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
  },
  [OTHER5]: {
    title: '項目5',
    // group: DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
  },
  [CV_CONTACT_LATENCY_TIME_AVG]: {
    title: '潜伏期間',
    dataType: DATA_TYPE_PERIOD,
    // group: DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
  },
  [CNT_CONTACT]: {
    title: '接触回数',
    dataType: DATA_TYPE_FREQUENCY,
    // group: DISPLAY_GROUP_CONVERSION_ATTRIBUTES,
  },
  [CONTACT_DIRECT]: {
    title: '直接効果',
    // group: DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
  },
  [CONTACT_INDIRECT2]: {
    title: '間接効果2',
    // group: DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
  },
  [CONTACT_INDIRECT3]: {
    title: '間接効果3',
    // group: DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
  },
  [CONTACT_INDIRECT4]: {
    title: '間接効果4',
    // group: DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
  },
  [CONTACT_INDIRECT5]: {
    title: '間接効果5',
    // group: DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
  },
  [CONTACT_INDIRECT6]: {
    title: '間接効果6',
    // group: DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
  },
  [CONTACT_INDIRECT7]: {
    title: '間接効果7',
    // group: DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
  },
  [CONTACT_INDIRECT8]: {
    title: '間接効果8',
    // group: DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
  },
  [CONTACT_INDIRECT9]: {
    title: '間接効果9',
    // group: DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
  },
  [CONTACT_INDIRECT10]: {
    title: '間接効果10',
    // group: DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
  },
  [CONTACT_FIRST]: {
    title: '初回接触',
    // group: DISPLAY_GROUP_CONVERSION_CONTACT_HISTORY,
  },
  [CONTACT_ACCESS_TIME]: {
    title: '発生日時',
    dataType: DATA_TYPE_DATETIME,
    // group: DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
  },
  [CONTACT_DEVICE]: {
    title: 'デバイス',
    // group: DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
  },
  [CONTACT_CHANNEL]: {
    title: 'チャネル種別',
    // group: DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
  },
  [CONTACT_CATEGORY]: {
    title: 'カテゴリ',
    // group: DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
  },
  // dynamic value, get from setting
  [CONTACT_AD_GROUP1]: {
    title: '広告グループ１',
    // group: DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
  },
  [CONTACT_AD_GROUP2]: {
    title: '広告グループ2',
    // group: DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
  },
  [CONTACT_AD_ID]: {
    title: '広告ID',
    // group: DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
  },
  [CONTACT_AD_NAME]: {
    title: '名称',
    // group: DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
  },
  [CONTACT_AGENCY]: {
    title: '担当代理店',
    permissionSet: {
      ad: AGENCY_CONTRACT_PERMISSIONS,
      all: AGENCY_CONTRACT_PERMISSIONS,
      view: AGENCY_CONTRACT_PERMISSIONS,
    },
    // group: DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
  },
  [CONTACT_CONTENT_CATEGORY]: {
    title: 'コンテンツカテゴリ',
    permissionSet: LOG_CONTRACT_PERMISSIONS,
  },
  [CONTACT_LANDING_PAGE_DOMAIN]: {
    title: 'ランディングページドメイン',
    dataType: DATA_TYPE_URL,
  },
  [CONTACT_SYNC_CATEGORY]: {
    title: 'カテゴリ',
  },
  [CONTACT_MEDIA_SIDE_CAMPAIGN]: {
    title: '媒体キャンペーン',
  },
  [CONTACT_MEDIA_SIDE_GROUP]: {
    title: '媒体広告グループ',
  },
  [CONTACT_MEDIA_SIDE_AD_ID]: {
    title: '媒体広告ID',
  },
  [CONTACT_MEDIA_SIDE_AD_NAME]: {
    title: '名称',
  },
  [CONTACT_OPTIONAL_AD_ID]: {
    title: '広告ID',
    permissionSet: MEDIA_SYNC_PERMISSIONS,
  },
  [CONTACT_OPTIONAL_MEDIA_SIDE_AD_ID]: {
    title: '媒体広告ID',
    permissionSet: MEDIA_SYNC_PERMISSIONS,
  },
  [CONTACT_LANDING_PAGE_URL]: {
    title: 'ランディングページURL',
    dataType: DATA_TYPE_URL,
  },
  [CONTACT_AVG]: {
    title: '平均接触履歴回数',
    dataType: DATA_TYPE_FREQUENCY,
  },
  [AMOUNT_AVG]: {
    title: '平均売上',
    dataType: DATA_TYPE_MONEY,
  },
  [RCV_RATE]: {
    title: '再配分CV割合',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: '2:4',
  },
  [PRODUCTIVITY_RCV]: {
    title: '生産性（再配分CV）',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: '2:4',
  },
  [RAMOUNT_RATE]: {
    title: '再配分売上割合',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: '2:4',
  },
  [PRODUCTIVITY_AMOUNT]: {
    title: '生産性（再配分売上）',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: '2:4',
  },
  [COST_RATE]: {
    title: '広告コスト割合',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: '2:4',
  },
  [CV]: {
    title: 'CV',
    dataType: DATA_TYPE_NUMERIC,
  },
  [ITEM]: {
    title: '商品',
  },
  [OFFER]: {
    title: 'オファー',
  },
  [CNT_NEW_CV_DETAIL]: {
    title: '新規CV（CV別）',
    titleTable: '新規CV',
    dataType: DATA_TYPE_NUMERIC,
  },
  [CNT_NEW_CV_TOTAL]: {
    title: '新規CV（合計）',
    dataType: DATA_TYPE_NUMERIC,
  },
  [NEW_CVR_DETAIL]: {
    title: '新規CVR（CV別）',
    titleTable: '新規CVR',
    dataType: DATA_TYPE_PERCENT,
  },
  [NEW_CVR_TOTAL]: {
    title: '新規CVR（合計）',
    dataType: DATA_TYPE_PERCENT,
  },
  [NEW_CPA_ACTUAL_RESULTS]: {
    title: '新規CPA：実績',
    titleTable: '実績',
    dataType: DATA_TYPE_MONEY,
  },
  [NEW_CPA_UPPER_LIMIT]: {
    title: '新規CPA：上限',
    titleTable: '上限',
    dataType: DATA_TYPE_MONEY,
  },
  [NEW_CPA_DIFFERENCE]: {
    title: '新規CPA：差分',
    titleTable: '差分',
    dataType: DATA_TYPE_MONEY,
  },
  [F2_CPA]: {
    title: 'F2転換CPA',
    dataType: DATA_TYPE_MONEY,
  },
  [F1_AVERAGE_AMOUNT]: {
    title: 'F1平均売上',
    dataType: DATA_TYPE_MONEY,
  },
  [F2_AVERAGE_AMOUNT]: {
    title: 'F2平均売上',
    dataType: DATA_TYPE_MONEY,
  },
  [F3_OVER_AVERAGE_AMOUNT]: {
    title: 'F3以降平均売上',
    dataType: DATA_TYPE_MONEY,
  },
  [F2_CV_RATIO]: {
    title: 'F2転換率',
    dataType: DATA_TYPE_PERCENT,
  },
  [F3_CV_RATIO]: {
    title: 'F3転換率',
    dataType: DATA_TYPE_PERCENT,
  },
  [PAYBACK_PERIOD_TARGET]: {
    title: '投資回収期間：目標',
    titleTable: '目標',
    dataType: DATA_TYPE_PERIOD,
  },
  [PAYBACK_PERIOD_PREDICTION]: {
    title: '投資回収期間：予測',
    titleTable: '予測',
    dataType: DATA_TYPE_PERIOD,
  },
  [PAYBACK_PERIOD_DIFFERENCE]: {
    title: '投資回収期間：差分',
    titleTable: '差分',
    dataType: DATA_TYPE_PERIOD,
  },
  [LTV_AMOUNT]: {
    title: 'LTV売上',
    dataType: DATA_TYPE_MONEY,
  },
  [LTV_AMOUNT_TOTAL]: {
    title: 'LTV売上（合計）',
    dataType: DATA_TYPE_MONEY,
  },
  [LTV_PROFIT]: {
    title: 'LTV利益',
    dataType: DATA_TYPE_MONEY,
  },
  [LTV_PROFIT_TOTAL]: {
    title: 'LTV利益（合計）',
    dataType: DATA_TYPE_MONEY,
  },
  [MARGINAL_PROFIT]: {
    title: '限界利益',
    dataType: DATA_TYPE_MONEY,
  },
  [ROI]: {
    title: 'ROI',
    dataType: DATA_TYPE_PERCENT,
  },
  [CROSSDEVICE_DIFF_COMPARISON]: {
    title: 'クロスデバイス差分比較',
    permissionSet: CROSS_DEVICE_PERMISSIONS,
  },
  [LINK_URL]: {
    title: 'リンク先URL',
    permissionSet: LPO_PERMISSIONS,
    dataType: DATA_TYPE_URL,
  },
  [LINK_PAGE_TITLE]: {
    title: 'リンク先タイトル',
    permissionSet: LPO_PERMISSIONS,
  },
  [TRANSITION_RATE]: {
    title: '遷移割合',
    permissionSet: LPO_PERMISSIONS,
  },
  [CNT_UU]: {
    title: 'UU',
  },
  [PER_UU]: {
    title: 'UU割合',
  },
  [CNT_ENTRANCE]: {
    title: '流入数',
  },
  [CNT_EXIT]: {
    title: '離脱数',
  },
  [CNT_CV_CONTRIBUTE]: {
    title: 'CV貢献度（CV別）',
  },
  [CNT_CVR_CONTRIBUTE]: {
    title: 'CV貢献率（CV別）',
  },
  [CV_CONTRIBUTE_TOTAL]: {
    title: 'CV貢献度(合計)',
  },
  [CVR_CONTRIBUTE_TOTAL]: {
    title: 'CV貢献率(合計)',
  },
  [RANK]: {
    title: '優先順位',
  },
  [CNT]: {
    title: '件数',
  },
  [RATE]: {
    title: '経路割合',
  },
  [LANDING_PAGE_TITLE]: {
    title: 'ランディングページタイトル',
  },
};

const displayConditions = {
  [F2_CV_PERIOD_DURATION_DAYS]: {
    title: 'F2転換対象期間',
    defaultValue: F2_CV_PERIOD_DURATION_DAYS_DEFULT_VALUE,
    gropName: 'ltvF2RadioBtnDisplay',
    group: DISPLAY_GROUP_DISPLAY_CONDITIONS,
    disabled: false,
    getGroup: () => DISPLAY_GROUP_DISPLAY_CONDITIONS,
    getRequired: () => false,
    getDeny: () => false,
    options: {
      [THIRTY_DAY]: {
        title: '30日',
        value: 30,
      },
      [SIXTY_DAY]: {
        title: '60日',
        value: 60,
      },
      [NINETY_DAY]: {
        title: '90日',
        value: 90,
      },
      [ONE_HUNDRED_TWENTY_DAY]: {
        title: '120日',
        value: 120,
      },
    },
  },
  [F3_CV_PERIOD_DURATION_DAYS]: {
    title: 'F3転換対象期間',
    defaultValue: F3_CV_PERIOD_DURATION_DAYS_DEFULT_VALUE,
    gropName: 'ltvF3RadioBtnDisplay',
    group: DISPLAY_GROUP_DISPLAY_CONDITIONS,
    disabled: true,
    getGroup: () => DISPLAY_GROUP_DISPLAY_CONDITIONS,
    getRequired: () => false,
    getDeny: () => false,
    options: {
      [THIRTY_DAY]: {
        title: '30日',
        value: 30,
      },
      [SIXTY_DAY]: {
        title: '60日',
        value: 60,
      },
      [NINETY_DAY]: {
        title: '90日',
        value: 90,
      },
      [ONE_HUNDRED_TWENTY_DAY]: {
        title: '120日',
        value: 120,
      },
    },
  },
  [LTV_PERIOD_DURATION_MONTHS]: {
    title: 'LTV期間',
    defaultValue: LTV_PERIOD_DURATION_MONTHS_DEFULT_VALUE,
    gropName: 'ltvPeriodMonthRadioBtnDisplay',
    group: DISPLAY_GROUP_DISPLAY_CONDITIONS,
    disabled: false,
    getGroup: () => DISPLAY_GROUP_DISPLAY_CONDITIONS,
    getRequired: () => false,
    getDeny: () => false,
    options: {
      [HALF_YEAR]: {
        title: '6ヶ月',
        value: 6,
      },
      [ONE_YEAR]: {
        title: '12ヶ月',
        value: 12,
      },
      [TWE_YEAR]: {
        title: '24ヶ月',
        value: 24,
      },
      [THREE_YEAR]: {
        title: '36ヶ月',
        value: 36,
      },
    },
  },
};

export const getDisplayItemsBase = () => {
  const displayItemsBase = {};
  Object.keys(initBase).forEach((key) => {
    displayItemsBase[key] = {
      ...defaultItem,
      ...initBase[key],
    };
  });
  return displayItemsBase;
};

export const getDisplayConditions = () => {
  const displayConditionsBase = {};
  Object.keys(displayConditions).forEach((key) => {
    displayConditionsBase[key] = {
      ...defaultItem,
      ...displayConditions[key],
    };
  });
  return displayConditionsBase;
};

const DisplayItemsOnlyAll = {
  [CHANNEL]: {
    title: 'チャネル種別',
    // group: DISPLAY_GROUP_AGGREGATION_AXIS,
  },
  [CONTACT_CHANNEL]: {
    title: 'チャネル種別',
    // group: DISPLAY_GROUP_ITEMS_CONTACT_HISTORY,
  },
};

const DisplayItemsOnlyAd = {
  [RCV_RATE]: {
    title: '再配分CV割合',
  },
  [PRODUCTIVITY_RCV]: {
    title: '生産性（再配分CV）',
  },
  [RAMOUNT_RATE]: {
    title: '再配分売上割合',
  },
  [PRODUCTIVITY_AMOUNT]: {
    title: '生産性（再配分売上）',
  },
  [COST_RATE]: {
    title: '広告コスト割合',
  },
};

// If inflow-analysis, check permissions for cost-allocation-analysis items
const DisplayItemsInflowAnalyze = {
  [RCV_RATE]: {
    title: '再配分CV割合',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: '2:4',
    permissionSet: COST_ALLOCATION_PERMISSIONS,
  },
  [PRODUCTIVITY_RCV]: {
    title: '生産性（再配分CV）',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: '2:4',
    permissionSet: COST_ALLOCATION_PERMISSIONS,
  },
  [RAMOUNT_RATE]: {
    title: '再配分売上割合',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: '2:4',
    permissionSet: COST_ALLOCATION_PERMISSIONS,
  },
  [PRODUCTIVITY_AMOUNT]: {
    title: '生産性（再配分売上）',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: '2:4',
    permissionSet: COST_ALLOCATION_PERMISSIONS,
  },
  [COST_RATE]: {
    title: '広告コスト割合',
    dataType: DATA_TYPE_PERCENT,
    decimalPoint: '2:4',
    permissionSet: COST_ALLOCATION_PERMISSIONS,
  },
};

export const getAll = () => {
  const displayItemsBase = getDisplayItemsBase();
  const displayItemsAll = {};
  Object.keys(displayItemsBase).forEach((key) => {
    // disabled ad only
    if (!(key in DisplayItemsOnlyAd)) {
      displayItemsAll[key] = {
        ...displayItemsBase[key],
      };
    }
  });
  return displayItemsAll;
};

const displayItemsAdChangeTitle = {
  [CATEGORY]: {
    title: '媒体種別',
  },
  [CONTACT_CATEGORY]: {
    title: '媒体種別',
  },
  [CONTACT_SYNC_CATEGORY]: {
    title: '媒体',
  },
  [CNT_ACCESS]: {
    title: 'クリック数',
  },
  [AD_NAME]: {
    title: '広告名',
  },
  [CONTACT_AD_NAME]: {
    title: '広告名',
  },
  [CONTACT_MEDIA_SIDE_AD_NAME]: {
    title: '媒体広告名',
  },
  [SYNC_CATEGORY]: {
    title: '媒体',
  },
  [MEDIA_SIDE_AD_NAME]: {
    title: '媒体広告名',
  },
};

const displayItemsViewChangeTitle = {
  [CATEGORY]: {
    title: '媒体種別/カテゴリ',
  },
  [CONTACT_CATEGORY]: {
    title: '媒体種別/カテゴリ',
  },
  [CONTACT_SYNC_CATEGORY]: {
    title: '媒体/カテゴリ',
  },
  [CNT_ACCESS]: {
    title: 'クリック/流入回数',
  },
  [AD_NAME]: {
    title: '広告名/名称',
  },
  [CONTACT_AD_NAME]: {
    title: '広告名/名称',
  },
  [CONTACT_MEDIA_SIDE_AD_NAME]: {
    title: '媒体広告名/名称',
  },
  [SYNC_CATEGORY]: {
    title: '媒体/カテゴリ',
  },
  [MEDIA_SIDE_AD_NAME]: {
    title: '媒体広告名/名称',
  },
};

export const LIST_FUNC_APPLY_SORTING = [
  FUNC_CODE_LANDING_PAGE_ANALYZE,
  FUNC_CODE_LOG_PAGE_ANALYZE,
  // FUNC_CODE_LOG_PERIOD_ANALYZE,
];

export const getAd = () => {
  const displayItemsBase = getDisplayItemsBase();
  const displayItemsAd = {};
  Object.keys(displayItemsBase).forEach((key) => {
    // disabled all only
    if (!(key in DisplayItemsOnlyAll)) {
      const changeTitles =
        key in displayItemsAdChangeTitle ? displayItemsAdChangeTitle[key] : {};
      displayItemsAd[key] = {
        ...displayItemsBase[key],
        ...changeTitles,
      };
    }
  });
  return displayItemsAd;
};

export const getCommon = () => {
  const displayItemsBase = getDisplayItemsBase();
  const displayItemsCommon = {};
  Object.keys(displayItemsBase).forEach((key) => {
    const changeTitles =
      key in displayItemsViewChangeTitle
        ? displayItemsViewChangeTitle[key]
        : {};
    displayItemsCommon[key] = {
      ...displayItemsBase[key],
      ...changeTitles,
    };
  });
  return displayItemsCommon;
};

export const getAdInflowAnalysis = () => {
  const displayItemsBase = getDisplayItemsBase();
  const displayItemsAd = {};
  Object.keys(displayItemsBase).forEach((key) => {
    // disabled all only
    if (!(key in DisplayItemsOnlyAll)) {
      const changeTitles =
        key in displayItemsAdChangeTitle ? displayItemsAdChangeTitle[key] : {};
      displayItemsAd[key] = {
        ...displayItemsBase[key],
        ...changeTitles,
      };
    }
    // Add permission check for cost-allocation-analysis items
    if (!(key in DisplayItemsInflowAnalyze)) {
      const changeTitles =
        key in displayItemsAdChangeTitle ? displayItemsAdChangeTitle[key] : {};
      displayItemsAd[key] = {
        ...displayItemsBase[key],
        ...changeTitles,
      };
    } else {
      const changeTitles =
        key in displayItemsAdChangeTitle ? displayItemsAdChangeTitle[key] : {};
      displayItemsAd[key] = {
        ...DisplayItemsInflowAnalyze[key],
        ...changeTitles,
      };
    }
  });
  return displayItemsAd;
};

export const getCommonInflowAnalysis = () => {
  const displayItemsBase = getDisplayItemsBase();
  const displayItemsCommon = {};
  Object.keys(displayItemsBase).forEach((key) => {
    // Add permission check for cost-allocation-analysis items
    if (!(key in DisplayItemsInflowAnalyze)) {
      const changeTitles =
        key in displayItemsViewChangeTitle
          ? displayItemsViewChangeTitle[key]
          : {};
      displayItemsCommon[key] = {
        ...displayItemsBase[key],
        ...changeTitles,
      };
    } else {
      const changeTitles =
        key in displayItemsViewChangeTitle
          ? displayItemsViewChangeTitle[key]
          : {};
      displayItemsCommon[key] = {
        ...DisplayItemsInflowAnalyze[key],
        ...changeTitles,
      };
    }
  });
  return displayItemsCommon;
};

export const getItems = ({
  displayItemsBase,
  displayFreezeList,
  displayDefaultList,
  orderList,
  sortDefaultList,
  groupList,
  denyMediaSyncList = [],
  denyEbisList = [],
  requiredList = [],
  ebisRequiredList = [],
  syncRequiredList = [],
  sortDisabledList = [],
}) => {
  const displayItems = {};
  Object.keys(displayItemsBase).forEach((key) => {
    const order = Object.keys(orderList).includes(key) ? orderList[key] : null;
    // Remove un-used in screen
    if (!order) {
      return;
    }

    // エビス軸優先時に適応されるアイテムのグループ種別を代入する
    let ebisGroup = '';
    let denyEbis = false;
    if (denyEbisList.includes(key)) {
      ebisGroup = DISPLAY_GROUP_HIDE;
      denyEbis = true;
    } else {
      ebisGroup = Object.keys(groupList).includes(key)
        ? groupList[key]
        : DISPLAY_GROUP_ITEM;
    }

    // 媒体シンク優先時に適応されるアイテムのグループ種別を代入する
    let syncGroup = '';
    let denySync = false;
    if (denyMediaSyncList.includes(key)) {
      syncGroup = DISPLAY_GROUP_HIDE;
      denySync = true;
    } else {
      syncGroup = Object.keys(groupList).includes(key)
        ? groupList[key]
        : DISPLAY_GROUP_ITEM;
    }

    const group = Object.keys(groupList).includes(key)
      ? groupList[key]
      : DISPLAY_GROUP_ITEM;

    const displayFreeze = !!displayFreezeList.includes(key);
    const sortDisabled = sortDisabledList.includes(key);
    const required = requiredList.includes(key);
    const ebisRequired = ebisRequiredList.includes(key) || required;
    const syncRequired = syncRequiredList.includes(key) || required;

    const displayDefault =
      !!displayDefaultList.includes(key) || syncRequired || ebisRequired;

    const sortDefault = Object.keys(sortDefaultList).includes(key)
      ? sortDefaultList[key]
      : SORT_DIRECTION_NONE;

    const getGroup = (sync) => {
      if (typeof sync === 'string') {
        return sync === 'priority_axis_media_sync' ? syncGroup : ebisGroup;
      }
      return sync ? syncGroup : ebisGroup;
    };
    const getRequired = (sync) => {
      if (required) {
        return required;
      }
      if (typeof sync === 'string') {
        return sync === 'priority_axis_media_sync'
          ? syncRequired
          : ebisRequired;
      }
      return sync ? syncRequired : ebisRequired;
    };
    const getDeny = (sync) => {
      if (typeof sync === 'string') {
        return sync === 'priority_axis_media_sync' ? denySync : denyEbis;
      }
      return sync ? denySync : denyEbis;
    };
    displayItems[key] = {
      ...displayItemsBase[key],
      disabled: false,
      displayFreeze,
      displayDefault,
      order,
      sortDefault,
      ebisGroup,
      ebisRequired,
      syncRequired,
      syncGroup,
      group,
      denyEbis,
      denySync,
      sortDisabled,
      getGroup,
      getRequired,
      getDeny,
    };
  });
  return displayItems;
};

export const getDefault = (displayItemsBase) => {
  const displayItems = {};
  Object.keys(displayItemsBase).forEach((key) => {
    if (displayItemsBase[key].displayDefault) {
      displayItems[key] = displayItemsBase[key];
    }
  });
  return displayItems;
};
