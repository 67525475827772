import React, { useEffect } from 'react';
import { func, shape } from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import settingsSelector from 'store/settings/selectors';
import masterDataActions from 'store/master-data/actions';
import useFilterData from 'services/custom-hooks/useFilterData';
import { getPermissions } from 'store/auth/selectors';
import FilterService from 'domain/FilterService';
import isEqual from 'lodash/isEqual';
import * as DefinedConstant from 'domain/consts';
import { DisplayItemsSelectors } from 'store/display-items';
import settingsViewSelectors from 'store/customview/selectors';
import { SEARCH_WORD } from 'domain/settings/display-items';
import { FILTER_KEY_SEARCH_CONSOLE } from 'services/consts';
import useCheckPermissionSC from 'services/custom-hooks/useCheckPermissionSC';
import {
  DETAILS_ANALYSIS,
  LANDING_PAGE_ANALYZE,
  CV_ATTRIBUTE,
} from 'services/routes/constants';
import FilterForm from './FilterForm';

const contactHistoryDefault = Object.keys(
  DefinedConstant.FILTER_CONTACT_HISTORY
);

const FilterContainer = (props) => {
  const {
    handleApplyFilter,
    selectedFilters,
    crossDeviceReflectionData,
    storeFilters,
  } = props;

  const dispatch = useDispatch();

  const pageId = useSelector(settingsSelector.getPage, shallowEqual);
  const priorityAxis = useSelector(
    DisplayItemsSelectors.getDisplayItemPriorityAxis,
    shallowEqual
  );
  const currentTab = useSelector(settingsSelector.getCurrentTab);
  const isPageSettings = useSelector(settingsSelector.isPageSettings);

  const userPermissions = useSelector(getPermissions);
  const displayItems = useSelector(
    DisplayItemsSelectors.getSettings,
    shallowEqual
  );
  const viewStatus = useSelector(settingsViewSelectors.getStatus, shallowEqual);

  const [filterableList, mergedMasterData] = useFilterData(pageId);
  const { hasSEOContractPermissions } = useCheckPermissionSC();

  if ('contact_history' in filterableList) {
    selectedFilters.contact_history = {
      values: selectedFilters.contact_history
        ? selectedFilters.contact_history.values
        : contactHistoryDefault,
      ...{
        order: filterableList.contact_history.order,
        title: filterableList.contact_history.title,
        type: filterableList.contact_history.type,
      },
    };
  }

  useEffect(() => {
    // Handle case where values of disabled contracts still remain in session storage
    const contractFilteredValues = FilterService.filterNonContractValues(
      selectedFilters,
      userPermissions,
      pageId,
      currentTab
    );
    if (!isEqual(contractFilteredValues, selectedFilters)) {
      handleApplyFilter(contractFilteredValues);
    }
  }, [currentTab, handleApplyFilter, pageId, selectedFilters, userPermissions]);

  useEffect(() => {
    if (isPageSettings) {
      dispatch(masterDataActions.fetchAllForSettings(pageId));
    } else {
      dispatch(masterDataActions.fetchAll());
    }
  }, [dispatch, pageId, currentTab, isPageSettings]);

  useEffect(() => {
    if (
      hasSEOContractPermissions &&
      [DETAILS_ANALYSIS, LANDING_PAGE_ANALYZE, CV_ATTRIBUTE].includes(pageId) &&
      // handle for case customview apply without google search console filter
      viewStatus !== 'loading' &&
      SEARCH_WORD in displayItems &&
      !(FILTER_KEY_SEARCH_CONSOLE in storeFilters) &&
      displayItems[SEARCH_WORD]
    ) {
      handleApplyFilter({
        ...storeFilters,
        [FILTER_KEY_SEARCH_CONSOLE]: {
          type: 3,
          values: ['反映する'],
          ids: ['1'],
          option: null,
          title: 'Google Search Console連携',
          order: 2,
        },
      });
    }
  }, [
    dispatch,
    pageId,
    currentTab,
    isPageSettings,
    displayItems,
    handleApplyFilter,
    storeFilters,
    hasSEOContractPermissions,
    viewStatus,
  ]);

  return (
    <FilterForm
      isPageSettings={isPageSettings}
      masterData={mergedMasterData}
      filterableList={filterableList}
      selectedFilters={selectedFilters}
      applyFilter={handleApplyFilter}
      userPermissions={userPermissions}
      pageId={pageId}
      currentTab={currentTab}
      priorityAxis={priorityAxis}
      crossDeviceReflectionData={crossDeviceReflectionData}
      displayItems={displayItems}
      storeFilters={storeFilters}
    />
  );
};

FilterContainer.propTypes = {
  handleApplyFilter: func.isRequired,
  selectedFilters: shape({}).isRequired,
  crossDeviceReflectionData: shape({}).isRequired,
  storeFilters: shape({}).isRequired,
};

export default FilterContainer;
