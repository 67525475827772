import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { func } from 'prop-types';
import {
  FUNC_CODE_CSV_SETTING,
  CSV_CURRENCY_SYMBOL,
  CSV_OLD_SORT_ORDER,
} from 'domain/settings/display-items';
import CsvSettingActions from 'store/csv-setting/actions';
import CsvSettingSelectors from 'store/csv-setting/selectors';
import DisplayItemsApi from 'services/api/DisplayItemsApi';
import { CSV_SETTING } from 'services/routes/constants';
import screenTitleConfigs from 'services/common/screenTitleConfigs';
import EbisRadio from 'views/atoms/radio/EbisRadio';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import SettingModal from 'views/pages/system-setting/components/Modal';
import FormGroup from 'views/molecules/FormGroup/FormGroup';
import 'views/molecules/modal/csvsettingmodal.scss';
import { FALSE_FLAG, TRUE_FLAG } from 'domain/consts';

function CsvSettingModal(props) {
  const dispatch = useDispatch();
  const { onHide } = props;

  const [isOldSortOrder, setOldSortOrder] = useState();
  const [isCurrencySymbol, setCurrencySymbol] = useState();

  const funcId = FUNC_CODE_CSV_SETTING;
  const { displayitemsApi = DisplayItemsApi } = screenTitleConfigs[CSV_SETTING];
  useEffect(() => {
    if (funcId) {
      dispatch(CsvSettingActions.fetch({ funcId, displayitemsApi }));
    }
  }, [dispatch, funcId, displayitemsApi]);

  // Get data from store
  const modalItems = useSelector(
    CsvSettingSelectors.getModalSettings,
    shallowEqual
  );

  // Get status from store
  const isLoading = useSelector(
    CsvSettingSelectors.getModalStatus,
    shallowEqual
  );

  useEffect(() => {
    if (typeof modalItems !== 'undefined') {
      if (typeof modalItems.csvOldSortOrder !== 'undefined') {
        setOldSortOrder(modalItems.csvOldSortOrder);
      } else {
        setOldSortOrder(false);
      }
      if (typeof modalItems.currencySymbol !== 'undefined') {
        setCurrencySymbol(modalItems.currencySymbol);
      } else {
        setCurrencySymbol(true);
      }
    }
  }, [modalItems]);

  const handleCheckedButton = useCallback(() => {
    setOldSortOrder(!isOldSortOrder);
  }, [isOldSortOrder]);

  const handleChangeRadio = (value) => {
    const isEnable = value === TRUE_FLAG;
    setCurrencySymbol(isEnable);
  };

  const handleSubmit = useCallback(
    (data) => {
      dispatch(
        CsvSettingActions.create({
          data,
          funcId,
          displayitemsApi,
        })
      );
      onHide();
    },
    [dispatch, funcId, onHide, displayitemsApi]
  );

  return (
    <SettingModal
      isShow
      loading={isLoading !== 'succeeded'}
      onSubmit={() =>
        handleSubmit({
          [CSV_OLD_SORT_ORDER]: isOldSortOrder,
          [CSV_CURRENCY_SYMBOL]: isCurrencySymbol,
        })
      }
      onHide={onHide}
      title="CSV出力設定"
      dialogClassName="display-setting__modal-label"
      submitButtonText="設定を保存"
    >
      <FormGroup
        label="表示項目の並び順（広告タブの対象画面のみ）"
        note={
          <>
            ※適用できる対象画面は広告タブのカテゴリ分析・詳細分析・コンバージョン属性画面等に限ります。
            <br />
            　対象画面や並び順の詳細は
            <a
              href="https://support.ebis.ne.jp/s/article/000004879"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </a>
            をご確認ください。
          </>
        }
        variant="csvOldSortOrder"
      >
        <EbisCheckbox
          name={CSV_OLD_SORT_ORDER}
          text="旧バージョン（ADエビス）と同様の並び順で出力する"
          value={TRUE_FLAG}
          checked={isOldSortOrder}
          changeSelectBoxValue={handleCheckedButton}
        />
      </FormGroup>
      <FormGroup
        label="金額項目出力設定"
        variant="currencySymbol"
        note="※設定内容は、全ての分析画面のCSVに適用されます。"
      >
        <EbisRadio
          name={CSV_CURRENCY_SYMBOL}
          label="通貨として出力（￥マークあり）"
          value={TRUE_FLAG}
          checked={isCurrencySymbol}
          onChange={handleChangeRadio}
        />
        <EbisRadio
          name={CSV_CURRENCY_SYMBOL}
          value={FALSE_FLAG}
          label="数値として出力（￥マークなし）"
          checked={!isCurrencySymbol}
          onChange={handleChangeRadio}
        />
      </FormGroup>
    </SettingModal>
  );
}

CsvSettingModal.propTypes = {
  onHide: func.isRequired,
};

export default CsvSettingModal;
