import React from 'react';
import * as pages from 'services/routes/constants';
import { TAB_ALL, TAB_AD, TAB_COMMON } from 'store/settings/constant';
import { AD_MANAGEMENT_MODE } from 'domain/ad-management/consts';
import { TAG_MANAGEMENT_TAB } from 'domain/tag-management/consts';
import { AGENCY_FILTER_LIMIT, REPORT_TYPE } from 'domain/consts';
import {
  LOG_CONTRACT_PERMISSIONS,
  LOG_CONTRACT_PERMISSIONS_FOR_SETTINGS,
  CROSS_DEVICE_PERMISSIONS,
  SEO_CONTRACT_DISPLAY_SEARCH_CONSOLE,
} from 'domain/permissions/contractGroups';

const FILTER_KEY_CHANNEL = 'channel_access_type';
const FILTER_KEY_CV = 'conv_id';
const FILTER_KEY_MEMBER_NAME = 'member_name';
const FILTER_KEY_AMOUNT = 'amount';
const FILTER_KEY_OTHER1 = 'other1';
const FILTER_KEY_OTHER2 = 'other2';
const FILTER_KEY_OTHER3 = 'other3';
const FILTER_KEY_OTHER4 = 'other4';
const FILTER_KEY_OTHER5 = 'other5';
const FILTER_KEY_DEVICE = 'terminal_type';
const FILTER_KEY_DIRECTORY = 'directory';
const FILTER_KEY_CROSS_DEVICE = 'cross_device';
const FILTER_KEY_USER_ID = 'tracking_data';
const FILTER_KEY_CHANNEL_DISPLAY = 'display_channel';
const FILTER_KEY_CHANNEL_DISPLAY_RANGE = 'display_channel_range';
const FILTER_KEY_AD_GROUP1 = 'ad_group1_id';
const FILTER_KEY_AD_GROUP2 = 'ad_group2_id';
const FILTER_KEY_MEDIA = 'media_id';
const FILTER_KEY_AD_NAME = 'ad_name';
const FILTER_KEY_AD_ID = 'ad_id';
const FILTER_KEY_AGENCY = 'agency_id';
const FILTER_KEY_AD_NOTE = 'ad_note';
const FILTER_KEY_LINK_URL = 'link_url';
const FILTER_KEY_SEARCH_WORD = 'search_word';
const FILTER_KEY_LANDING_PAGE_URL = 'landing_page_url';
const FILTER_KEY_CONTACT_HISTORY = 'contact_history';
const FILTER_KEY_LTV_PRODUCT_NAME = 'product_name';
const FILTER_KEY_LTV_OFFER_NAME = 'offer_name';
const FILTER_KEY_PAGE_ID = 'page_id';
const FILTER_KEY_PAGE_TITLE = 'page_title';
const FILTER_KEY_PAGE_URL = 'page_url';
const FILTER_KEY_CV_TITLE = 'cv_title';
const FILTER_KEY_CONTENT_CATEGORY = 'content_category_id';
const FILTER_KEY_CONTENT_CATEGORY_NAME = 'content_category_name';
const FILTER_KEY_REGISTER_DATE = 'register_date';
const FILTER_KEY_REGISTER_DATE_MIN = 'regist_date_min';
const FILTER_KEY_REGISTER_DATE_MAX = 'regist_date_max';
const FILTER_KEY_UPDATE_DATE = 'update_date';
const FILTER_KEY_UPDATE_DATE_MIN = 'update_date_min';
const FILTER_KEY_UPDATE_DATE_MAX = 'update_date_max';

const FILTER_KEY_ADMNG_AD_TYPE = `ad_type`;
const FILTER_KEY_ADMNG_KEYWORD = `keyword`;
const FILTER_KEY_ADMNG_URL = `url`;
const FILTER_KEY_ADMNG_MEASUREMENT_TYPE = `measurement_type`;
const FILTER_KEY_ADMNG_AD_ID = `ad_id`;
const FILTER_KEY_ADMNG_MEDIA_ID = `media_id`;
const FILTER_KEY_ADMNG_AD_GROUP1_ID = `ad_group1_id`;
const FILTER_KEY_ADMNG_AD_GROUP2_ID = `ad_group2_id`;
const FILTER_KEY_ADMNG_AD_NOTE = `ad_note`;
const FILTER_KEY_ADMNG_AD_REGISTER_DATE = `ad_register_date`;
const FILTER_KEY_ADMNG_AD_UPDATE_DATE = `ad_update_date`;
const FILTER_KEY_ADMNG_TEXT_DATA = `text_data`;
const FILTER_KEY_ADMNG_DEVICE = `terminal_type`;
export const FILTER_KEY_OWNED_MEDIA_FLAG = 'owned_media_flag';

// for screen 広告カテゴリ管理
const FILTER_KEY_MEDIA_GROUP_MEDIA_ID = 'media_type_id';
const FILTER_KEY_MEDIA_GROUP_MEDIA_NAME = 'media_type_name';
const FILTER_KEY_MEDIA_GROUP_AD_GROUP1_ID = 'adgroup1_id';
const FILTER_KEY_MEDIA_GROUP_AD_GROUP1_NAME = 'adgroup1_name';
const FILTER_KEY_MEDIA_GROUP_AD_GROUP2_ID = 'adgroup2_id';
const FILTER_KEY_MEDIA_GROUP_AD_GROUP2_NAME = 'adgroup2_name';

// for media ad campaign and media ad group
const FILTER_KEY_MEDIA_SIDE_GROUP = 'media_side_group_id';
const FILTER_KEY_MEDIA_SIDE_CAMPAIGN = 'media_side_campaign_id';
const FILTER_KEY_MEDIA_ACCOUNT = 'media_account_id';
const FILTER_KEY_MEDIA_SIDE_AD_ID = 'media_side_ad_id';
const FILTER_KEY_MEDIA_SIDE_AD_NAME = 'media_side_ad_name';
const FILTER_KEY_MEDIA_SIDE_FINAL_URL = 'media_side_final_url';

const FILTER_KEY_LINK_PAGE_TITLE = 'link_page_title';

export const FILTER_KEY_SEARCH_CONSOLE = 'google_search_console_flag';

export const EBISADMIN = 'ebisadmin';

const ONLY_EBIS_AXIS_FILTERS = [
  FILTER_KEY_AD_GROUP1,
  FILTER_KEY_AD_GROUP2,
  FILTER_KEY_AD_NAME,
  FILTER_KEY_AD_ID,
  FILTER_KEY_MEDIA,
];

const ONLY_MEDIA_SYNC_AXIS_FILTERS = [
  FILTER_KEY_MEDIA_ACCOUNT,
  FILTER_KEY_MEDIA_SIDE_CAMPAIGN,
  FILTER_KEY_MEDIA_SIDE_GROUP,
  FILTER_KEY_MEDIA_SIDE_AD_ID,
  FILTER_KEY_MEDIA_SIDE_AD_NAME,
  FILTER_KEY_MEDIA_SIDE_FINAL_URL,
];

const PAGES_SETTINGS = [
  pages.AD_MANAGEMENT_MEDIA_TYPE,
  pages.AD_MANAGEMENT_AD_GROUP_1,
  pages.AD_MANAGEMENT_AD_GROUP_2,
  pages.TAG_MANAGEMENT,
  pages.SYSTEM_SETTING,
  pages.MEASUREMENT_SITE_PAGE_MANAGEMENT,
];

const PAGES_REPORTS = [
  pages.CATEGORY_ANALYZE,
  pages.DETAILS_ANALYSIS,
  pages.COMPARE_PERIOD,
  pages.CV_ATTRIBUTE,
  pages.CV_FLOW,
  pages.LTV_ANALYZE,
  pages.LANDING_PAGE_ANALYZE,
  pages.COST_ALLOCATION,
  pages.LPO_LINK,
  pages.LPO_PERIOD,
  pages.INFLOW_ANALYSIS,
];

const PAGES_LPO = [pages.LPO_LINK, pages.LPO_PERIOD];
const PAGES_LOGS = [pages.LOG_PAGE_ANALYZE, pages.LOG_PERIOD_ANALYZE];

// for DataExport screen
export const FILTER_KEY_CHANNELS = 'channel_access_types';
export const FILTER_KEY_TARGET_EXPIREDAYS = 'target_expiredays';
export const FILTER_KEY_MEMBER_NAME_EXCLUDE = 'member_name_exclude';
export const FILTER_KEY_OTHER1_EXCLUDE = 'other1_exclude';
export const FILTER_KEY_OTHER2_EXCLUDE = 'other2_exclude';
export const FILTER_KEY_OTHER3_EXCLUDE = 'other3_exclude';
export const FILTER_KEY_OTHER4_EXCLUDE = 'other4_exclude';
export const FILTER_KEY_OTHER5_EXCLUDE = 'other5_exclude';
export const FILTER_KEY_AD_GROUP1_EXCLUDE = 'ad_group1_id_exclude';
export const FILTER_KEY_AD_GROUP2_EXCLUDE = 'ad_group2_id_exclude';
export const FILTER_KEY_MEDIA_EXCLUDE = 'media_id_exclude';
export const FILTER_KEY_AD_NAME_EXCLUDE = 'ad_name_exclude';
export const FILTER_KEY_AD_ID_EXCLUDE = 'ad_id_exclude';
export const FILTER_KEY_SEARCH_WORD_EXCLUDE = 'search_word_exclude';
export const FILTER_KEY_CONTACT_HISTORY_AD = 'contact_history_ad';
export const FILTER_KEY_CONTACT_HISTORY_SEARCH = 'contact_history_search';
export const FILTER_KEY_CONTACT_HISTORY_PAGE = 'contact_history_page';
export const FILTER_KEY_AD_DATE = 'ad_date';
export const FILTER_KEY_AD_DATE_MIN = 'ad_date_min';
export const FILTER_KEY_AD_DATE_MAX = 'ad_date_max';

// Group filter
export const FILTER_GROUP_CONVERSION_EXCLUDE = 'conversion_exclude';
export const FILTER_GROUP_AD_EXCLUDE = 'ad_exclude';
export const FILTER_GROUP_AD = 'ad';
export const FILTER_GROUP_SEARCH = 'search';
export const FILTER_GROUP_PAGE = 'page';

// Operator
export const FILTER_OPERATOR_INCLUDE = 1;
export const FILTER_OPERATOR_EXCLUDE = 2;
export const FILTER_OPERATOR_EXACT = 3;
export const FILTER_OPERATOR_FRONT = 4;
export const FILTER_OPERATOR_UNREGISTER = 5;

// Validate
export const MAX_LENGTH_MEMBER_NAME = 50;
export const MAX_LENGTH_OTHER = 200;
export const MAX_VALUE_AMOUNT = 999999999999;

// Filter type
export const FILTER_TYPE_CHECKBOX = 1;

const ignoreOption = [
  FILTER_KEY_USER_ID,
  FILTER_KEY_MEMBER_NAME,
  FILTER_KEY_OTHER1,
  FILTER_KEY_OTHER2,
  FILTER_KEY_OTHER3,
  FILTER_KEY_OTHER4,
  FILTER_KEY_OTHER5,
  FILTER_KEY_AD_ID,
  FILTER_KEY_PAGE_ID,
  FILTER_KEY_SEARCH_WORD,
  FILTER_KEY_MEMBER_NAME_EXCLUDE,
  FILTER_KEY_OTHER1_EXCLUDE,
  FILTER_KEY_OTHER2_EXCLUDE,
  FILTER_KEY_OTHER3_EXCLUDE,
  FILTER_KEY_OTHER4_EXCLUDE,
  FILTER_KEY_OTHER5_EXCLUDE,
  FILTER_KEY_AD_ID_EXCLUDE,
  FILTER_KEY_SEARCH_WORD_EXCLUDE,
];
// 「次を含む」「次を含まない」「次に完全一致」「次で始まる」
const FilterTypeTwoOptions = [
  {
    key: FILTER_OPERATOR_INCLUDE,
    name: '次を含む',
    value: '次を含む',
    label: 'を含む',
    ignore: {
      [pages.DATA_EXPORT]: [
        FILTER_KEY_PAGE_ID,
        FILTER_KEY_AD_ID,
        FILTER_KEY_AD_ID_EXCLUDE,
        FILTER_KEY_USER_ID,
      ],
    },
  },
  {
    key: FILTER_OPERATOR_EXCLUDE,
    name: '次を含まない',
    value: '次を含まない',
    label: 'を含まない',
    ignore: { [pages.DATA_EXPORT]: ignoreOption },
  },
  {
    key: FILTER_OPERATOR_EXACT,
    name: '次に完全一致',
    value: '次に完全一致',
    label: 'に完全一致',
  },
  {
    key: FILTER_OPERATOR_FRONT,
    name: '次で始まる',
    value: '次で始まる',
    label: 'で始まる',
    ignore: { [pages.DATA_EXPORT]: ignoreOption },
  },
];

const FILTER_KEY_UNREGISTER = {
  key: FILTER_OPERATOR_UNREGISTER,
  name: '未登録のみ',
  value: '未登録のみ',
  label: '未登録のみ',
  disabledInput: true,
};

const FilterTypeTwoDisplayOptions = [
  {
    key: FILTER_OPERATOR_INCLUDE,
    name: 'を含む',
    value: 'を含む',
  },
  {
    key: FILTER_OPERATOR_EXCLUDE,
    name: 'を含まない',
    value: 'を含まない',
  },
  {
    key: FILTER_OPERATOR_EXACT,
    name: '完全一致',
    value: '完全一致',
  },
  {
    key: FILTER_OPERATOR_FRONT,
    name: 'から始まる',
    value: 'から始まる',
  },
  {
    key: FILTER_OPERATOR_UNREGISTER,
    name: '未登録のみ',
    value: '未登録のみ',
  },
];

const allFilterableGroup = {
  [FILTER_GROUP_CONVERSION_EXCLUDE]: {
    title: '除外するCV属性',
    order: 100,
    isTitleGroup: true,
  },
  [FILTER_GROUP_AD_EXCLUDE]: {
    title: '除外する広告',
    order: 200,
    isTitleGroup: true,
  },
  [FILTER_GROUP_AD]: { title: '広告', order: 300, isTitleGroup: true },
  [FILTER_GROUP_SEARCH]: { title: '自然検索', order: 400, isTitleGroup: true },
  [FILTER_GROUP_PAGE]: { title: 'ページ', order: 500, isTitleGroup: true },
};

const allFilterableList = {
  [FILTER_KEY_CHANNEL]: {
    title: '集計チャネル',
    allowScreen: [...PAGES_REPORTS],
    allowTab: [TAB_ALL, TAB_COMMON],
    type: 4,
    order: 1,
  },
  [FILTER_KEY_CHANNELS]: {
    title: '集計チャネル',
    allowScreen: [pages.DATA_EXPORT],
    type: 3,
    order: 1,
  },
  [FILTER_KEY_CV]: {
    title: '対象コンバージョン',
    denyScreen: [
      ...PAGES_SETTINGS,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
    ],
    type: 1,
    order: 2,
  },
  [FILTER_KEY_MEMBER_NAME]: {
    title: 'ユーザー名（コンバージョン属性）',
    denyScreen: [
      ...PAGES_SETTINGS,
      ...PAGES_LOGS,
      pages.LTV_ANALYZE,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
      ...PAGES_LPO,
    ],
    type: 2,
    order: 3,
  },
  [FILTER_KEY_AMOUNT]: {
    title: '売上金額（コンバージョン属性）',
    denyScreen: [
      ...PAGES_SETTINGS,
      ...PAGES_LOGS,
      pages.LTV_ANALYZE,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
      ...PAGES_LPO,
    ],
    type: 5,
    order: 4,
  },
  [FILTER_KEY_OTHER1]: {
    title: '項目１（コンバージョン属性）',
    denyScreen: [
      ...PAGES_SETTINGS,
      ...PAGES_LOGS,
      pages.LTV_ANALYZE,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
      ...PAGES_LPO,
    ],
    type: 2,
    order: 5,
  },
  [FILTER_KEY_OTHER2]: {
    title: '項目２（コンバージョン属性）',
    denyScreen: [
      ...PAGES_SETTINGS,
      ...PAGES_LOGS,
      pages.LTV_ANALYZE,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
      ...PAGES_LPO,
    ],
    type: 2,
    order: 6,
  },
  [FILTER_KEY_OTHER3]: {
    title: '項目３（コンバージョン属性）',
    denyScreen: [
      ...PAGES_SETTINGS,
      ...PAGES_LOGS,
      pages.LTV_ANALYZE,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
      ...PAGES_LPO,
    ],
    type: 2,
    order: 7,
  },
  [FILTER_KEY_OTHER4]: {
    title: '項目４（コンバージョン属性）',
    denyScreen: [
      ...PAGES_SETTINGS,
      ...PAGES_LOGS,
      pages.LTV_ANALYZE,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
      ...PAGES_LPO,
    ],
    type: 2,
    order: 8,
  },
  [FILTER_KEY_OTHER5]: {
    title: '項目５（コンバージョン属性）',
    denyScreen: [
      ...PAGES_SETTINGS,
      ...PAGES_LOGS,
      pages.LTV_ANALYZE,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
      ...PAGES_LPO,
    ],
    type: 2,
    order: 9,
  },
  [FILTER_KEY_MEMBER_NAME_EXCLUDE]: {
    title: 'ユーザー名',
    allowScreen: [pages.DATA_EXPORT],
    type: 2,
    order: 9,
    group: FILTER_GROUP_CONVERSION_EXCLUDE,
  },
  [FILTER_KEY_OTHER1_EXCLUDE]: {
    title: '項目１',
    allowScreen: [pages.DATA_EXPORT],
    type: 2,
    order: 9,
    group: FILTER_GROUP_CONVERSION_EXCLUDE,
  },
  [FILTER_KEY_OTHER2_EXCLUDE]: {
    title: '項目2',
    allowScreen: [pages.DATA_EXPORT],
    type: 2,
    order: 9,
    group: FILTER_GROUP_CONVERSION_EXCLUDE,
  },
  [FILTER_KEY_OTHER3_EXCLUDE]: {
    title: '項目3',
    allowScreen: [pages.DATA_EXPORT],
    type: 2,
    order: 9,
    group: FILTER_GROUP_CONVERSION_EXCLUDE,
  },
  [FILTER_KEY_OTHER4_EXCLUDE]: {
    title: '項目4',
    allowScreen: [pages.DATA_EXPORT],
    type: 2,
    order: 9,
    group: FILTER_GROUP_CONVERSION_EXCLUDE,
  },
  [FILTER_KEY_OTHER5_EXCLUDE]: {
    title: '項目5',
    allowScreen: [pages.DATA_EXPORT],
    type: 2,
    order: 9,
    group: FILTER_GROUP_CONVERSION_EXCLUDE,
  },
  [FILTER_KEY_DEVICE]: {
    title: 'デバイス',
    denyScreen: [
      ...PAGES_SETTINGS,
      pages.LTV_ANALYZE,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
    ],
    type: 3,
    order: 10,
  },
  [FILTER_KEY_CROSS_DEVICE]: {
    title: 'クロスデバイス',
    denyScreen: [...PAGES_SETTINGS, ...PAGES_LOGS, ...PAGES_LPO],
    type: 3,
    order: 10,
    permissionSet: {
      [pages.CATEGORY_ANALYZE]: CROSS_DEVICE_PERMISSIONS,
      [pages.DETAILS_ANALYSIS]: CROSS_DEVICE_PERMISSIONS,
      [pages.COMPARE_PERIOD]: CROSS_DEVICE_PERMISSIONS,
      [pages.CV_ATTRIBUTE]: CROSS_DEVICE_PERMISSIONS,
      [pages.CV_FLOW]: CROSS_DEVICE_PERMISSIONS,
      [pages.INFLOW_ANALYSIS]: CROSS_DEVICE_PERMISSIONS,
    },
  },
  [FILTER_KEY_DIRECTORY]: {
    title: 'ディレクトリ',
    type: 2,
    order: 11,
    allowScreen: [pages.LOG_PAGE_ANALYZE],
  },
  [FILTER_KEY_USER_ID]: {
    title: 'ユーザーID',
    type: 2,
    order: 11,
    allowScreen: [pages.CV_ATTRIBUTE, pages.CV_FLOW, pages.DATA_EXPORT],
    allowTab: [TAB_AD, TAB_ALL, TAB_COMMON, REPORT_TYPE.CVATTR],
  },
  [FILTER_KEY_TARGET_EXPIREDAYS]: {
    title: 'トラッキング対象期間',
    type: 10,
    order: 11,
    unit: '日',
    note: '1～366日で設定できます。',
    allowScreen: [pages.DATA_EXPORT],
  },
  [FILTER_KEY_CHANNEL_DISPLAY]: {
    title: '表示チャネル',
    allowScreen: [...PAGES_REPORTS],
    allowTab: [TAB_ALL, TAB_COMMON],
    type: 3,
    order: 12,
  },
  [FILTER_KEY_AD_GROUP1]: {
    title: '広告グループ１',
    denyScreen: [
      ...PAGES_SETTINGS,
      ...PAGES_LOGS,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
    ],
    type: 1,
    order: 13,
    group: FILTER_GROUP_AD,
  },
  [FILTER_KEY_AD_GROUP2]: {
    title: '広告グループ２',
    denyScreen: [
      ...PAGES_SETTINGS,
      ...PAGES_LOGS,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
    ],
    type: 1,
    order: 14,
    group: FILTER_GROUP_AD,
  },
  [FILTER_KEY_MEDIA]: {
    title: '媒体種別',
    denyScreen: [
      ...PAGES_SETTINGS,
      ...PAGES_LOGS,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
    ],
    type: 1,
    order: 15,
    group: FILTER_GROUP_AD,
  },
  [FILTER_KEY_AD_ID]: {
    title: '広告ID',
    denyScreen: [
      ...PAGES_SETTINGS,
      ...PAGES_LOGS,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
    ],
    type: 2,
    order: 16,
    group: FILTER_GROUP_AD,
  },
  [FILTER_KEY_AD_NAME]: {
    title: '広告名',
    denyScreen: [
      ...PAGES_SETTINGS,
      ...PAGES_LOGS,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
    ],
    type: 2,
    order: 17,
    group: FILTER_GROUP_AD,
  },
  [FILTER_KEY_AD_GROUP1_EXCLUDE]: {
    title: '広告グループ１',
    allowScreen: [pages.DATA_EXPORT],
    type: 1,
    order: 13,
    group: FILTER_GROUP_AD_EXCLUDE,
  },
  [FILTER_KEY_AD_GROUP2_EXCLUDE]: {
    title: '広告グループ２',
    allowScreen: [pages.DATA_EXPORT],
    type: 1,
    order: 14,
    group: FILTER_GROUP_AD_EXCLUDE,
  },
  [FILTER_KEY_MEDIA_EXCLUDE]: {
    title: '媒体種別',
    allowScreen: [pages.DATA_EXPORT],
    type: 1,
    order: 15,
    group: FILTER_GROUP_AD_EXCLUDE,
  },
  [FILTER_KEY_AD_ID_EXCLUDE]: {
    title: '広告ID',
    allowScreen: [pages.DATA_EXPORT],
    type: 2,
    order: 16,
    group: FILTER_GROUP_AD_EXCLUDE,
  },
  [FILTER_KEY_AD_NAME_EXCLUDE]: {
    title: '広告名',
    allowScreen: [pages.DATA_EXPORT],
    type: 2,
    order: 17,
    group: FILTER_GROUP_AD_EXCLUDE,
  },
  [FILTER_KEY_AGENCY]: {
    title: '担当代理店',
    type: 1,
    order: 17,
    maxItemSelect: AGENCY_FILTER_LIMIT,
    allowScreen: [
      pages.CATEGORY_ANALYZE,
      pages.DETAILS_ANALYSIS,
      pages.COMPARE_PERIOD,
      pages.CV_ATTRIBUTE,
      pages.CV_FLOW,
      pages.LANDING_PAGE_ANALYZE,
      pages.LTV_ANALYZE,
      pages.INFLOW_ANALYSIS,
      ...PAGES_LPO,
    ],
  },
  [FILTER_KEY_AD_NOTE]: {
    title: '備考',
    denyScreen: [
      ...PAGES_SETTINGS,
      ...PAGES_LOGS,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
    ],
    type: 2,
    order: 18,
    group: FILTER_GROUP_AD,
  },
  [FILTER_KEY_AD_DATE]: {
    title: '出稿期間',
    type: 6,
    order: 18,
    group: FILTER_GROUP_AD,
    allowScreen: [pages.DATA_EXPORT],
    defaultProps: {
      minDate: '2004/01/01',
      maxDate: `${new Date().getFullYear() + 5}/12/31`,
    },
  },
  [FILTER_KEY_LINK_URL]: {
    title: 'リンク先URL',
    denyScreen: [
      ...PAGES_SETTINGS,
      ...PAGES_LOGS,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
    ],
    type: 2,
    order: 19,
    group: FILTER_GROUP_AD,
  },
  [FILTER_KEY_SEARCH_WORD]: {
    title: '検索ワード',
    allowScreen: [...PAGES_REPORTS, pages.DATA_EXPORT],
    allowTab: [TAB_ALL, TAB_COMMON, REPORT_TYPE.CHANNEL, REPORT_TYPE.CVATTR],
    type: 2,
    order: 20,
    group: FILTER_GROUP_SEARCH,
  },
  [FILTER_KEY_SEARCH_WORD_EXCLUDE]: {
    title: '除外ワード',
    allowScreen: [pages.DATA_EXPORT],
    type: 2,
    order: 20,
    group: FILTER_GROUP_SEARCH,
  },
  [FILTER_KEY_LANDING_PAGE_URL]: {
    title: 'URL（ランディングページ）',
    denyScreen: [
      ...PAGES_SETTINGS,
      ...PAGES_LOGS,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
      ...PAGES_LPO,
    ],
    type: 2,
    order: 21,
    group: FILTER_GROUP_PAGE,
  },
  [FILTER_KEY_CONTACT_HISTORY]: {
    title: '接触タイミング',
    allowScreen: [pages.CV_ATTRIBUTE, pages.CV_FLOW],
    // allowTab: [TAB_ALL],
    type: 0,
    order: 22,
  },
  [FILTER_KEY_CONTACT_HISTORY_AD]: {
    title: '接触タイミング（広告）',
    tooltip:
      '接触タイミングのみ指定されている場合は適用されません。広告の条件も合わせて設定してください。',
    type: 0,
    order: 22,
    allowScreen: [pages.DATA_EXPORT],
    allowTab: [REPORT_TYPE.CVATTR],
  },
  [FILTER_KEY_CONTACT_HISTORY_SEARCH]: {
    title: '接触タイミング（自然検索）',
    tooltip:
      '接触タイミングのみ指定されている場合は適用されません。自然検索の条件も合わせて設定してください。',
    type: 0,
    order: 22,
    allowScreen: [pages.DATA_EXPORT],
    allowTab: [REPORT_TYPE.CVATTR],
  },
  [FILTER_KEY_CONTACT_HISTORY_PAGE]: {
    title: '接触タイミング（ページ）',
    tooltip:
      '接触タイミングのみ指定されている場合は適用されません。ページの条件も合わせて設定してください。',
    type: 0,
    order: 22,
    allowScreen: [pages.DATA_EXPORT],
    allowTab: [REPORT_TYPE.CVATTR],
  },
  [FILTER_KEY_LTV_PRODUCT_NAME]: {
    title: '商品',
    allowScreen: [
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
      pages.LTV_ANALYZE,
    ],
    // allowTab: [TAB_ALL],
    type: 2,
    order: 23,
  },
  [FILTER_KEY_LTV_OFFER_NAME]: {
    title: 'オファー',
    allowScreen: [
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
      pages.LTV_ANALYZE,
    ],
    // allowTab: [TAB_ALL],
    type: 2,
    order: 24,
  },
  [FILTER_KEY_MEDIA_GROUP_MEDIA_ID]: {
    title: 'ID',
    allowScreen: [pages.AD_MANAGEMENT_MEDIA_TYPE],
    type: 7,
    order: 25,
  },
  [FILTER_KEY_MEDIA_GROUP_MEDIA_NAME]: {
    title: '媒体種別',
    allowScreen: [pages.AD_MANAGEMENT_MEDIA_TYPE],
    type: 2,
    order: 26,
  },
  [FILTER_KEY_MEDIA_GROUP_AD_GROUP1_ID]: {
    title: 'ID',
    allowScreen: [pages.AD_MANAGEMENT_AD_GROUP_1],
    type: 7,
    order: 27,
  },
  [FILTER_KEY_MEDIA_GROUP_AD_GROUP1_NAME]: {
    title: '広告グループ1',
    allowScreen: [pages.AD_MANAGEMENT_AD_GROUP_1],
    type: 2,
    order: 28,
  },
  [FILTER_KEY_MEDIA_GROUP_AD_GROUP2_ID]: {
    title: 'ID',
    allowScreen: [pages.AD_MANAGEMENT_AD_GROUP_2],
    type: 7,
    order: 29,
  },
  [FILTER_KEY_MEDIA_GROUP_AD_GROUP2_NAME]: {
    title: '広告グループ2',
    allowScreen: [pages.AD_MANAGEMENT_AD_GROUP_2],
    type: 2,
    order: 30,
  },
  [FILTER_KEY_PAGE_ID]: {
    title: 'ページID',
    type: 2,
    order: 31,
    allowScreen: [
      ...PAGES_LOGS,
      pages.TAG_MANAGEMENT,
      pages.MEASUREMENT_SITE_PAGE_MANAGEMENT,
      pages.LANDING_PAGE_ANALYZE,
      pages.CV_ATTRIBUTE,
      pages.CV_FLOW,
      pages.DATA_EXPORT,
    ],
    allowTab: [
      TAG_MANAGEMENT_TAB.CV,
      TAG_MANAGEMENT_TAB.PV,
      TAB_ALL,
      TAB_AD,
      TAB_COMMON,
      REPORT_TYPE.CHANNEL,
      REPORT_TYPE.CVATTR,
    ],
    permissionSet: {
      [pages.LANDING_PAGE_ANALYZE]: LOG_CONTRACT_PERMISSIONS,
      [pages.CV_ATTRIBUTE]: LOG_CONTRACT_PERMISSIONS,
      [pages.CV_FLOW]: LOG_CONTRACT_PERMISSIONS,
    },
    group: FILTER_GROUP_PAGE,
  },
  [FILTER_KEY_PAGE_TITLE]: {
    title: 'ページタイトル',
    type: 2,
    order: 32,
    allowScreen: [
      ...PAGES_LOGS,
      pages.TAG_MANAGEMENT,
      pages.MEASUREMENT_SITE_PAGE_MANAGEMENT,
      pages.LANDING_PAGE_ANALYZE,
      pages.CV_ATTRIBUTE,
      pages.CV_FLOW,
      pages.DATA_EXPORT,
    ],
    allowTab: [
      TAG_MANAGEMENT_TAB.CV,
      TAG_MANAGEMENT_TAB.PV,
      TAB_ALL,
      TAB_AD,
      TAB_COMMON,
      REPORT_TYPE.CHANNEL,
      REPORT_TYPE.CVATTR,
    ],
    permissionSet: {
      [pages.LANDING_PAGE_ANALYZE]: LOG_CONTRACT_PERMISSIONS,
      [pages.CV_ATTRIBUTE]: LOG_CONTRACT_PERMISSIONS,
      [pages.CV_FLOW]: LOG_CONTRACT_PERMISSIONS,
    },
    group: FILTER_GROUP_PAGE,
  },
  [FILTER_KEY_CV_TITLE]: {
    title: 'コンバージョン名',
    type: 2,
    order: 33,
    allowScreen: [pages.TAG_MANAGEMENT],
    allowTab: [TAG_MANAGEMENT_TAB.CV],
  },
  [FILTER_KEY_CONTENT_CATEGORY]: {
    title: 'コンテンツカテゴリ',
    type: 1,
    order: 34,
    allowScreen: [
      ...PAGES_LOGS,
      pages.TAG_MANAGEMENT,
      pages.MEASUREMENT_SITE_PAGE_MANAGEMENT,
      pages.LANDING_PAGE_ANALYZE,
      pages.CV_ATTRIBUTE,
      pages.CV_FLOW,
      pages.DATA_EXPORT,
    ],
    allowTab: [
      TAG_MANAGEMENT_TAB.PV,
      TAB_ALL,
      TAB_AD,
      TAB_COMMON,
      REPORT_TYPE.CHANNEL,
      REPORT_TYPE.CVATTR,
    ],
    permissionSet: {
      [pages.TAG_MANAGEMENT]: {
        [TAG_MANAGEMENT_TAB.PV]: LOG_CONTRACT_PERMISSIONS_FOR_SETTINGS,
      },
      [pages.LANDING_PAGE_ANALYZE]: LOG_CONTRACT_PERMISSIONS,
      [pages.CV_ATTRIBUTE]: LOG_CONTRACT_PERMISSIONS,
      [pages.CV_FLOW]: LOG_CONTRACT_PERMISSIONS,
    },
    group: FILTER_GROUP_PAGE,
  },
  [FILTER_KEY_CONTENT_CATEGORY_NAME]: {
    title: 'コンテンツカテゴリ',
    type: 2,
    order: 34,
    allowScreen: [pages.TAG_MANAGEMENT],
    allowTab: [TAG_MANAGEMENT_TAB.CONTENT_CATEGORY],
  },
  [FILTER_KEY_REGISTER_DATE]: {
    title: '登録日',
    type: 6,
    order: 35,
    defaultProps: {
      minDate: '2004/01/01', // TODO check old screen
      maxDate: `${new Date().getFullYear() + 5}/12/31`, // TODO check old screen
    },
    allowScreen: [pages.TAG_MANAGEMENT, pages.MEASUREMENT_SITE_PAGE_MANAGEMENT],
  },
  [FILTER_KEY_UPDATE_DATE]: {
    title: '最終更新日',
    type: 6,
    order: 36,
    defaultProps: {
      minDate: '2004/01/01', // TODO check old screen
      maxDate: `${new Date().getFullYear() + 5} /12/31`, // TODO check old screen
    },
    allowScreen: [pages.TAG_MANAGEMENT, pages.MEASUREMENT_SITE_PAGE_MANAGEMENT],
  },
  [FILTER_KEY_MEDIA_ACCOUNT]: {
    title: '媒体',
    denyScreen: [
      ...PAGES_LOGS,
      pages.TAG_MANAGEMENT,
      pages.MEASUREMENT_SITE_PAGE_MANAGEMENT,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
      pages.AD_MANAGEMENT_MEDIA_TYPE,
      pages.AD_MANAGEMENT_AD_GROUP_1,
      pages.AD_MANAGEMENT_AD_GROUP_2,
    ],
    type: 1,
    order: 37,
  },
  [FILTER_KEY_MEDIA_SIDE_AD_ID]: {
    title: '媒体広告ID',
    denyScreen: [
      ...PAGES_LOGS,
      pages.TAG_MANAGEMENT,
      pages.MEASUREMENT_SITE_PAGE_MANAGEMENT,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
      pages.AD_MANAGEMENT_MEDIA_TYPE,
      pages.AD_MANAGEMENT_AD_GROUP_1,
      pages.AD_MANAGEMENT_AD_GROUP_2,
    ],
    type: 2,
    order: 38,
  },
  [FILTER_KEY_MEDIA_SIDE_CAMPAIGN]: {
    title: '媒体キャンペーン',
    denyScreen: [
      ...PAGES_LOGS,
      pages.TAG_MANAGEMENT,
      pages.MEASUREMENT_SITE_PAGE_MANAGEMENT,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
      pages.AD_MANAGEMENT_MEDIA_TYPE,
      pages.AD_MANAGEMENT_AD_GROUP_1,
      pages.AD_MANAGEMENT_AD_GROUP_2,
    ],
    type: 1,
    order: 39,
  },
  [FILTER_KEY_MEDIA_SIDE_GROUP]: {
    title: '媒体広告グループ',
    denyScreen: [
      ...PAGES_LOGS,
      pages.TAG_MANAGEMENT,
      pages.MEASUREMENT_SITE_PAGE_MANAGEMENT,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
      pages.AD_MANAGEMENT_MEDIA_TYPE,
      pages.AD_MANAGEMENT_AD_GROUP_1,
      pages.AD_MANAGEMENT_AD_GROUP_2,
    ],
    type: 1,
    order: 40,
  },
  [FILTER_KEY_MEDIA_SIDE_AD_NAME]: {
    title: '媒体広告名',
    denyScreen: [
      ...PAGES_LOGS,
      pages.TAG_MANAGEMENT,
      pages.MEASUREMENT_SITE_PAGE_MANAGEMENT,
      pages.LTV_SETTINGS_PRODUCT,
      pages.LTV_SETTINGS_OFFER,
      pages.LTV_SETTINGS_CONDITION,
      pages.LTV_SETTINGS_AD,
      pages.AD_MANAGEMENT_MEDIA_TYPE,
      pages.AD_MANAGEMENT_AD_GROUP_1,
      pages.AD_MANAGEMENT_AD_GROUP_2,
    ],
    type: 2,
    order: 41,
  },
  [FILTER_KEY_PAGE_URL]: {
    title: 'リンク先URL',
    type: 2,
    order: 42,
    allowScreen: [...PAGES_LOGS, pages.MEASUREMENT_SITE_PAGE_MANAGEMENT],
  },
  [FILTER_KEY_OWNED_MEDIA_FLAG]: {
    title: 'サイトコンテンツ',
    allowScreen: [pages.MEASUREMENT_SITE_PAGE_MANAGEMENT],
    type: 3,
    order: 43,
  },
  [FILTER_KEY_LINK_PAGE_TITLE]: {
    title: 'リンク先タイトル',
    allowScreen: [...PAGES_LPO],
    type: 2,
    order: 44,
    group: FILTER_GROUP_AD,
  },
  [FILTER_KEY_SEARCH_CONSOLE]: {
    title: 'Google Search Console連携',
    tooltip: (
      <>
        「反映する」にチェックをいれるとGoogle Search
        Consoleのデータが分析画面に反映されます。詳しくは
        <a
          href={pages.APP_HELP_SC_SETTING}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
      </>
    ),

    allowScreen: [
      pages.DETAILS_ANALYSIS,
      pages.CV_ATTRIBUTE,
      pages.LANDING_PAGE_ANALYZE,
    ],
    allowTab: [TAB_ALL, TAB_COMMON],
    type: 3,
    order: 45,
    permissionSet: {
      [pages.DETAILS_ANALYSIS]: SEO_CONTRACT_DISPLAY_SEARCH_CONSOLE,
      [pages.CV_ATTRIBUTE]: SEO_CONTRACT_DISPLAY_SEARCH_CONSOLE,
      [pages.LANDING_PAGE_ANALYZE]: SEO_CONTRACT_DISPLAY_SEARCH_CONSOLE,
    },
  },
};

const adManagementFilterables = {
  // Ad management specific filters
  [FILTER_KEY_ADMNG_AD_TYPE]: {
    title: '広告種別',
    type: 4,
    order: 101,
    allowScreen: [pages.AD_MANAGEMENT],
    allowModes: [AD_MANAGEMENT_MODE.DELETED],
  },
  [FILTER_KEY_ADMNG_AD_GROUP1_ID]: {
    title: '広告グループ01',
    type: 1,
    order: 102,
    allowScreen: [pages.AD_MANAGEMENT],
  },
  [FILTER_KEY_ADMNG_AD_GROUP2_ID]: {
    title: '広告グループ02',
    type: 1,
    order: 103,
    allowScreen: [pages.AD_MANAGEMENT],
  },
  [FILTER_KEY_ADMNG_MEDIA_ID]: {
    title: '媒体種別',
    type: 1,
    order: 104,
    allowScreen: [pages.AD_MANAGEMENT],
  },
  [FILTER_KEY_ADMNG_AD_ID]: {
    title: '広告ID',
    type: 2,
    order: 105,
    allowScreen: [pages.AD_MANAGEMENT],
  },
  [FILTER_KEY_ADMNG_KEYWORD]: {
    title: '広告名',
    type: 2,
    order: 106,
    allowScreen: [pages.AD_MANAGEMENT],
  },
  [FILTER_KEY_AGENCY]: {
    title: '担当代理店',
    type: 1,
    order: 107,
    maxItemSelect: AGENCY_FILTER_LIMIT,
    allowScreen: [pages.AD_MANAGEMENT],
  },
  [FILTER_KEY_ADMNG_URL]: {
    title: 'リンク先URL',
    type: 2,
    order: 108,
    allowScreen: [pages.AD_MANAGEMENT],
  },
  [FILTER_KEY_ADMNG_AD_NOTE]: {
    title: '備考',
    type: 2,
    order: 109,
    allowScreen: [pages.AD_MANAGEMENT],
  },
  [FILTER_KEY_ADMNG_TEXT_DATA]: {
    title: 'テキスト原稿',
    type: 2,
    order: 110,
    allowScreen: [pages.AD_MANAGEMENT],
  },
  [FILTER_KEY_ADMNG_AD_REGISTER_DATE]: {
    title: '登録日',
    type: 6,
    order: 111,
    defaultProps: {
      minDate: '2004/01/01',
      maxDate: `${new Date().getFullYear() + 5} /12/31`,
    },
    allowScreen: [pages.AD_MANAGEMENT],
  },
  [FILTER_KEY_ADMNG_AD_UPDATE_DATE]: {
    title: '最終更新日',
    type: 6,
    order: 112,
    defaultProps: {
      minDate: '2004/01/01',
      maxDate: `${new Date().getFullYear() + 5} /12/31`,
    },
    allowScreen: [pages.AD_MANAGEMENT],
  },
  [FILTER_KEY_ADMNG_MEASUREMENT_TYPE]: {
    title: '計測方式',
    type: 4,
    order: 113,
    allowScreen: [pages.AD_MANAGEMENT],
    allowModes: [
      AD_MANAGEMENT_MODE.AD,
      AD_MANAGEMENT_MODE.ALL,
      AD_MANAGEMENT_MODE.DELETED,
    ],
  },
  [FILTER_KEY_ADMNG_DEVICE]: {
    title: 'デバイス',
    type: 3,
    order: 114,
    allowScreen: [pages.AD_MANAGEMENT],
    allowModes: [
      AD_MANAGEMENT_MODE.AD,
      AD_MANAGEMENT_MODE.LISTING,
      AD_MANAGEMENT_MODE.DELETED,
    ],
  },
  [FILTER_KEY_MEDIA_ACCOUNT]: {
    title: '媒体',
    allowScreen: [pages.AD_MANAGEMENT],
    allowModes: [
      AD_MANAGEMENT_MODE.AD,
      AD_MANAGEMENT_MODE.DELETED,
      AD_MANAGEMENT_MODE.ALL,
    ],
    type: 1,
    order: 115,
  },
  [FILTER_KEY_MEDIA_SIDE_AD_ID]: {
    title: '媒体広告ID',
    allowScreen: [pages.AD_MANAGEMENT],
    allowModes: [
      AD_MANAGEMENT_MODE.AD,
      AD_MANAGEMENT_MODE.DELETED,
      AD_MANAGEMENT_MODE.ALL,
    ],
    type: 2,
    order: 116,
  },
  [FILTER_KEY_MEDIA_SIDE_CAMPAIGN]: {
    title: '媒体キャンペーン',
    allowScreen: [pages.AD_MANAGEMENT],
    allowModes: [
      AD_MANAGEMENT_MODE.AD,
      AD_MANAGEMENT_MODE.DELETED,
      AD_MANAGEMENT_MODE.ALL,
    ],
    type: 1,
    order: 117,
  },
  [FILTER_KEY_MEDIA_SIDE_GROUP]: {
    title: '媒体広告グループ',
    allowScreen: [pages.AD_MANAGEMENT],
    allowModes: [
      AD_MANAGEMENT_MODE.AD,
      AD_MANAGEMENT_MODE.DELETED,
      AD_MANAGEMENT_MODE.ALL,
    ],
    type: 1,
    order: 118,
  },
  [FILTER_KEY_MEDIA_SIDE_AD_NAME]: {
    title: '媒体広告名',
    allowScreen: [pages.AD_MANAGEMENT],
    allowModes: [
      AD_MANAGEMENT_MODE.AD,
      AD_MANAGEMENT_MODE.DELETED,
      AD_MANAGEMENT_MODE.ALL,
    ],
    type: 2,
    order: 119,
  },
  [FILTER_KEY_MEDIA_SIDE_FINAL_URL]: {
    title: '媒体最終リンク先URL',
    allowScreen: [pages.AD_MANAGEMENT],
    allowModes: [
      AD_MANAGEMENT_MODE.AD,
      AD_MANAGEMENT_MODE.DELETED,
      AD_MANAGEMENT_MODE.ALL,
    ],
    type: 2,
    order: 120,
  },
};

// const TAB_AD = 'ad';
// const TAB_ALL = 'all';

const tabsSettings = {
  [TAB_AD]: {
    key: 'common.tabs.ad',
  },
  [TAB_ALL]: {
    key: 'common.tabs.all',
  },
  [TAB_COMMON]: {
    key: 'common.tabs.common',
  },
};

const GET_REPORT_DEBOUNCE_LENGTH = 100;
const UPDATE_BOOKMARK_DEBOUNCE_LENGTH = 500;

const FileValidationRule = {
  MAX_SIZE: 'MAX_SIZE',
  FILE_TYPES: 'FILE_TYPES',
  MULTIPLE: 'MULTIPLE',
};

const COLUMNS_TABLE_CONFIRM_CONTENT_INVALID = [
  {
    name: 'line',
    title: '対象行',
    width: 130,
  },
  {
    name: 'message',
    title: '対応方法',
    width: 0,
  },
];
const COLUMNS_TABLE_CONFIRM_CONTENT_INVALID_MERGED = [
  {
    name: 'line',
    title: '対象行',
    width: 280,
  },
  {
    name: 'message',
    title: '対応方法',
    width: 0,
  },
];

const FILTER_ITEMS_OWNED_MEDIA_FLAG = [
  {
    key: 't',
    name: '対象',
    value: '対象',
    order: 0,
  },
  {
    key: 'f',
    name: '対象外',
    value: '対象外',
    order: 1,
  },
];

export {
  FilterTypeTwoOptions,
  FilterTypeTwoDisplayOptions,
  allFilterableGroup,
  allFilterableList,
  adManagementFilterables,
  TAB_AD,
  TAB_ALL,
  TAB_COMMON,
  tabsSettings,
  PAGES_SETTINGS,
  PAGES_REPORTS,
  FILTER_KEY_CHANNEL,
  FILTER_KEY_CV,
  FILTER_KEY_MEMBER_NAME,
  FILTER_KEY_AMOUNT,
  FILTER_KEY_OTHER1,
  FILTER_KEY_OTHER2,
  FILTER_KEY_OTHER3,
  FILTER_KEY_OTHER4,
  FILTER_KEY_OTHER5,
  FILTER_KEY_DEVICE,
  FILTER_KEY_CROSS_DEVICE,
  FILTER_KEY_USER_ID,
  FILTER_KEY_CHANNEL_DISPLAY,
  FILTER_KEY_CHANNEL_DISPLAY_RANGE,
  FILTER_KEY_AD_GROUP1,
  FILTER_KEY_AD_GROUP2,
  FILTER_KEY_MEDIA,
  FILTER_KEY_AD_NAME,
  FILTER_KEY_AD_ID,
  FILTER_KEY_AGENCY,
  FILTER_KEY_AD_NOTE,
  FILTER_KEY_LINK_URL,
  FILTER_KEY_SEARCH_WORD,
  FILTER_KEY_LANDING_PAGE_URL,
  FILTER_KEY_CONTACT_HISTORY,
  FILTER_KEY_ADMNG_AD_GROUP1_ID,
  FILTER_KEY_ADMNG_AD_TYPE,
  FILTER_KEY_ADMNG_AD_ID,
  FILTER_KEY_ADMNG_AD_GROUP2_ID,
  FILTER_KEY_ADMNG_AD_NOTE,
  FILTER_KEY_ADMNG_AD_REGISTER_DATE,
  FILTER_KEY_ADMNG_AD_UPDATE_DATE,
  FILTER_KEY_ADMNG_DEVICE,
  FILTER_KEY_ADMNG_KEYWORD,
  FILTER_KEY_ADMNG_MEASUREMENT_TYPE,
  FILTER_KEY_ADMNG_MEDIA_ID,
  FILTER_KEY_ADMNG_TEXT_DATA,
  FILTER_KEY_ADMNG_URL,
  FILTER_KEY_LTV_PRODUCT_NAME,
  FILTER_KEY_LTV_OFFER_NAME,
  GET_REPORT_DEBOUNCE_LENGTH,
  UPDATE_BOOKMARK_DEBOUNCE_LENGTH,
  FileValidationRule,
  COLUMNS_TABLE_CONFIRM_CONTENT_INVALID,
  FILTER_KEY_MEDIA_GROUP_MEDIA_ID,
  FILTER_KEY_MEDIA_GROUP_MEDIA_NAME,
  FILTER_KEY_MEDIA_GROUP_AD_GROUP1_ID,
  FILTER_KEY_MEDIA_GROUP_AD_GROUP1_NAME,
  FILTER_KEY_MEDIA_GROUP_AD_GROUP2_ID,
  FILTER_KEY_MEDIA_GROUP_AD_GROUP2_NAME,
  FILTER_KEY_DIRECTORY,
  FILTER_KEY_PAGE_ID,
  FILTER_KEY_PAGE_TITLE,
  FILTER_KEY_PAGE_URL,
  FILTER_KEY_CV_TITLE,
  FILTER_KEY_CONTENT_CATEGORY,
  FILTER_KEY_CONTENT_CATEGORY_NAME,
  FILTER_KEY_REGISTER_DATE,
  FILTER_KEY_REGISTER_DATE_MIN,
  FILTER_KEY_REGISTER_DATE_MAX,
  FILTER_KEY_UPDATE_DATE,
  FILTER_KEY_UPDATE_DATE_MIN,
  FILTER_KEY_UPDATE_DATE_MAX,
  COLUMNS_TABLE_CONFIRM_CONTENT_INVALID_MERGED,
  FILTER_KEY_MEDIA_SIDE_GROUP,
  FILTER_KEY_MEDIA_SIDE_CAMPAIGN,
  FILTER_KEY_MEDIA_ACCOUNT,
  FILTER_KEY_MEDIA_SIDE_AD_ID,
  FILTER_KEY_MEDIA_SIDE_AD_NAME,
  ONLY_MEDIA_SYNC_AXIS_FILTERS,
  ONLY_EBIS_AXIS_FILTERS,
  FILTER_ITEMS_OWNED_MEDIA_FLAG,
  FILTER_KEY_UNREGISTER,
};
