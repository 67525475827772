import { TAG_MANAGEMENT } from 'services/routes/constants';
import * as FIELDS from 'domain/fields';
import * as LABELS from 'domain/labels';

export const TAG_MANAGEMENT_TAB = {
  CV: 'cv',
  PV: 'pv',
  CONTENT_CATEGORY: 'content-category',
};

export const MODAL_ID = {
  CREATE: 'create',
  UPDATE: 'update',
  VIEW_DETAIL: 'view_detail',
  CONFIRM_DELETE: 'confirm_delete',
  SORT_LIST: 'sort_list',
  ERROR: 'error',
  COMMON_TAG: 'common_tag',
  UPLOAD_CSV: 'upload_csv',
};

export const TABS = [
  {
    key: TAG_MANAGEMENT_TAB.CV,
    label: 'CV計測タグ',
    path: `/${TAG_MANAGEMENT}/${TAG_MANAGEMENT_TAB.CV}`,
  },
  {
    key: TAG_MANAGEMENT_TAB.PV,
    label: 'PV計測タグ',
    path: `/${TAG_MANAGEMENT}/${TAG_MANAGEMENT_TAB.PV}`,
  },
  {
    key: TAG_MANAGEMENT_TAB.CONTENT_CATEGORY,
    label: 'コンテンツカテゴリ',
    path: `/${TAG_MANAGEMENT}/${TAG_MANAGEMENT_TAB.CONTENT_CATEGORY}`,
  },
];

export const DISPLAY_ITEMS = [
  {
    field: 'rowId',
    fieldResponse: FIELDS.PAGE_ID,
    name: '',
    type: 'checkbox',
    isFreeze: true,
    isEdit: true,
    allowTabs: [
      TAG_MANAGEMENT_TAB.CV,
      TAG_MANAGEMENT_TAB.PV,
      TAG_MANAGEMENT_TAB.CONTENT_CATEGORY,
    ],
  },
  {
    field: FIELDS.RANK,
    name: LABELS.RANK,
    allowTabs: [TAG_MANAGEMENT_TAB.CONTENT_CATEGORY],
  },
  {
    field: FIELDS.OWNED_MEDIA_FLAG,
    name: LABELS.OWNED_MEDIA,
    needFormat: true,
    allowTabs: [TAG_MANAGEMENT_TAB.PV],
  },
  {
    field: FIELDS.CONTENT_CATEGORY,
    name: LABELS.CONTENT_CATEGORY,
    type: 'inlineTruncate',
    needFormat: true,
    allowTabs: [TAG_MANAGEMENT_TAB.PV],
  },
  {
    field: FIELDS.CONTENT_CATEGORY_NAME,
    name: LABELS.CONTENT_CATEGORY,
    type: 'inlineTruncate',
    allowTabs: [TAG_MANAGEMENT_TAB.CONTENT_CATEGORY],
  },
  {
    field: FIELDS.PAGE_ID,
    name: LABELS.PAGE_ID,
    isFreeze: true,
    isViewDetail: true,
    variant: 'btn-link',
    type: 'inlineTruncate',
    allowTabs: [TAG_MANAGEMENT_TAB.CV, TAG_MANAGEMENT_TAB.PV],
  },
  {
    field: FIELDS.PAGE_TITLE,
    name: LABELS.PAGE_NAME,
    isFreeze: true,
    type: 'inlineTruncate',
    allowTabs: [TAG_MANAGEMENT_TAB.CV, TAG_MANAGEMENT_TAB.PV],
  },
  {
    field: FIELDS.CV_TITLE,
    name: LABELS.CV_TITLE,
    isFreeze: true,
    type: 'inlineTruncate',
    allowTabs: [TAG_MANAGEMENT_TAB.CV],
  },
  {
    field: 'cv_condition',
    name: 'CV判定条件',
    allowTabs: [TAG_MANAGEMENT_TAB.CV],
    children: [
      {
        field: FIELDS.ROUTE_URLS,
        name: LABELS.ROUTE_URLS,
        type: 'collapseByLine',
        needFormat: true,
        sort: 'none',
      },
      {
        field: FIELDS.CV_URLS,
        name: LABELS.CV_URLS,
        type: 'collapseByLine',
        needFormat: true,
        sort: 'none',
      },
    ],
  },
  {
    field: 'page_condition',
    name: '指定方法',
    allowTabs: [TAG_MANAGEMENT_TAB.CONTENT_CATEGORY],
    children: [
      {
        field: FIELDS.PAGE_URLS,
        name: LABELS.PAGE_URLS,
        type: 'collapseByLine',
        needFormat: true,
        sort: 'none',
      },
      {
        field: FIELDS.PAGE_IDS,
        name: LABELS.PAGE_ID,
        type: 'collapseByHeight',
        needFormat: true,
        sort: 'none',
      },
    ],
  },
  {
    field: FIELDS.REGIST_DATE,
    name: LABELS.REGIST_DATE,
    needFormat: true,
    type: 'inlineNowrap',
    allowTabs: [
      TAG_MANAGEMENT_TAB.CV,
      TAG_MANAGEMENT_TAB.PV,
      TAG_MANAGEMENT_TAB.CONTENT_CATEGORY,
    ],
  },
  {
    field: FIELDS.UPDATE_DATE,
    name: LABELS.UPDATE_DATE,
    needFormat: true,
    type: 'inlineNowrap',
    allowTabs: [
      TAG_MANAGEMENT_TAB.CV,
      TAG_MANAGEMENT_TAB.PV,
      TAG_MANAGEMENT_TAB.CONTENT_CATEGORY,
    ],
  },
];

export const TAGS = {
  COMMON: 'common',
  CUSTOMIZE: 'customize',
  PC: 'pc',
  PC_ID: 'pc_id',
  MOBILE: 'mobile',
  BUTTON: 'button',
  BUTTON_ATTRIBUTE: 'button_attribute',
  READ: 'read',
  ATTRIBUTE_PC: 'attribute_pc',
  ATTRIBUTE_PC_ID: 'attribute_pc_id',
  ATTRIBUTE_BUTTON_ATTRIBUTE: 'attribute_button_attribute',
  GTM_ATTRIBUTE_PC: 'gtm_attribute_pc',
  GTM_ATTRIBUTE_PC_ID: 'gtm_attribute_pc_id',
  GTM_ATTRIBUTE_BUTTON_ATTRIBUTE: 'gtm_attribute_button_attribute',
  GTM_DATALAYER: 'gtm_datalayer',
  ONLY_CT: 'only_ct',
};

export const MODE_OPTIONS_CV = [
  {
    key: 'customize',
    name: 'CVページ用タグ',
    url: 'https://support.ebis.ne.jp/s/article/000004722',
  },
  {
    key: 'pc',
    name: '【テンプレート】コンバージョン属性取得用タグ',
    url: 'https://support.ebis.ne.jp/s/article/000004724',
  },
  {
    key: 'pc_id',
    name: '【テンプレート】コンバージョン属性取得用タグ（ページID指定）',
    url: 'https://support.ebis.ne.jp/s/article/000004724',
  },
  {
    key: 'mobile',
    name: '【テンプレート】ノンタグ計測用ＵＲＬ',
    url: 'https://support.ebis.ne.jp/s/article/000004704',
  },
  {
    key: 'button',
    name: '【テンプレート】ボタンクリックタグ',
    url: 'https://support.ebis.ne.jp/s/article/000004729',
  },
  {
    key: 'button_attribute',
    name: '【テンプレート】ボタンクリックタグ（属性取得）',
    url: 'https://support.ebis.ne.jp/s/article/000004729',
  },
  {
    key: 'read',
    name: '【テンプレート】読了タグ',
    url: 'https://support.ebis.ne.jp/s/article/000004730',
  },
];

export const MODE_OPTIONS_CV_CAPI_SINGLE = MODE_OPTIONS_CV.filter(
  (option) => !['mobile', 'button_attribute', 'read'].includes(option.key)
);

export const MODE_OPTIONS_PV = [
  { key: 'customize', name: 'PVページ用タグ' },
  { key: 'pc', name: '【テンプレート】属性取得用タグ' },
  {
    key: 'pc_id',
    name: '【テンプレート】属性取得用タグ（ページID指定）',
  },
  {
    key: 'mobile',
    name: '【テンプレート】ノンタグ計測用ＵＲＬ',
  },
  {
    key: 'button',
    name: '【テンプレート】ボタンクリックタグ',
  },
  {
    key: 'button_attribute',
    name: '【テンプレート】ボタンクリックタグ（属性取得）',
  },
  {
    key: 'read',
    name: '【テンプレート】読了タグ',
  },
];

export const MODE_FILE_NAME_DOWNLOAD = {
  customize: 'tag_',
  pc: 'unique_tag_',
  pc_id: 'unique_tag_id_',
  mobile: 'socket_url_',
  button: 'button_tag_',
  button_attribute: 'button_tag_attribute_',
  read: 'read_tag_',
};

export const CONDITION_CV = {
  PAGE_PV_URL: 1,
  PAGE_PV_ID: 2,
  BUTTON_CLICK: 3,
  SCROLL: 4,
};

export const CONDITION_LABELS = {
  [CONDITION_CV.PAGE_PV_URL]: '特定ページのPV（URL）指定',
  [CONDITION_CV.PAGE_PV_ID]: '特定ページのPV（ID）指定',
  [CONDITION_CV.BUTTON_CLICK]: 'ボタンクリック',
  [CONDITION_CV.SCROLL]: '読了',
};

export const CONDITION_CV_OPTIONS = [
  {
    key: CONDITION_CV.PAGE_PV_URL,
    name: '特定ページのPV',
  },
  {
    key: CONDITION_CV.BUTTON_CLICK,
    name: 'ボタンクリック',
  },
  {
    key: CONDITION_CV.SCROLL,
    name: '読了',
  },
];

export const TAG_MANAGER_OTHER = 0;
export const TAG_MANAGER_GTM = 1;
export const TAG_MANAGER_LABELS = {
  [TAG_MANAGER_OTHER]: 'その他/利用なし',
  [TAG_MANAGER_GTM]: 'Googleタグマネージャー',
};

export const CV_TAG_STRING_EMPTY =
  'コンバージョン条件が未設定です。ご設定いただくと、条件に沿ったタグが表示されます。';
export const PV_TAG_STRING_EMPTY = '属性取得用タグ（ページID指定）';

export const STEP_PAGE = 1;
export const STEP_CONVERSION = 2;
export const STEP_ATTRIBUTE = 3;
export const STEP_DETAIL_CV = 4;
export const STEP_DETAIL_PV = 2;
export const STEPS_FORM_CV = [
  {
    step: STEP_PAGE,
    label: 'ページ設定',
    active: false,
  },
  {
    step: STEP_CONVERSION,
    label: 'CV設定',
    active: false,
  },
  {
    step: STEP_ATTRIBUTE,
    label: '属性設定',
    active: false,
  },
  {
    step: STEP_DETAIL_CV,
    label: '完了',
    active: false,
  },
];

export const STEPS_FORM_CV_SKIP_ATTRIBUTE = [
  {
    step: STEP_PAGE,
    label: 'ページ設定',
    active: false,
  },
  {
    step: STEP_CONVERSION,
    label: 'CV設定',
    active: false,
  },
  {
    step: STEP_ATTRIBUTE,
    label: '完了',
    active: false,
  },
];

export const STEPS_FORM_PV = [
  {
    step: STEP_PAGE,
    label: 'ページ設定',
    active: false,
  },
  {
    step: STEP_DETAIL_PV,
    label: '完了',
    active: false,
  },
];

export const STEPS_FORM_CONTENT_CATEGORY = [
  {
    step: 1,
    label: 'コンテンツカテゴリ設定',
    active: false,
  },
  {
    step: 2,
    label: '完了',
    active: false,
  },
];

export const MATCH_TYPE_DEFAULT = '0';
export const MATCH_TYPES = [
  {
    key: '0',
    name: '次を含む',
  },
  {
    key: '2',
    name: '次を含まない',
  },
  {
    key: '1',
    name: '次に完全一致',
  },
  {
    key: '3',
    name: '次で始まる',
  },
];

export const OPTIONS_DESIGNATION = [
  {
    label: LABELS.PAGE_URLS,
    value: FIELDS.PAGE_URLS,
    tooltip: LABELS.PAGE_URLS, // todo
  },
  {
    label: LABELS.PAGE_ID,
    value: FIELDS.PAGE_IDS,
    tooltip: LABELS.PAGE_ID, // todo
  },
];

export const TAG_FIELD = {
  HAS_ATTRIBUTE: 'has_attribute',
  HAS_ROUTE: 'has_route',
  PAGE_CONDITION: 'page_condition',
  ATTRIBUTES: 'attributes',
  GTM_ATTRIBUTES: 'gtm_attributes',
  MATCH_TYPE: 'match_type',
  MATCH_URL: 'url',
  MATCH_URL_ERROR: 'url_error',
};

export const ATTRIBUTE_FIELD = {
  [FIELDS.MEMBER_NAME]: {
    label: FIELDS.MEMBER_NAME,
    value: '',
  },
  [FIELDS.AMOUNT]: {
    label: FIELDS.AMOUNT,
    value: '',
  },
  [FIELDS.OTHER1]: {
    label: FIELDS.OTHER1,
    value: '',
  },
  [FIELDS.OTHER2]: {
    label: FIELDS.OTHER2,
    value: '',
  },
  [FIELDS.OTHER3]: {
    label: FIELDS.OTHER3,
    value: '',
  },
  [FIELDS.OTHER4]: {
    label: FIELDS.OTHER4,
    value: '',
  },
  [FIELDS.OTHER5]: {
    label: FIELDS.OTHER5,
    value: '',
  },
};
export const ATTRIBUTE_FIELDS = Object.keys(ATTRIBUTE_FIELD);
export const ATTRIBUTE_VALUES = ATTRIBUTE_FIELDS.reduce((acc, field) => {
  return { ...acc, [field]: ATTRIBUTE_FIELD[field].value };
}, {});
export const ATTRIBUTE_LABELS = ATTRIBUTE_FIELDS.reduce((acc, field) => {
  return { ...acc, [field]: ATTRIBUTE_FIELD[field].label };
}, {});

export const PAGE_ID_MAX = 50;
export const PAGE_TITLE_MAX = 200;
export const PAGE_URL_CV_MAX = 1000;
export const PAGE_URL_PV_MAX = 2000;
export const CV_TITLE_MAX = 200;
export const ATTRIBUTE_MAX = 255;
export const PAGE_IDS_ITEM_MIN = 1;
export const URL_ITEM_MIN = 1;
export const URL_ITEM_MAX = 10;

export const FIELD_FORM_CV = {
  [FIELDS.PAGE_ID]: {
    step: STEP_PAGE,
    label: LABELS.PAGE_ID,
    value: '',
    note: `半角英字、半角数字のみ、${PAGE_ID_MAX}文字以内`,
    tooltip:
      '半角英数で任意のページID を入力してください。\n例）complete_pc, contact',
  },
  [FIELDS.PAGE_TITLE]: {
    step: STEP_PAGE,
    label: LABELS.PAGE_NAME,
    value: '',
    note: `${PAGE_TITLE_MAX}文字以内`,
    tooltip:
      'ページの判別が可能なタイトルを入力してください。\n例）PC購入完了、問合せ',
  },
  [FIELDS.PAGE_URL]: {
    step: STEP_PAGE,
    label: LABELS.PAGE_URL,
    value: '',
    note: '1,000文字以内',
    tooltip:
      'タグを設置するページのURLを入力してください。\n任意項目となり、サイト内分析を利用する場合にディレクトリ分析等に使われます。',
    tooltipCapiSingle: 'タグを設置するページのURLを入力してください。',
  },
  [FIELDS.CV_TITLE]: {
    step: STEP_CONVERSION,
    label: LABELS.CV_TITLE,
    value: undefined, // Default get value from page_title
    note: `${CV_TITLE_MAX}文字以内`,
    tooltip:
      'コンバージョンの名称を入力します。\nこちらの名称が、カテゴリ分析等の各集計画面に表示されます。ページタイトルと同じ名称でも問題ありません。\n例）PC購入完了、問合せ',
    tooltipCapiSingle:
      'コンバージョンの名称を入力します。ページタイトルと同じ名称でも問題ありません。\n例）PC購入完了、問合せ',
  },
  [FIELDS.TAG_MANAGER_TYPE]: {
    step: STEP_CONVERSION,
    label: LABELS.TAG_MANAGER,
    value: TAG_MANAGER_OTHER,
    tooltip:
      'タグ設置の方法をご選択ください。\nGoogleタグマネージャーを選択すると、Googleタグマネージャーの設置方法に合わせた内容が表示されます。',
  },
  [FIELDS.CV_CONDITION]: {
    step: STEP_CONVERSION,
    label: LABELS.CV_CONDITION,
    value: CONDITION_CV.PAGE_PV_URL,
    tooltip:
      'コンバージョン計測するタイミングを「特定ページのPV」「ボタンクリック」「読了」からご選択ください。\n※「ボタンクリック」「読了」はタグマネージャーの利用はできません。「その他タグマネージャー/利用なし」をご選択いただき、ページ直書きにてご設定ください。',
  },
  [TAG_FIELD.PAGE_CONDITION]: {
    step: STEP_CONVERSION,
    label: 'CVページ指定方法',
    value: CONDITION_CV.PAGE_PV_URL,
    tooltip:
      '特定ページのPVをコンバージョン計測する際の、ページ判別方法をご選択ください。\n指定したページURLへのアクセスを計測する「URL指定」と、ページID指定タグを設置したページへのアクセスを計測する「ページID指定」から選択できます。',
  },
  [TAG_FIELD.HAS_ROUTE]: {
    step: STEP_CONVERSION,
    label: '指定する',
    value: false,
  },
  [FIELDS.ROUTE_URLS]: {
    step: STEP_CONVERSION,
    label: 'URL判定条件（経由ページ）',
    value: [],
    tooltip:
      'リロードによる重複計測防止など、特定ページを経由していないコンバージョンを除外する機能です。\nご利用の場合は経由ページのURLをご入力ください。\n例）入力内容確認画面のURLなど',
  },
  [FIELDS.USE_FRAGMENT]: {
    step: STEP_CONVERSION,
    label: 'フラグメントを利用する',
    value: false,
  },
  [FIELDS.CV_URLS]: {
    step: STEP_CONVERSION,
    label: 'URL判定条件（CVページ）',
    value: [],
    tooltip:
      '特定ページのPVをURL指定で判別する際に、コンバージョン計測を行うページのURLを入力してください。',
  },
  [TAG_FIELD.HAS_ATTRIBUTE]: {
    step: STEP_ATTRIBUTE,
    label: '属性取得',
    value: false,
  },
  [TAG_FIELD.ATTRIBUTES]: {
    step: STEP_ATTRIBUTE,
    label: '変数入力',
    value: ATTRIBUTE_VALUES,
  },
  [TAG_FIELD.GTM_ATTRIBUTES]: {
    step: STEP_ATTRIBUTE,
    label: '変数入力',
    value: ATTRIBUTE_VALUES,
  },
};

export const FIELD_REQUEST_CV = {
  [FIELDS.PAGE_ID]: '',
  [FIELDS.PAGE_TITLE]: '',
  [FIELDS.PAGE_URL]: '',
  [FIELDS.CV_TITLE]: '',
  [FIELDS.TAG_MANAGER_TYPE]: TAG_MANAGER_OTHER,
  [FIELDS.CV_CONDITION]: CONDITION_CV.PAGE_PV_URL,
  [FIELDS.USE_FRAGMENT]: false,
  [FIELDS.ROUTE_URLS]: [],
  [FIELDS.CV_URLS]: [],
  [TAG_FIELD.HAS_ATTRIBUTE]: false,
  [TAG_FIELD.ATTRIBUTES]: {},
  [TAG_FIELD.GTM_ATTRIBUTES]: {},
};

export const FIELD_FORM_PV = {
  [FIELDS.PAGE_ID]: {
    label: LABELS.PAGE_ID,
    value: '',
    note: `半角英字、半角数字のみ、${PAGE_ID_MAX}文字以内`,
    tooltip:
      '半角英数で任意のページID を入力してください。\n例）complete_pc, contact',
  },
  [FIELDS.PAGE_TITLE]: {
    label: LABELS.PAGE_NAME,
    value: '',
    note: `${PAGE_TITLE_MAX}文字以内`,
    tooltip:
      'ページの判別が可能なタイトルを入力してください。\n例）PC購入完了、問合せ',
  },
  [FIELDS.PAGE_URL]: {
    label: LABELS.PAGE_URL,
    value: '',
    note: '2,000文字以内',
    tooltip:
      'タグを設置するページのURLを入力してください。\n任意項目となり、サイト内分析を利用する場合にディレクトリ分析等に使われます。',
  },
  [FIELDS.OWNED_MEDIA_FLAG]: {
    label: LABELS.OWNED_MEDIA,
    value: false,
  },
  [FIELDS.CONTENT_CATEGORY_ID]: {
    label: LABELS.CONTENT_CATEGORY,
    value: {},
    tooltip:
      '複数のページIDをグルーピングし、「サイト内」として計測することができます。\n[タグ管理>コンテンツカテゴリ]で登録した、コンテンツカテゴリが選択できます。',
  },
};

export const FIELD_IMPORT_CSV_PV = {
  ...FIELD_FORM_PV,
  [FIELDS.CONTENT_CATEGORY_NAME]: {
    label: LABELS.CONTENT_CATEGORY,
  },
};

export const FIELD_IMPORT_CSV_CONTENT_CATEGORY = {
  [FIELDS.CONTENT_CATEGORY_NAME]: {
    label: LABELS.CONTENT_CATEGORY,
  },
  [FIELDS.RANK]: {
    label: LABELS.RANK,
  },
};

export const FIELD_REQUEST_PV = {
  [FIELDS.PAGE_ID]: '',
  [FIELDS.PAGE_TITLE]: '',
  [FIELDS.PAGE_URL]: '',
  [FIELDS.OWNED_MEDIA_FLAG]: false,
  [FIELDS.CONTENT_CATEGORY_ID]: '',
};

export const CONTENT_CATEGORY_NAME_MAX = 255;
export const URL_LENGTH_MAX = 1000;

export const PV_MAXIMUM_LINE_CSV = 20000;
export const CONTENT_CATEGORY_MAXIMUM_LINE_CSV = 10000;

export const CONTENT_CATEGORY_LIMIT_SORT_LIST = 300;

export const FIELD_FORM_CONTENT_CATEGORY = {
  [FIELDS.CONTENT_CATEGORY_NAME]: 'コンテンツカテゴリ',
  [FIELDS.PAGE_URLS]: 'URL条件',
  [FIELDS.PAGE_IDS]: 'ページID',
};
