import { call, put, takeLatest, fork, takeEvery } from 'redux-saga/effects';
import agencyServiceApi from 'services/api/AgencyManagementApi';
import handleError from 'services/error/handleScopeError';
import { communicationStatus, HttpStatus } from 'services/utils';
import { loggerActions } from 'store/logger';
import commonActions from 'store/common/actions';
import loggerConstants from 'store/logger/constant';
import sharedSagas from 'store/sharedSagas';
import { convertDataDetailResponse } from 'services/agency-management/agencyManagementService';
import { STEP_TWO } from 'domain/agency-management/consts';

import actions from './actions';
import types from './types';

const { BAD_REQUEST } = HttpStatus;
const { LOADING, SUCCEEDED, FAILED } = communicationStatus;

function* errorHandler(err) {
  const errors = err.response
    ? err.response.data.errors
    : [{ message: err.message }];

  yield put(loggerActions.logError(errors, loggerConstants.LOG_LEVEL_ERROR));
}

function* handleGetList() {
  try {
    yield put(actions.setStatusList(LOADING));
    const { data } = yield call(agencyServiceApi.getList);
    yield put(actions.setDataList(data));
  } catch (e) {
    yield fork(errorHandler, e);
    yield put(actions.setStatusList(FAILED));
  }
}

function* handleGetDetail(action) {
  const { id, callback, convert } = action.payload;
  try {
    yield put(actions.setStatusDetail(LOADING));
    const {
      data: { data },
    } = yield call(agencyServiceApi.getDetail, id, convert);
    yield put(actions.setDetail(convertDataDetailResponse(data)));
    yield put(actions.setStatusDetail(SUCCEEDED));
  } catch (e) {
    const { error: response, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];
    yield put(actions.setErrorDetail(errors, scope));
    yield put(actions.setStatusDetail(FAILED));
    callback(true, errors);
  }
}

function* handleValidate(action) {
  const { data, changeStep, callback } = action.payload;
  try {
    yield put(actions.setStatusDetail(LOADING));
    const { data: dataResponse } = yield call(agencyServiceApi.validate, data);
    yield put(actions.setStatusDetail(SUCCEEDED));
    if (dataResponse.errors && dataResponse.errors.length > 0) {
      callback(true, dataResponse.errors);
    } else {
      changeStep(STEP_TWO);
    }
  } catch (e) {
    const { error: response, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];
    yield put(actions.setErrorDetail(errors, scope));
    callback(true, errors);
  }
}

function* handleCreate(action) {
  const { data, callback } = action.payload;
  try {
    yield put(actions.setStatusDetail(LOADING));
    yield call(agencyServiceApi.create, data);
    yield put(actions.setStatusDetail(SUCCEEDED));
    callback();
    yield fork(handleGetList);
  } catch (e) {
    const { error: response, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];
    yield put(actions.setErrorDetail(errors, scope));
    callback(true, errors);
  }
}

function* handleUpdate(action) {
  const { id, data, callback } = action.payload;
  try {
    yield put(actions.setStatusDetail(LOADING));
    yield call(agencyServiceApi.update, id, data);
    yield put(actions.setStatusDetail(SUCCEEDED));
    callback();
    yield fork(handleGetList);
  } catch (e) {
    const { error: response, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];
    yield put(actions.setErrorDetail(errors, scope));
    callback(true, errors);
  }
}

function* handleDelete(action) {
  const { ids, callback } = action.payload;
  try {
    yield call(agencyServiceApi.delete, ids);
    yield put(actions.setStatusList(SUCCEEDED));
    callback();
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];

    yield put(actions.setErrorList(errors, scope));
    callback(true, errors);
  }
}

function* handleGetAgent(action) {
  try {
    yield put(actions.setStatus(action.type, LOADING));
    const { data } = yield call(agencyServiceApi.getAgent);
    yield put(actions.setAgent(data));
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];

    yield put(actions.setErrors(action.type, errors, scope));
  }
}

function* handleSyncAgent(action) {
  const { userIds, callback } = action.payload;
  try {
    yield put(actions.setStatus(action.type, LOADING));
    const { data } = yield call(agencyServiceApi.syncAgent, userIds);
    callback(data);
    yield put(actions.setStatus(action.type, SUCCEEDED));
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];
    callback(e, true);
    yield put(actions.setErrors(action.type, errors, scope));
  }
}

function* handleDownloadCsvAgent(action) {
  try {
    yield put(
      commonActions.setDownloadNotify('CSVファイルを作成しています...')
    );
    yield call(agencyServiceApi.downloadCsvAgent);
    yield put(commonActions.setDownloadNotify(''));
  } catch (e) {
    const { error, scope } = handleError(e?.response || {});
    const errors = error?.data?.errors || [];

    yield put(actions.setErrors(action.type, errors, scope));
  }
}

function* handleDownloadCsvAdAgent(action) {
  const { data } = action.payload;
  try {
    yield put(
      commonActions.setDownloadNotify('CSVファイルを作成しています...')
    );
    yield call(agencyServiceApi.downloadCsvAdAgent, data);
    yield put(commonActions.setDownloadNotify(''));
  } catch (e) {
    const { error, scope } = handleError(e?.response || {});
    const errors = error?.data?.errors || [];

    yield put(actions.setErrors(action.type, errors, scope));
  }
}

export default function* watch() {
  yield takeLatest(
    types.GET_LIST,
    sharedSagas.safe(errorHandler, handleGetList)
  );
  yield takeLatest(
    types.GET_DETAIL,
    sharedSagas.safe(errorHandler, handleGetDetail)
  );
  yield takeEvery(
    types.VALIDATE,
    sharedSagas.safe(errorHandler, handleValidate)
  );
  yield takeEvery(types.CREATE, sharedSagas.safe(errorHandler, handleCreate));
  yield takeEvery(types.UPDATE, sharedSagas.safe(errorHandler, handleUpdate));
  yield takeLatest(types.DELETE, sharedSagas.safe(errorHandler, handleDelete));
  yield takeLatest(
    types.GET_AGENT,
    sharedSagas.safe(errorHandler, handleGetAgent)
  );
  yield takeLatest(
    types.SYNC_AGENT,
    sharedSagas.safe(errorHandler, handleSyncAgent)
  );
  yield takeLatest(
    types.DOWNLOAD_CSV_AGENT,
    sharedSagas.safe(errorHandler, handleDownloadCsvAgent)
  );
  yield takeLatest(
    types.DOWNLOAD_CSV_AD_AGENT,
    sharedSagas.safe(errorHandler, handleDownloadCsvAdAgent)
  );
}
