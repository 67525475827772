import types from 'store/agency-management/types';
import helper from 'store/helper';
import loggerConstant from 'store/logger/constant';

const getList = () => helper.createAction(types.GET_LIST);
const setDataList = (data) =>
  helper.createAction(types.SET_DATA_LIST, { data });
const setErrorList = (errors, scope = loggerConstant.SCOPE_DONT_SHOW) =>
  helper.createAction(types.SET_ERROR_LIST, { errors }, scope);
const setStatusList = (status) =>
  helper.createAction(types.SET_STATUS_LIST, { status });

const getDetail = (id, callback = () => {}, convert = false) =>
  helper.createAction(types.GET_DETAIL, {
    id,
    convert, // false: limit data link media/adgroup, true: get full data link media/adgroup
    callback,
  });
const setDetail = (data) =>
  helper.createAction(types.SET_DATA_DETAIL, { data });
const setErrorDetail = (errors, scope) =>
  helper.createAction(types.SET_ERROR_DETAIL, { errors }, scope);
const setStatusDetail = (status) =>
  helper.createAction(types.SET_STATUS_DETAIL, { status });

const validate = (data, changeStep, callback) =>
  helper.createAction(types.VALIDATE, { data, changeStep, callback });
const create = (data, callback) =>
  helper.createAction(types.CREATE, { data, callback });
const update = (id, data, callback) =>
  helper.createAction(types.UPDATE, { id, data, callback });
const deleteItems = (ids, callback) =>
  helper.createAction(types.DELETE, { ids, callback });

const setStatus = (type, status) =>
  helper.createAction(types.SET_STATUS, { type, status });
const setErrors = (type, errors, scope = null) =>
  helper.createAction(types.SET_ERRORS, { type, errors }, scope);

const getAgent = () => helper.createAction(types.GET_AGENT);
const setAgent = (data) => helper.createAction(types.SET_AGENT, { data });
const syncAgent = (userIds, callback) =>
  helper.createAction(types.SYNC_AGENT, { userIds, callback });
const resetAgent = () => helper.createAction(types.RESET_AGENT);
const downloadCsvAgent = () => helper.createAction(types.DOWNLOAD_CSV_AGENT);
const downloadCsvAdAgent = (data) =>
  helper.createAction(types.DOWNLOAD_CSV_AD_AGENT, { data });

const setStateAgentSyncModal = (data) =>
  helper.createAction(types.SET_STATE_AGENT_SYNC_MODAL, { data });

const resetStateAgentSyncModal = () =>
  helper.createAction(types.RESET_STATE_AGENT_SYNC_MODAL);

const agencyManagementActions = {
  getList,
  setDataList,
  setErrorList,
  setStatusList,
  getDetail,
  setDetail,
  setErrorDetail,
  setStatusDetail,
  validate,
  create,
  update,
  deleteItems,
  setStatus,
  setErrors,
  getAgent,
  setAgent,
  syncAgent,
  resetAgent,
  downloadCsvAgent,
  downloadCsvAdAgent,
  setStateAgentSyncModal,
  resetStateAgentSyncModal
};

export default agencyManagementActions;
