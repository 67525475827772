import React, { useContext, useRef, useEffect } from 'react';
import { bool, string } from 'prop-types';
import {
  GridTableContext,
  GridTableItemContext,
} from 'views/organism/GridTable/GridTableContext';
import {
  GRID_TABLE_ID,
  FULL_WIDTH_COLUMN,
  MIN_WIDTH_COLUMN,
  MAX_WIDTH_COLUMN,
} from './consts';

function GridTableResizeBar(props) {
  const { isResizeGroup, field } = props;
  const {
    settings: { heightHeader },
  } = useContext(GridTableContext);
  const {
    dispatchItem,
    headers,
    gridTemplateColumns,
    gridTableItemRef,
  } = useContext(GridTableItemContext);

  const resizeBarRef = useRef(null);
  const templateColumnsRef = useRef(gridTemplateColumns);

  useEffect(() => {
    if (!resizeBarRef?.current) return () => {};
    const gridTableEl = resizeBarRef.current.closest(`#${GRID_TABLE_ID}`);
    const gridTableItemEl = gridTableItemRef.current;
    const resizeBarEl = resizeBarRef.current;
    const columnEL = resizeBarEl.parentNode;
    const column = headers.find((item) => item.field === field);

    let beingDragged = false;

    const startDrag = () => {
      if (!beingDragged) {
        beingDragged = true;
        gridTableEl.classList.add('grid-table--resizing');
      }
    };

    const stopDrag = () => {
      if (beingDragged) {
        beingDragged = false;
        gridTableEl.classList.remove('grid-table--resizing');
        dispatchItem({
          type: 'setTemplateColumns',
          payload: templateColumnsRef.current,
        });
      }
    };

    const onResizable = (e) =>
      requestAnimationFrame(() => {
        if (!beingDragged) return;

        // get width
        const width = e.clientX - columnEL.getBoundingClientRect().left;
        const minWidth = `${Math.min(
          Math.max(width, parseInt(column.minWidth, 10) || MIN_WIDTH_COLUMN),
          MAX_WIDTH_COLUMN
        )}px`;
        const maxWidth = column.isLastColumn ? FULL_WIDTH_COLUMN : minWidth;

        const actualWidth =
          minWidth === maxWidth ? minWidth : `minmax(${minWidth}, ${maxWidth})`;

        templateColumnsRef.current = {
          ...gridTemplateColumns,
          [field]: `[${field}] ${actualWidth}`,
        };

        const templateColumnsStyle = Object.values(
          templateColumnsRef.current
        ).join(' ');

        // set width
        gridTableItemEl.querySelector(
          '[role="rowheader"]'
        ).style.gridTemplateColumns = templateColumnsStyle;
        gridTableItemEl.querySelector(
          '[role="rowgroup"]'
        ).style.gridTemplateColumns = templateColumnsStyle;
      });

    resizeBarEl.addEventListener('mousedown', startDrag);
    window.addEventListener('mouseup', stopDrag);
    resizeBarEl.addEventListener('mousemove', onResizable);

    return () => {
      resizeBarEl.removeEventListener('mousedown', startDrag);
      window.removeEventListener('mouseup', stopDrag);
      resizeBarEl.removeEventListener('mousemove', onResizable);
    };
  }, [
    dispatchItem,
    headers,
    field,
    resizeBarRef,
    gridTemplateColumns,
    gridTableItemRef,
  ]);

  return (
    <div
      ref={resizeBarRef}
      className="grid-table__column-resize"
      style={{ height: isResizeGroup ? heightHeader : null }}
    />
  );
}

GridTableResizeBar.propTypes = {
  isResizeGroup: bool,
  field: string,
};

GridTableResizeBar.defaultProps = {
  isResizeGroup: false,
  field: '',
};

export default React.memo(GridTableResizeBar);
