import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';
import settingsSelectors from 'store/settings/selectors';
import masterDataSelectors from 'store/master-data/selectors';
import { checkHasContractLog } from 'store/auth/selectors';
import {
  CONTENT_CATEGORY_ID,
  OWNED_MEDIA_FLAG,
  CONTENT_CATEGORY,
} from 'domain/fields';
import { UNREGISTERED_ID_FILTER_KEY } from 'domain/consts';
import {
  TABS,
  DISPLAY_ITEMS,
  TAG_MANAGEMENT_TAB,
} from 'domain/tag-management/consts';
import { formatNumber } from 'domain/utils';
import { SORT_DIRECTION_ASC } from 'domain/settings/display-items';
import { communicationStatus, getErrorMessageByCode } from 'services/utils';
import * as messageError from 'services/validations/messageErrorByCode';

const getTab = (state) => state.cache.tagManagement.settings.tab;

const getList = (state) => state.cache.tagManagement.data.list.items;
const getErrorList = (state) => state.cache.tagManagement.data.list.errors;
const getStatusList = (state) => state.cache.tagManagement.status.list;
const getPagination = (state) => state.cache.tagManagement.settings.pagination;
const getSort = (state) => state.cache.tagManagement.settings.sort;
const getMaxItemsRegist = (state) =>
  state.cache.tagManagement.settings.maxItems;
const getSelectedRows = (state) =>
  state.cache.tagManagement.settings.selectedRows;

const geDataDetail = (state) => state.cache.tagManagement.data.detail.item;
const geStatusDetail = (state) => state.cache.tagManagement.status.detail;
const geErrorsDetail = (state) => state.cache.tagManagement.data.detail.errors;
const getCommonTag = (state) => state.cache.tagManagement.settings.commonTag;

const getCsvFileName = (state) =>
  state.cache.tagManagement.data.upload.fileName;
const geDataContentCategories = (state) =>
  state.cache.tagManagement.settings.contentCategories.data;
const geStatusContentCategories = (state) =>
  state.cache.tagManagement.status.contentCategories;
const geErrorsContentCategories = (state) =>
  state.cache.tagManagement.settings.contentCategories.errors;

const getDataUploadCsv = (state) => state.cache.tagManagement.data.upload;
const geStatusUploadCsv = (state) => state.cache.tagManagement.status.upload;
const geStatusOpenConfirmUpload = (state) =>
  state.cache.tagManagement.settings.openConfirmUpload;

const getTitle = createSelector([getTab], (currentTab) => {
  return TABS.find((tab) => tab.key === currentTab).label || TABS[0].label;
});

const getHeader = createSelector(
  [getTab, getTitle, checkHasContractLog],
  (tab, title, hasContractLog) => {
    return DISPLAY_ITEMS.filter((item) => {
      const hasPermission =
        hasContractLog ||
        ![OWNED_MEDIA_FLAG, CONTENT_CATEGORY].includes(item.field);
      return item.allowTabs.includes(tab) && hasPermission;
    }).map((item) => {
      if (item.field === 'rowId') {
        // Set field response & // Add callback to format data
        if (tab === TAG_MANAGEMENT_TAB.CONTENT_CATEGORY) {
          return {
            ...item,
            fieldResponse: CONTENT_CATEGORY_ID,
            callback: (data) => {
              return {
                ...data,
                isDisabledCheckbox: !isEmpty(data.page_ids),
                tooltipCheckbox: `この${title}には削除の権限がありません`,
              };
            },
          };
        }
      }
      return { ...item };
    });
  }
);

const getSortRequest = createSelector(
  [getHeader, getSort],
  (displayItemms, sort) => {
    const { field, direction } = sort;
    const allowSort = displayItemms.find((item) => item.field === field);
    const mappingKey = { [CONTENT_CATEGORY]: CONTENT_CATEGORY_ID };

    if (allowSort) {
      return `${direction === SORT_DIRECTION_ASC ? '' : '-'}${
        mappingKey[field] ?? field
      }`;
    }

    return undefined;
  }
);

const getStatesUpload = createSelector(
  [
    getDataUploadCsv,
    settingsSelectors.getLoadingByStatus([geStatusUploadCsv]),
    geStatusOpenConfirmUpload,
  ],
  (dataUploadCsv, statusUpload, statusOpenConfirmUpload) => {
    return {
      fileName: dataUploadCsv.fileName,
      list: dataUploadCsv.list,
      statusUpload,
      statusOpenConfirmUpload,
    };
  }
);

const getStates = createSelector(
  [
    settingsSelectors.getLoadingByStatus([getStatusList]),
    getTab,
    getTitle,
    getHeader,
    getList,
    getPagination,
    getSort,
    getMaxItemsRegist,
    geDataDetail,
    geStatusDetail,
    geErrorsDetail,
    getCommonTag,
  ],
  (
    isLoading,
    currentTab,
    currentTitle,
    headers,
    dataList,
    pagination,
    sort,
    maxItems,
    detail,
    statusDetail,
    errorsDetail,
    commonTag
  ) => {
    let notePagination = '';
    if (currentTab !== TAG_MANAGEMENT_TAB.CONTENT_CATEGORY) {
      notePagination = `/登録上限数${formatNumber(maxItems)}件`;
    }

    return {
      isLoading,
      currentTab,
      currentTitle,
      headers,
      dataList,
      sort,
      detail,
      statusDetail: statusDetail === communicationStatus.LOADING,
      errorsDetail,
      pagination: { ...pagination, maxItems, note: notePagination },
      commonTag,
    };
  }
);

const getStatesContentCategories = createSelector(
  [
    geDataContentCategories,
    geStatusContentCategories,
    geErrorsContentCategories,
  ],
  (contentCategories, statusContentCategories, errorsContentCategories) => {
    return {
      contentCategories,
      statusContentCategories:
        statusContentCategories === communicationStatus.LOADING,
      errorsContentCategories,
    };
  }
);

const getContentCategory = createSelector(
  [
    // Initial data
    settingsSelectors.getLoadingByStatus([masterDataSelectors.getStatus]),
    masterDataSelectors.getContentCategory,
    // Search data
    masterDataSelectors.getSearchResult,
    masterDataSelectors.getSearchType,
    // Create data
    geDataContentCategories,
    geStatusContentCategories,
    geErrorsContentCategories,
  ],
  (
    isLoading,
    defaultItems,
    searchItems,
    searchType,
    itemCreated,
    statusCreate,
    errorsCreate
  ) => {
    const actualItems =
      searchType === CONTENT_CATEGORY_ID ? searchItems : { ...defaultItems };
    delete actualItems[+UNREGISTERED_ID_FILTER_KEY]; // Remove item [未登録]
    const items = Object.values(actualItems);

    return {
      isLoading,
      items,
      itemCreated,
      statusCreate,
      errorCreate: getErrorMessageByCode(errorsCreate[0], messageError, {
        label: 'コンテンツカテゴリ',
      }),
    };
  }
);

const tagManagementSelectors = {
  getTab,
  getList,
  getErrorList,
  getStatusList,
  getPagination,
  getSelectedRows,
  getSortRequest,
  getStates,
  getCsvFileName,
  getDataUploadCsv,
  getStatesUpload,
  getStatesContentCategories,
  getContentCategory,
};

export default tagManagementSelectors;
