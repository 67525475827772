import helper from 'store/helper';
import types from './types';

export const initialState = {
  items: {
    /* : {
      channel: true,
      category: false,
      ...
    }, */
  },
  tagManagement: {},
  pageManagement: {},
};

const updateFilters = (state, action) => {
  const { items } = action.payload;
  return {
    ...state,
    items,
  };
};

const updateFiltersTagManagement = (state, action) => {
  const { items } = action.payload;
  return {
    ...state,
    tagManagement: items,
  };
};

const updateFiltersPageManagement = (state, action) => {
  const { items } = action.payload;
  return {
    ...state,
    pageManagement: items,
  };
};

const FilterReducer = helper.createReducer(initialState, {
  [types.UPDATE_FILTERS]: updateFilters,
  [types.UPDATE_FILTERS_TAG_MANAGEMENT]: updateFiltersTagManagement,
  [types.UPDATE_FILTERS_PAGE_MANAGEMENT]: updateFiltersPageManagement,
});

export default FilterReducer;
