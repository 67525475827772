import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import masterDataSelectors from 'store/master-data/selectors';
import settingsSelectors from 'store/settings/selectors';
import { MEDIA_ID, AD_GROUP1_ID, AD_GROUP2_ID } from 'domain/fields';
import {
  formatDataOptions,
  convertDataState,
  prepareAgentSyncHeader,
} from 'services/agency-management/agencyManagementService';
import adManagementGroupSelectors from 'store/ad-management-media/selectors';
import { getErrorMessageByCode } from 'services/utils';
import {
  inputMediaError,
  msgLineError,
} from 'domain/ad-management/media-group/errorMessages';
import { checkHasMediaSyncEdit } from 'store/auth/selectors';
import {
  TYPE_MEDIA_TYPE,
  TYPE_AD_GROUP1,
  TYPE_AD_GROUP2,
} from 'domain/ad-management/media-group/constants';
import { ROLE_CAPI_AGENT_SETTING } from 'domain/permissions/roles';

const getList = (state) => state.cache.agencyManagement.data.list.items;
const getErrorList = (state) => state.cache.agencyManagement.data.list.errors;
const getStatusList = (state) =>
  state.cache.agencyManagement.apiStatus.list.status;
const getTotalRows = (state) =>
  state.cache.agencyManagement.data.list.metadata.count;
const getSyncLastRun = (state) =>
  state.cache.agencyManagement.data.list.metadata.sync_lastrun;
const getSyncStatus = (state) =>
  state.cache.agencyManagement.data.list.metadata.sync_status;

const getAgentData = (state) =>
  state.cache.agencyManagement.data.agentSync.data;
const getAgentMetaData = (state) =>
  state.cache.agencyManagement.data.agentSync.metadata;
const getAgentStatus = (state) =>
  state.cache.agencyManagement.apiStatus.agentSync;

const getStateAgentSyncModal = (state) =>
  state.cache.agencyManagement.stateForAgentSyncModal;

const getHeader = createSelector(
  [masterDataSelectors.getDisplay, checkHasMediaSyncEdit],
  (displayConfig, hasMediaSyncEdit) => {
    const {
      ad_group1: adGroup1 = '広告グループ１',
      ad_group2: adGroup2 = '広告グループ2',
    } = displayConfig;

    const header = [
      {
        field: 'rowId',
        name: '',
        type: 'checkbox',
        isFreeze: true,
        isEdit: true,
      },
      {
        field: 'agency_name',
        name: '会社名',
        isFreeze: true,
      },
      {
        field: 'function',
        name: '機能権限',
        children: [
          {
            field: 'media_name',
            name: '媒体種別',
            type: 'collapseByHeight',
            minWidth: '210px',
            defaultWidth: null,
          },
          {
            field: 'ad_group1_name',
            name: adGroup1,
            type: 'collapseByHeight',
            minWidth: '160px',
            defaultWidth: null,
          },
          {
            field: 'ad_group2_name',
            name: adGroup2,
            type: 'collapseByHeight',
            minWidth: '160px',
            defaultWidth: null,
          },
          {
            field: 'view',
            name: '閲覧',
            children: [
              {
                field: 'view_all',
                name: '全トラフィック',
                variant: 'icon-check',
                width: '108px',
              },
              {
                field: 'view_ad',
                name: '広告',
                variant: 'icon-check',
                width: '53px',
              },
            ],
          },
          {
            field: 'agent_setting',
            name: '編集',
            variant: 'icon-check',
            width: '53px',
          },
        ],
      },
    ];
    const indexFieldFunction = header.findIndex(
      (item) => item.field === 'function'
    );
    if (hasMediaSyncEdit) {
      header[indexFieldFunction].children.push({
        field: 'media_sync_setting',
        name: '媒体シンク設定',
        variant: 'icon-check',
        width: '108px',
      });
    }
    return header;
  }
);

const getStatusDetail = (state) =>
  state.cache.agencyManagement.apiStatus.detail.status;

const getDetail = (state) => state.cache.agencyManagement.data.detail.item;

const getErrorDetail = (state) =>
  state.cache.agencyManagement.data.detail.errors;

const getMedia = createSelector(
  [
    masterDataSelectors.getMedia,
    adManagementGroupSelectors.getListByType(TYPE_MEDIA_TYPE),
    adManagementGroupSelectors.getStatusDetail,
    adManagementGroupSelectors.getErrorDetail,
  ],
  (list, items, status, errors) => ({
    status,
    list: formatDataOptions(list),
    error: errors[0] || {},
    items: convertDataState(isEmpty(items) ? [] : items, 'name', 'id'),
  })
);

const getAdGroup1 = createSelector(
  [
    masterDataSelectors.getAdGroup1,
    adManagementGroupSelectors.getListByType(TYPE_AD_GROUP1),
    adManagementGroupSelectors.getStatusDetail,
    adManagementGroupSelectors.getErrorDetail,
  ],
  (list, items, status, errors) => ({
    status,
    list: formatDataOptions(list),
    error: errors[0] || {},
    items: convertDataState(isEmpty(items) ? [] : items, 'name', 'id'),
  })
);

const getAdGroup2 = createSelector(
  [
    masterDataSelectors.getAdGroup2,
    adManagementGroupSelectors.getListByType(TYPE_AD_GROUP2),
    adManagementGroupSelectors.getStatusDetail,
    adManagementGroupSelectors.getErrorDetail,
  ],
  (list, items, status, errors) => ({
    status,
    list: formatDataOptions(list),
    error: errors[0] || {},
    items: convertDataState(isEmpty(items) ? [] : items, 'name', 'id'),
  })
);

const getMasterdata = createSelector(
  [
    getMedia,
    getAdGroup1,
    getAdGroup2,
    masterDataSelectors.getDisplay,
    masterDataSelectors.getStatus,
    masterDataSelectors.getSearchResult,
    masterDataSelectors.getSearchType,
  ],
  (media, adGroup1, adGroup2, display, status, searchResult, searchType) => {
    const searchItems = formatDataOptions(searchResult);
    const message = { ...inputMediaError, ...msgLineError };

    return {
      isLoading: status === 'loading',
      displayConfig: display,
      media: {
        ...media,
        list: searchType === MEDIA_ID ? searchItems : media.list,
        error: getErrorMessageByCode(media.error, message, {
          label: '媒体種別',
        }),
      },
      adGroup1: {
        ...adGroup1,
        list: searchType === AD_GROUP1_ID ? searchItems : adGroup1.list,
        error: getErrorMessageByCode(adGroup1.error, message, {
          label: display.ad_group1,
        }),
      },
      adGroup2: {
        ...adGroup2,
        list: searchType === AD_GROUP2_ID ? searchItems : adGroup2.list,
        error: getErrorMessageByCode(adGroup2.error, message, {
          label: display.ad_group1,
        }),
      },
    };
  }
);

const getStateAgentSync = createSelector(
  [
    settingsSelectors.getLoadingByStatus([getAgentStatus]),
    masterDataSelectors.getDisplay,
    getAgentData,
    getAgentMetaData,
  ],
  (isLoading, displayConfig, agentSyncData, agentSyncMetaData) => {
    return {
      isLoading,
      displayConfig,
      agentSyncData,
      agentSyncMetaData,
      agentSyncHeader: prepareAgentSyncHeader(displayConfig),
    };
  }
);

const agencyManagementSelectors = {
  getList,
  getErrorList,
  getStatusList,
  getStatusDetail,
  getErrorDetail,
  getDetail,
  getTotalRows,
  getSyncLastRun,
  getSyncStatus,
  getHeader,
  getMasterdata,
  getStateAgentSync,
  getStateAgentSyncModal,
};

export default agencyManagementSelectors;
