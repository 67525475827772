import React from 'react';
import pages from 'services/routes/pages';
import {
  DASHBOARD,
  CATEGORY_ANALYZE,
  DETAILS_ANALYSIS,
  COMPARE_PERIOD,
  CV_ATTRIBUTE,
  CV_FLOW,
  COST_ALLOCATION,
  USER_MANAGEMENT,
  USER_MANAGEMENT_DRAGONBALL_LINK,
  AGENCY_MANAGEMENT,
  TAG_MANAGEMENT,
  DASHBOARD_DRAGONBALL_LINK,
  CATEGORY_ANALYZE_DRAGONBALL_LINK,
  DETAILS_ANALYSIS_DRAGONBALL_LINK,
  COMPARE_PERIOD_DRAGONBALL_LINK,
  CV_ATTRIBUTE_DRAGONBALL_LINK,
  CV_FLOW_DRAGONBALL_LINK,
  COST_ALLOCATION_DRAGONBALL_LINK,
  AD_MANAGEMENT,
  AD_MANAGEMENT_MEDIA_TYPE,
  AD_MANAGEMENT_AD_GROUP_1,
  AD_MANAGEMENT_AD_GROUP_2,
  LTV_SETTINGS_PRODUCT,
  LTV_SETTINGS_OFFER,
  LTV_SETTINGS_CONDITION,
  LTV_SETTINGS_AD,
  LTV_SETTINGS_OTHER,
  LTV_SETTINGS_CLEAR_CSV_ORDERS,
  LTV_ANALYZE,
  APP_HELP_AD_MANAGEMENT,
  APP_HELP_AD_CATEGORY,
  APP_HELP_AGENCY_MANAGEMENT_PAGE,
  APP_HELP_TAG_MANAGEMENT_PAGE,
  LANDING_PAGE_ANALYZE,
  LANDING_PAGE_ANALYZE_DRAGONBALL_LINK,
  EXTERNAL_SERVICE_SETTINGS,
  USER_PROFILE,
  USER_PROFILE_DRAGONBALL_LINK,
  MEDIA_SYNC_MANAGEMENT,
  MEDIA_SYNC_MANAGEMENT_DRAGONBALL_LINK,
  DATA_EXPORT_DRAGONBALL_LINK,
  APP_HELP_API_MANAGEMENT_PAGE,
  DATA_EXPORT,
  USAGE_STATUS_SITE,
  API_MANAGEMENT,
  CONTACT_DEMAND_MANAGEMENT,
  CONTACT_MANAGEMENT,
  SYSTEM_SETTING,
  APP_HELP_SYSTEM_SETTING,
  MEASUREMENT_SITE_PAGE_MANAGEMENT,
  IMPRESSION_AD_COST,
  APP_HELP_IMPRESSION_AD_COST_PAGE,
  LPO_LINK,
  LPO_PERIOD,
  LPO_LINK_DRAGONBALL_LINK,
  LPO_PERIOD_DRAGONBALL_LINK,
  LOG_PAGE_ANALYZE,
  LOG_PERIOD_ANALYZE,
  LOG_ROUTE_ANALYZE,
  APP_HELP_LOG_PAGE_ANALYZE,
  APP_HELP_LOG_PERIOD_ANALYZE,
  APP_HELP_LOG_ROUTE_ANALYZE,
  CAPI_SETTING,
  SEARCH_CONSOLE_SETTING,
  APP_HELP_CAPI_SETTING,
  CSV_SETTING,
  INFLOW_ANALYSIS,
  APP_HELP_SC_SETTING,
  LINE_ADD_FRIEND_SETTINGS,
  APP_HELP_LINE_ADD_FRIEND,
} from 'services/routes/constants';

import DisplayItemsActions from 'store/display-items/actions';
import DisplayItemsSelectors from 'store/display-items/selectors';
import DashboardActions from 'store/dashboard/actions';
import DashboardSelectors from 'store/dashboard/selectors';
import LtvActions from 'store/ltv/analyze/actions';
import LtvSelectors from 'store/ltv/analyze/selectors';
import ReflectionTimeActions from 'store/reflectiontime/actions';
import ReflectionTimeSelectors from 'store/reflectiontime/selectors';
import LtvDummyMainContent from 'views/pages/dummy-main-content/ltv/LtvDummyMainContent';
import LtvDisplayItemApi from 'services/api/ltv/LtvDisplayItemApi';
import DisplayItemsApi from 'services/api/DisplayItemsApi';
import LtvAnalyzeApi from 'services/api/LtvAnalyzeApi';
import Api from 'services/api/Api';

import LtvPermissionFilterService from 'services/ltv/analyze/LtvPermissionFilterService';

const ScreenTitle = {
  default: {
    title: '',
    breadcrumbs: [],
    dragonBall: '',
    btnCalendar: true,
    btnDisplayItems: true,
    btnFilters: true,
    btnViewSettings: true,
    btnDownload: true,
    btnMonthDropDwon: false,
    dummyMainContentDisplay: false,
    displayConditions: false,
    showZeros: false,
    newIconDisplay: false,
    ltvStatus: false,
    displayItemsActions: DisplayItemsActions,
    displayItemsSelectors: DisplayItemsSelectors,
    periodActions: DashboardActions,
    periodSelectors: DashboardSelectors,
    reflectionTimeActions: ReflectionTimeActions,
    reflectionTimeSelectors: ReflectionTimeSelectors,
    dummyMainContent: '',
    displayitemsApi: DisplayItemsApi,
    downLoadApi: Api,
    isMenuSetting: false,
    permissionFilter: null,
    releaseDate: '',
    toolTipMasseges: '',
  },
  [DASHBOARD]: {
    btnFilters: false,
    title: pages[DASHBOARD].key,
    dragonBall: (
      <>
        1ヶ月間（当日を含まない）の全体成果の現状把握ができます。詳しくは
        <a
          href={DASHBOARD_DRAGONBALL_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [CATEGORY_ANALYZE]: {
    title: pages[CATEGORY_ANALYZE].key,
    dragonBall: (
      <>
        計測データを「媒体種別、広告グループ」単位で確認でき、各カテゴリごとの評価ができます。詳しくは
        <a
          href={CATEGORY_ANALYZE_DRAGONBALL_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [DETAILS_ANALYSIS]: {
    title: pages[DETAILS_ANALYSIS].key,
    dragonBall: (
      <>
        計測データを、最も細かい粒度で確認することができ、より詳細な施策評価ができます。詳しくは
        <a
          href={DETAILS_ANALYSIS_DRAGONBALL_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [LANDING_PAGE_ANALYZE]: {
    title: pages[LANDING_PAGE_ANALYZE].key,
    dragonBall: (
      <>
        ランディングページを「ドメイン/URL/コンテンツカテゴリ」単位で確認でき、ランディングページごとの評価ができます。詳しくは
        <a
          href={LANDING_PAGE_ANALYZE_DRAGONBALL_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [COMPARE_PERIOD]: {
    title: pages[COMPARE_PERIOD].key,
    dragonBall: (
      <>
        計測データを、日別・月別・曜日別・時間帯別で確認することができ、時間軸の傾向分析ができます。詳しくは
        <a
          href={COMPARE_PERIOD_DRAGONBALL_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [CV_ATTRIBUTE]: {
    title: pages[CV_ATTRIBUTE].key,
    dragonBall: (
      <>
        コンバージョンに至るまでに接触した広告（チャネル）や属性情報を確認することができます。詳しくは
        <a
          href={CV_ATTRIBUTE_DRAGONBALL_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
    releaseDate: '2021-12-18 00:00:00',
  },
  [CV_FLOW]: {
    title: pages[CV_FLOW].key,
    dragonBall: (
      <>
        コンバージョンに至るまでに接触した広告（チャネル）の傾向分析をすることができます。詳しくは
        <a
          href={CV_FLOW_DRAGONBALL_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [COST_ALLOCATION]: {
    title: pages[COST_ALLOCATION].key,
    dragonBall: (
      <>
        広告成果とコストから生産性をスコアリングし、抑制可能なコストを算出できます。詳しくは
        <a
          href={COST_ALLOCATION_DRAGONBALL_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [USER_MANAGEMENT]: {
    title: pages[USER_MANAGEMENT].key,
    dragonBall: (
      <>
        管理画面で操作、閲覧できるユーザーの追加・編集・削除、および、ユーザーごとに権限を設定することができます。詳しくは
        <a
          href={USER_MANAGEMENT_DRAGONBALL_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [AGENCY_MANAGEMENT]: {
    title: pages[AGENCY_MANAGEMENT].key,
    dragonBall: (
      <>
        エージェント権限ユーザーを利用する際に設定する代理店の追加や編集ができます。詳しくは
        <a
          href={APP_HELP_AGENCY_MANAGEMENT_PAGE}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
    isMenuSetting: true,
    btnCalendar: false,
    btnPeriodDropDown: false,
    btnDisplayItems: false,
    btnFilters: false,
    btnViewSettings: false,
    btnDownload: false,
  },
  [TAG_MANAGEMENT]: {
    title: pages[TAG_MANAGEMENT].key,
    dragonBall: (
      <>
        共通タグの発行、および計測タグやコンテンツカテゴリの登録、編集をすることができます。詳しくは
        <a
          href={APP_HELP_TAG_MANAGEMENT_PAGE}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
    isMenuSetting: true,
    btnCalendar: false,
    btnPeriodDropDown: false,
    btnDisplayItems: false,
    btnFilters: true,
    btnViewSettings: false,
    btnDownload: true,
  },
  [MEASUREMENT_SITE_PAGE_MANAGEMENT]: {
    title: pages[MEASUREMENT_SITE_PAGE_MANAGEMENT].key,
    dragonBall: (
      <>
        共通タグによって自動生成されたPVページの一覧です。ページタイトルなどを編集することができます。
      </>
    ),
    isMenuSetting: true,
    btnCalendar: false,
    btnPeriodDropDown: false,
    btnDisplayItems: false,
    btnFilters: true,
    btnViewSettings: false,
    btnDownload: true,
  },
  [LINE_ADD_FRIEND_SETTINGS]: {
    title: pages[LINE_ADD_FRIEND_SETTINGS].key,
    dragonBall: (
      <>
        LINE友だち追加をコンバージョンとして計測するための設定を行う画面です。詳しくは
        <a
          href={APP_HELP_LINE_ADD_FRIEND}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
      </>
    ),
    isMenuSetting: true,
    btnCalendar: false,
    btnPeriodDropDown: false,
    btnDisplayItems: false,
    btnFilters: false,
    btnViewSettings: false,
    btnDownload: false,
  },
  [AD_MANAGEMENT]: {
    title: pages[AD_MANAGEMENT].key,
    dragonBall: (
      <>
        広告の登録や編集、および計測に必要な広告パラメーターを発行することができます。詳しくは
        <a
          href={APP_HELP_AD_MANAGEMENT}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [LTV_SETTINGS_PRODUCT]: {
    title: '分析設定',
    breadcrumbs: [{ text: 'LTVForecast', url: '/ltv-forecast' }],
    dragonBall: (
      <>
        LTV
        Forecastで分析をするための、商品やオファーの登録、広告への紐づけ設定などができます。詳しくは
        <br />
        <a
          href="https://support.ebis.ne.jp/s/article/000004690"
          title="分析設定"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
    btnCalendar: false,
    btnDisplayItems: false,
    btnFilters: {
      className: 'filter--setting',
    },
    btnViewSettings: false,
    btnDownload: {
      className: 'download--setting',
    },
    dummyMainContentDisplay: true,
    dummyMainContent: <LtvDummyMainContent />,
    permissionFilter: LtvPermissionFilterService(),
  },
  [LTV_SETTINGS_OFFER]: {
    title: '分析設定',
    breadcrumbs: [{ text: 'LTVForecast', url: '/ltv-forecast' }],
    dragonBall: (
      <>
        LTV
        Forecastで分析をするための、商品やオファーの登録、広告への紐づけ設定などができます。詳しくは
        <br />
        <a
          href="https://support.ebis.ne.jp/s/article/000004690"
          title="分析設定"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
    btnCalendar: false,
    btnDisplayItems: false,
    btnFilters: {
      className: 'filter--setting',
    },
    btnViewSettings: false,
    btnDownload: {
      className: 'download--setting',
    },
    dummyMainContentDisplay: true,
    dummyMainContent: <LtvDummyMainContent />,
    permissionFilter: LtvPermissionFilterService(),
  },
  [LTV_SETTINGS_CONDITION]: {
    title: '分析設定',
    breadcrumbs: [{ text: 'LTVForecast', url: '/ltv-forecast' }],
    dragonBall: (
      <>
        LTV
        Forecastで分析をするための、商品やオファーの登録、広告への紐づけ設定などができます。詳しくは
        <br />
        <a
          href="https://support.ebis.ne.jp/s/article/000004690"
          title="分析設定"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
    btnCalendar: false,
    btnDisplayItems: false,
    btnFilters: {
      className: 'filter--setting',
    },
    btnViewSettings: false,
    btnDownload: {
      className: 'download--setting',
    },
    dummyMainContentDisplay: true,
    dummyMainContent: <LtvDummyMainContent />,
    permissionFilter: LtvPermissionFilterService(),
  },
  [LTV_SETTINGS_AD]: {
    title: '分析設定',
    breadcrumbs: [{ text: 'LTVForecast', url: '/ltv-forecast' }],
    dragonBall: (
      <>
        LTV
        Forecastで分析をするための、商品やオファーの登録、広告への紐づけ設定などができます。詳しくは
        <br />
        <a
          href="https://support.ebis.ne.jp/s/article/000004690"
          title="分析設定"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
    btnCalendar: false,
    btnDisplayItems: false,
    btnFilters: {
      className: 'filter--setting',
    },
    btnViewSettings: false,
    btnDownload: {
      className: 'download--setting',
    },
    dummyMainContentDisplay: true,
    dummyMainContent: <LtvDummyMainContent />,
    permissionFilter: LtvPermissionFilterService(),
  },
  [LTV_SETTINGS_OTHER]: {
    title: '分析設定',
    breadcrumbs: [{ text: 'LTVForecast', url: '/ltv-forecast' }],
    dragonBall: (
      <>
        LTV
        Forecastで分析をするための、商品やオファーの登録、広告への紐づけ設定などができます。詳しくは
        <br />
        <a
          href="https://support.ebis.ne.jp/s/article/000004690"
          title="分析設定"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
    btnFilters: false,
    btnDownload: false,
    dummyMainContentDisplay: true,
    dummyMainContent: <LtvDummyMainContent />,
    permissionFilter: LtvPermissionFilterService(),
  },
  [LTV_SETTINGS_CLEAR_CSV_ORDERS]: {
    title: '分析設定',
    breadcrumbs: [{ text: 'LTVForecast', url: '/ltv-forecast' }],
    dragonBall: (
      <>
        LTV
        Forecastで分析をするための、商品やオファーの登録、広告への紐づけ設定などができます。詳しくは
        <br />
        <a
          href="https://support.ebis.ne.jp/s/article/000004690"
          title="分析設定"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
    btnFilters: false,
    btnDownload: false,
    dummyMainContentDisplay: true,
    dummyMainContent: <LtvDummyMainContent />,
    permissionFilter: LtvPermissionFilterService(),
  },
  [LTV_ANALYZE]: {
    title: pages[LTV_ANALYZE].key,
    dragonBall: (
      <>
        収益性の高い広告を「商品×オファー×媒体」の単位で確認・評価ができます。詳しくは
        <a
          href="https://support.ebis.ne.jp/s/article/000004690"
          title="分析設定"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
    btnFilters: {
      className: 'filter--setting',
    },
    btnViewSettings: false,
    btnDownload: {
      className: 'download--setting',
    },
    btnCalendar: false,
    btnPeriodDropDown: true,
    displayConditions: true,
    dummyMainContentDisplay: true,
    showZeros: true,
    newIconDisplay: true,
    ltvStatus: true,
    displayItemsActions: LtvActions,
    displayItemsSelectors: LtvSelectors,
    periodActions: LtvActions,
    periodSelectors: LtvSelectors,
    reflectionTimeActions: LtvActions,
    reflectionTimeSelectors: LtvSelectors,
    dummyMainContent: <LtvDummyMainContent />,
    displayitemsApi: LtvDisplayItemApi,
    downLoadApi: LtvAnalyzeApi,
    permissionFilter: LtvPermissionFilterService(),
  },
  [AD_MANAGEMENT_MEDIA_TYPE]: {
    title: pages[AD_MANAGEMENT_MEDIA_TYPE].key,
    dragonBall: (
      <>
        媒体種別・広告グループ1・広告グループ2の登録や編集をすることができます。詳しくは
        <a
          href={APP_HELP_AD_CATEGORY}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [AD_MANAGEMENT_AD_GROUP_1]: {
    title: pages[AD_MANAGEMENT_AD_GROUP_1].key,
    dragonBall: (
      <>
        媒体種別・広告グループ1・広告グループ2の登録や編集をすることができます。詳しくは
        <a
          href={APP_HELP_AD_CATEGORY}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [AD_MANAGEMENT_AD_GROUP_2]: {
    title: pages[AD_MANAGEMENT_AD_GROUP_2].key,
    dragonBall: (
      <>
        媒体種別・広告グループ1・広告グループ2の登録や編集をすることができます。詳しくは
        <a
          href={APP_HELP_AD_CATEGORY}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [SYSTEM_SETTING]: {
    title: pages[SYSTEM_SETTING].key,
    isMenuSetting: true,
    btnDisplayItems: false,
    btnFilters: false,
    btnViewSettings: false,
    btnCalendar: false,
    btnDownload: false,
    dragonBall: (
      <>
        計測対象サイトの登録やページ設定といった、当サービスの計測や表示に関する項目の設定をすることができます。詳しくは
        <a
          href={APP_HELP_SYSTEM_SETTING}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [EXTERNAL_SERVICE_SETTINGS]: {
    title: pages[EXTERNAL_SERVICE_SETTINGS].key,
    dragonBall: <></>,
  },
  [USAGE_STATUS_SITE]: {
    title: pages[USAGE_STATUS_SITE].key,
  },
  [USER_PROFILE]: {
    title: 'ユーザープロファイル',
    breadcrumbs: [{ text: 'コンバージョン属性', url: '/cv-attribute' }],
    btnDisplayItems: false,
    btnFilters: false,
    btnViewSettings: false,
    disabledCalendar: true,
    messageWhenDisabledCalendar:
      'ユーザープロファイルは直近3ヶ月の期間しか閲覧できません',
    dragonBall: (
      <>
        ユーザーごとの過去の接触履歴を、CVが発生したタイミングより遡って確認することができます。詳しくは
        <a
          href={USER_PROFILE_DRAGONBALL_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [MEDIA_SYNC_MANAGEMENT]: {
    title: pages[MEDIA_SYNC_MANAGEMENT].key,
    dragonBall: (
      <>
        媒体シンク機能に関連する設定や、入稿用のパラメータが発行できる画面です。詳しくは
        <a
          href={MEDIA_SYNC_MANAGEMENT_DRAGONBALL_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [DATA_EXPORT]: {
    title: pages[DATA_EXPORT].key,
    dragonBall: (
      <>
        集計軸や検索条件を選択し、柔軟な設定でレポートの作成ができます。詳しくは
        <a
          href={DATA_EXPORT_DRAGONBALL_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
    isMenuSetting: true,
    btnCalendar: false,
    btnPeriodDropDown: false,
    btnDisplayItems: false,
    btnFilters: false,
    btnViewSettings: false,
    btnDownload: false,
  },
  [API_MANAGEMENT]: {
    title: pages[API_MANAGEMENT].key,
    dragonBall: (
      <>
        APIのリフレッシュトークンを発行・再発行することができます。詳しくは
        <a
          href={APP_HELP_API_MANAGEMENT_PAGE}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ), // TODO change content
    isMenuSetting: true,
    btnCalendar: false,
    btnPeriodDropDown: false,
    btnDisplayItems: false,
    btnFilters: false,
    btnViewSettings: false,
    btnDownload: false,
  },
  [CONTACT_DEMAND_MANAGEMENT]: {
    title: pages[CONTACT_DEMAND_MANAGEMENT].key,
    dragonBall: '',
    isMenuSetting: true,
    btnCalendar: false,
    btnPeriodDropDown: false,
    btnDisplayItems: false,
    btnFilters: false,
    btnViewSettings: false,
    btnDownload: false,
  },
  [CONTACT_MANAGEMENT]: {
    title: pages[CONTACT_MANAGEMENT].key,
    dragonBall: '',
    isMenuSetting: true,
    btnCalendar: false,
    btnPeriodDropDown: false,
    btnDisplayItems: false,
    btnFilters: false,
    btnViewSettings: false,
    btnDownload: false,
  },
  [IMPRESSION_AD_COST]: {
    title: pages[IMPRESSION_AD_COST].key,
    dragonBall: (
      <>
        広告の表示回数・広告コストをCSVファイルで登録できます。広告ID（入稿用URL）と広告カテゴリごとの登録に対応しています。詳しくは
        <a
          href={APP_HELP_IMPRESSION_AD_COST_PAGE}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [LOG_PAGE_ANALYZE]: {
    title: pages[LOG_PAGE_ANALYZE].key,
    breadcrumbs: [{ text: 'サイト内分析' }],
    dragonBall: (
      <>
        サイトへのアクセスデータを「ディレクトリ、ページ」単位で確認でき、各ページの評価ができます。詳しくは
        <a
          href={APP_HELP_LOG_PAGE_ANALYZE}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [LOG_PERIOD_ANALYZE]: {
    title: pages[LOG_PERIOD_ANALYZE].key,
    breadcrumbs: [{ text: 'サイト内分析' }],
    dragonBall: (
      <>
        サイトへのアクセスデータを、日別・月別・曜日別・時間帯別で確認することができ、時間軸の傾向分析ができます。詳しくは
        <a
          href={APP_HELP_LOG_PERIOD_ANALYZE}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [LOG_ROUTE_ANALYZE]: {
    title: pages[LOG_ROUTE_ANALYZE].key,
    breadcrumbs: [{ text: 'サイト内分析' }],
    btnDisplayItems: false,
    btnFilters: false,
    btnViewSettings: false,
    dragonBall: (
      <>
        ユーザーのサイト内の行動を可視化し、サイト内の傾向分析ができます。詳しくは
        <a
          href={APP_HELP_LOG_ROUTE_ANALYZE}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [CAPI_SETTING]: {
    title: pages[CAPI_SETTING].key,
    isMenuSetting: true,
    btnDisplayItems: false,
    btnFilters: false,
    btnCalendar: false,
    btnPeriodDropDown: false,
    btnDownload: false,
    btnViewSettings: false,
    dragonBall: (
      <>
        アドエビスで計測した正確なCVを各媒体に送信することで、CVデータの精度が高まります。詳しくは
        <a
          href={APP_HELP_CAPI_SETTING}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
  },
  [SEARCH_CONSOLE_SETTING]: {
    title: pages[SEARCH_CONSOLE_SETTING].key,
    isMenuSetting: true,
    btnDisplayItems: false,
    btnFilters: false,
    btnCalendar: false,
    btnPeriodDropDown: false,
    btnDownload: false,
    btnViewSettings: false,
    dragonBall: (
      <>
        Google Search Console連携に関する認証設定を行う画面です。
        <a href={APP_HELP_SC_SETTING} target="_blank" rel="noopener noreferrer">
          詳しくはこちら
        </a>
      </>
    ),
  },
  [LPO_LINK]: {
    title: pages[LPO_LINK].key,
    dragonBall: (
      <>
        LPOは、広告のリンク先ページを複数用意してABテストができる機能です。LPO広告の計測データをリンク先ページごとに確認できます。詳しくは
        <a
          href={LPO_LINK_DRAGONBALL_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
    dragonBallToolTipPlacement: 'bottom',
    toolTipMasseges: '削除済みの広告のため詳細を開くことはできません。',
  },
  [LPO_PERIOD]: {
    title: pages[LPO_PERIOD].key,
    dragonBall: (
      <>
        LPOは、広告のリンク先ページを複数用意してABテストができる機能です。LPO広告の計測データを、日別・月別・曜日別・時間帯別に確認できます。詳しくは
        <a
          href={LPO_PERIOD_DRAGONBALL_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        。
      </>
    ),
    dragonBallToolTipPlacement: 'bottom',
  },
  [CSV_SETTING]: {
    title: pages[CSV_SETTING].key,
  },
  [INFLOW_ANALYSIS]: {
    title: pages[INFLOW_ANALYSIS].key,
  },
};

const handler = {
  get(target, name) {
    return Object.prototype.hasOwnProperty.call(target, name)
      ? target[name]
      : ScreenTitle.default;
  },
};

const screenTitleConfigs = new Proxy(ScreenTitle, handler);

export default screenTitleConfigs;
