import types from 'store/tag-management/types';
import helper from 'store/helper';
import loggerConstant from 'store/logger/constant';

const getList = () => helper.createAction(types.GET_LIST);
const setDataList = (
  items,
  totalItems,
  maxItems,
  changedToLastPage,
  commonTag
) =>
  helper.createAction(types.SET_DATA_LIST, {
    items,
    totalItems,
    maxItems,
    changedToLastPage,
    commonTag,
  });
const setErrorList = (errors, scope = loggerConstant.SCOPE_DONT_SHOW) =>
  helper.createAction(types.SET_ERROR_LIST, { errors }, scope);
const setStatusList = (status) =>
  helper.createAction(types.SET_STATUS_LIST, { status });

const setStatusDetail = (status) =>
  helper.createAction(types.SET_STATUS_DETAIL, { status });
const getDetail = (id, callback = () => {}) =>
  helper.createAction(types.GET_DETAIL, {
    id,
    callback,
  });
const setDataDetail = (data) =>
  helper.createAction(types.SET_DATA_DETAIL, { data });
const setErrorDetail = (errors, scope) =>
  helper.createAction(types.SET_ERROR_DETAIL, { errors }, scope);

const changeTab = (tab) => helper.createAction(types.CHANGE_TAB, { tab });
const updateSort = (field, direction) =>
  helper.createAction(types.UPDATE_SORT, { field, direction });
const updatePage = (page) => helper.createAction(types.UPDATE_PAGE, { page });
const downloadCsv = (type) => helper.createAction(types.DOWNLOAD_CSV, { type });
const selectRows = (rows) => helper.createAction(types.SELECT_ROWS, { rows });

const create = (data, callback = () => {}) =>
  helper.createAction(types.CREATE, { data, callback });
const update = (id, data, callback = () => {}) =>
  helper.createAction(types.UPDATE, { id, data, callback });
const deleteItems = (ids, callback) =>
  helper.createAction(types.DELETE, { ids, callback });

const createContentCategory = (data) =>
  helper.createAction(types.CREATE_CONTENT_CATEGORY, { data });
const getContentCategories = (params) =>
  helper.createAction(types.GET_CONTENT_CATEGORIES, { params });
const setDataContentCategories = (data) =>
  helper.createAction(types.SET_DATA_CONTENT_CATEGORIES, { data });
const setStatusContentCategories = (status) =>
  helper.createAction(types.SET_STATUS_CONTENT_CATEGORIES, { status });
const setErrorContentCategories = (errors, scope) =>
  helper.createAction(types.SET_ERROR_CONTENT_CATEGORIES, { errors }, scope);

const updateRankPriorityContentCategory = (originData, data, callback) =>
  helper.createAction(types.UPDATE_RANK_PRIORITY_CONTENT_CATEGORY, {
    originData,
    data,
    callback,
  });
const cleanStates = (data) =>
  helper.createAction(types.CLEAN_STATES, { ...data });

const uploadCsv = (payload) => helper.createAction(types.UPLOAD_CSV, payload);
const setDataUpload = (data) =>
  helper.createAction(types.SET_DATA_UPLOAD, { data });
const setStatusUpload = (status) =>
  helper.createAction(types.SET_STATUS_UPLOAD, { status });
const setErrorUploadConfirm = (errors) =>
  helper.createAction(types.SET_ERROR_UPLOAD, { errors });
const setStatusOpenUploadConfirm = (status) =>
  helper.createAction(types.SET_STATUS_OPEN_UPLOAD, { status });

const setCsvFileName = (fileName) =>
  helper.createAction(types.SET_CSV_FILE_NAME, { fileName });

const submitCsv = () => helper.createAction(types.SUBMIT_CSV);

const tagManagementActions = {
  getList,
  setDataList,
  setErrorList,
  setStatusList,
  getDetail,
  setDataDetail,
  setStatusDetail,
  setErrorDetail,
  changeTab,
  updateSort,
  updatePage,
  downloadCsv,
  selectRows,
  create,
  update,
  deleteItems,
  cleanStates,
  uploadCsv,
  submitCsv,
  setDataUpload,
  setStatusUpload,
  setErrorUploadConfirm,
  setStatusOpenUploadConfirm,
  setCsvFileName,
  createContentCategory,
  getContentCategories,
  setDataContentCategories,
  setStatusContentCategories,
  setErrorContentCategories,
  updateRankPriorityContentCategory,
};

export default tagManagementActions;
