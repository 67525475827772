import React, { useContext, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import {
  GridTableContext,
  GridTableItemContext,
} from 'views/organism/GridTable/GridTableContext';
import GirdTableCell from 'views/organism/GridTable/components/GirdTableCell';
import { ROW_TOTAL, FIELD_TOTAL } from '../consts';

function GridTableRow() {
  const { dispatch, settings, rowsRendering: rows, gridTableRef, isShowRowTotal } = useContext(
    GridTableContext
  );

  const {
    isFreeze,
    headersForBody: { headers, parentFields },
  } = useContext(GridTableItemContext);

  const { selectedRows, highlightRows, hasSelectRowFunc } = settings;

  const onMouseOverRow = useCallback(
    (rowindex) => {
      Array.from(
        gridTableRef.current.querySelectorAll(
          `[role="rowgroup"] [aria-rowindex="${rowindex}"]`
        )
      ).forEach((elem) => elem.classList.add('grid-table__row--hover'));
    },
    [gridTableRef]
  );

  const onMouseOutRow = useCallback(
    (rowindex) => {
      Array.from(
        gridTableRef.current.querySelectorAll(
          `[role="rowgroup"] [aria-rowindex="${rowindex}"]`
        )
      ).forEach((elem) => elem.classList.remove('grid-table__row--hover'));
    },
    [gridTableRef]
  );

  const onHighlightRow = useCallback(
    (rowindex, isHighlight, id) => {
      let isAllowSelect = false;
      if (hasSelectRowFunc && id) {
        isAllowSelect = rows.some(
          (row) =>
            row.rowId === id && !row.isDisabledCheckbox && !row.isAllowSelectRow
        );
        if (isAllowSelect) {
          const newSelectedRows = !isHighlight
            ? [...selectedRows, id]
            : selectedRows.filter((item) => item !== id);

          dispatch({ type: 'setSelectedRow', payload: newSelectedRows });
        }
      }

      if (!isAllowSelect) {
        const newhighlightRows = isHighlight
          ? highlightRows.filter((item) => item !== rowindex)
          : [...highlightRows, rowindex];

        dispatch({ type: 'setHighlightRow', payload: newhighlightRows });
      }
    },
    [dispatch, hasSelectRowFunc, highlightRows, selectedRows, rows]
  );

  return useMemo(() => {
    if (headers?.length === 0) return <></>;

    return (
      <>
        {/* If the data is empty, there is always 1 row by default to handle horizontal scrolling */}
        {(isEmpty(rows) ? [{}] : rows).map((row, index) => {
          const {
            rowId = null,
            rowindex = index,
          } = row;
          const isRowtotal = isShowRowTotal && rowindex - 1 === ROW_TOTAL;
          const isEven = rowindex % 2 === 0;
          const isSelected = selectedRows.includes(rowId);
          const gridRow = parentFields.reduce((acc, parentField) => {
            if (acc < row[parentField]?.length) return row[parentField].length;
            return acc;
          }, 0);
          const isHighlight = isSelected || highlightRows.includes(rowindex);
          const rowClass = classNames({
            'grid-table__row': true,
            'grid-table__row--total': isRowtotal,
            'grid-table__row--even': isShowRowTotal ? isEven : !isEven,
            'grid-table__row--odd': isShowRowTotal ? !isEven : isEven,
            'grid-table__row--selected': isSelected || isHighlight,
            'grid-table__row--highlight': row.isHighlight,
          });

          if (isRowtotal && isFreeze) {
            const cell = { field: FIELD_TOTAL };
            const data = { [FIELD_TOTAL]: '合計' };
            return (
              <div
                key={rowindex}
                role="row"
                aria-rowindex={rowindex}
                className={rowClass}
              >
                <GirdTableCell
                  cell={cell}
                  data={data}
                  gridRow={gridRow}
                  gridColumn="1 / -1"
                />
              </div>
            );
          }

          return (
            <div
              key={rowindex}
              role="row"
              aria-rowindex={rowindex}
              className={rowClass}
              onClick={() => onHighlightRow(rowindex, isHighlight, rowId)}
              onMouseOver={() => onMouseOverRow(rowindex)}
              onMouseOut={() => onMouseOutRow(rowindex)}
              onFocus={() => {}}
              onBlur={() => {}}
              aria-hidden="true"
            >
              {headers.map((header) => {
                const { field, children = [], isMergeColumns = false } = header;
                if (!isMergeColumns) {
                  const data = { ...row, rowindex, selected: isSelected };
                  return (
                    <GirdTableCell
                      key={field}
                      cell={header}
                      data={data}
                      gridRow={gridRow}
                    />
                  );
                }
                const items = isEmpty(row[field]) ? [{}] : row[field];
                return (
                  <div
                    key={field}
                    className="grid-table__subgrid"
                    aria-rowspan={items.length}
                  >
                    {(() => {
                      return items.map((item, i) => {
                        const data = { ...item, rowindex };
                        const key = `${rowindex}-${i}`;
                        const cells = children.map((cell) => (
                          <GirdTableCell
                            key={cell.field}
                            cell={cell}
                            data={data}
                          />
                        ));
                        return (
                          <div
                            key={key}
                            className="grid-table__row"
                            aria-colspan={cells.length}
                          >
                            {cells}
                          </div>
                        );
                      });
                    })()}
                  </div>
                );
              })}
            </div>
          );
        })}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers, rows, selectedRows, highlightRows]);
}

GridTableRow.propTypes = {};

GridTableRow.defaultProps = {};

export default React.memo(GridTableRow);
