import React from 'react';
import {
  ROLE_VIEW_AD,
  ROLE_VIEW_ALL_AD_ONLY,
  ROLE_SETTING_OTHER_AGENT,
  ROLE_SETTING_MEDIA_SYNC,
  ROLE_CAPI_AGENT_SETTING,
  ROLE_SEARCH_CONSOLE_SETTING,
  ROLE_LINE_ADD_FRIEND_SETTING,
} from 'domain/permissions/roles';
import {
  AGENCY_NAME,
  MEDIA,
  AD_GROUP1,
  AD_GROUP2,
  AGENCY_ROLES,
} from 'domain/fields';
import {
  CAPI_SETTING_VIEW,
  LINE_SETTING_VIEW,
  MANAGE_LTV_EDIT,
  MEDIA_SYNC_MANAGEMENT_EDIT,
  SC_SETTING_VIEW,
  SETTING_EBIS_API_VIEW,
} from 'domain/permissions/contractPermissions';
import { MEDIA_SYNC_MANAGEMENT_DRAGONBALL_LINK } from 'services/routes/constants';

export const AGENCY_NAME_MAX = 100;
export const AD_GROUP_ID = 'ad_group_id';
export const AD_GROUP_NAME = 'ad_group_name';

export const MAX_ITEMS_REGISTER_BY_SYNC = 3000;

export const SETTING_ROLES = [
  {
    value: ROLE_VIEW_ALL_AD_ONLY,
    name: ['閲覧', '（全トラフィック）'],
    minWidth: '112px',
  },
  {
    value: ROLE_VIEW_AD,
    name: ['閲覧', '（広告）'],
    minWidth: '112px',
  },
  {
    value: ROLE_SETTING_OTHER_AGENT,
    name: '編集',
    minWidth: '112px',
  },
  {
    value: ROLE_SETTING_MEDIA_SYNC,
    name: '媒体シンク設定',
    minWidth: '112px',
    requirePermission: [MEDIA_SYNC_MANAGEMENT_EDIT],
  },
  {
    value: ROLE_SEARCH_CONSOLE_SETTING,
    name: ['Google Search', 'Console連携設定'],
    minWidth: '112px',
    requirePermission: [SC_SETTING_VIEW],
  },
  {
    value: ROLE_CAPI_AGENT_SETTING,
    name: 'CAPiCO設定',
    minWidth: '112px',
    requirePermission: [CAPI_SETTING_VIEW],
  },
  {
    value: ROLE_LINE_ADD_FRIEND_SETTING,
    name: ['LINE友だち追加', '計測設定'],
    minWidth: '112px',
    requirePermission: [LINE_SETTING_VIEW],
  },
];

export const ANALYZE_SCREEN = {
  group: '分析画面',
  permissions: [
    {
      name: '広告',
      tooltip: '広告のみを集計対象とした各分析画面を閲覧することができます。',
      roles: [ROLE_VIEW_AD, ROLE_VIEW_ALL_AD_ONLY],
    },
    {
      name: '全トラフィック',
      tooltip:
        'すべてのチャネルを集計対象とした各分析画面を閲覧することができます。ご契約プランによって、集計対象となるチャネル種別が異なります。',
      roles: [ROLE_VIEW_ALL_AD_ONLY],
    },
  ],
};

export const SETTING_SCREEN_AGENCY = {
  group: '設定画面',
  permissions: [
    {
      name: '基本設定',
      tooltip:
        '計測対象サイトの登録やページ設定といった、アドエビスの計測に関する設定を行うことができる画面です。',
      roles: [],
    },
    {
      name: 'タグ設定',
      id: 'agentSetting',
      tooltip:
        '共通タグの発行やカスタムタグの登録、編集を行うことができる画面です。',
      roles: [ROLE_SETTING_OTHER_AGENT],
    },
    {
      name: 'ページ設定',
      subNames: ['LINE友だち追加計測設定'],
      tooltip:
        'LINE友だち追加計測設定は、LINE友だち追加をコンバージョンとして計測するための設定を行うことができる画面です。',
      roles: [ROLE_LINE_ADD_FRIEND_SETTING],
      requirePermission: LINE_SETTING_VIEW,
    },
    {
      name: '広告設定',
      subNames: ['広告管理', '広告カテゴリ管理', '配信管理'],
      tooltip:
        '広告の登録や編集・広告パラメーターの発行を行うことができる画面です。',
      roles: [ROLE_SETTING_OTHER_AGENT],
    },
    {
      name: '広告設定',
      subNames: ['媒体シンク設定'],
      tooltip: (
        <>
          媒体シンク機能に関連する設定や、入稿用のパラメータが発行できる画面です。詳しくは
          <a
            href={MEDIA_SYNC_MANAGEMENT_DRAGONBALL_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
          。
        </>
      ),
      roles: [ROLE_SETTING_MEDIA_SYNC],
      requirePermission: MEDIA_SYNC_MANAGEMENT_EDIT,
    },
    {
      name: 'コスト設定',
      tooltip:
        'コスト設定は、表示回数・広告コストデータの登録や媒体データ取得の連携設定を行うことができる画面です。',
      roles: [ROLE_SETTING_OTHER_AGENT],
    },
    {
      name: 'LTVForecast分析設定',
      tooltip: 'LTVForecastの分析に関わる設定を行うことができる画面です。',
      roles: [ROLE_SETTING_OTHER_AGENT],
      requirePermission: MANAGE_LTV_EDIT,
    },
    {
      name: '外部サービス連携管理',
      subNames: ['外部サービス連携管理'],
      tooltip:
        '外部連携サービス連携設定は、アドエビスと連携することのできる外部サービスの設定を行うことができる画面です。',
      roles: [],
    },
    {
      name: '外部サービス連携管理',
      subNames: ['Google Search Console\n連携設定'],
      tooltip:
        'Google Search Console連携設定は、Google Search Consoleのデータをアドエビスに連携するための設定を行うことができる画面です。',
      roles: [ROLE_SEARCH_CONSOLE_SETTING],
      requirePermission: SC_SETTING_VIEW,
    },
    {
      name: 'API',
      tooltip:
        'API形式で分析画面と同じ集計データが取得できます。権限を剥奪すると発行済みのリフレッシュトークンは無効になります。',
      roles: [],
      requirePermission: SETTING_EBIS_API_VIEW,
    },
    {
      name: 'CAPiCO設定',
      tooltip:
        'CAPiCOは広告配信の最適化のために、各媒体とCVデータを紐付ける設定を行うことができる画面です。',
      roles: [ROLE_CAPI_AGENT_SETTING],
      requirePermission: CAPI_SETTING_VIEW,
    },
  ],
};

export const SETTING_PERMISSIONS = [ANALYZE_SCREEN, SETTING_SCREEN_AGENCY];

export const STEP_ONE = 1;
export const STEP_TWO = 2;
export const STEPS = [
  {
    step: STEP_ONE,
    label: '代理店情報入力',
    active: false,
  },
  {
    step: STEP_TWO,
    label: '権限設定',
    active: false,
  },
];

export const FIELD_FORM = {
  [AGENCY_NAME]: '会社名',
  [MEDIA]: '媒体種別',
  [AD_GROUP1]: '広告グループ1',
  [AD_GROUP2]: '広告グループ2',
  [AGENCY_ROLES]: '権限設定',
};

export const FIELD_REQUEST_API = {
  [AGENCY_NAME]: 'agency_name',
  [MEDIA]: 'media_ids',
  [AD_GROUP1]: 'ad_group1_ids',
  [AD_GROUP2]: 'ad_group2_ids',
  [AGENCY_ROLES]: 'agency_roles',
};

export const AGENT_SYNC_MAX_ITEM_AD_CATEGORY = 200;

export const AGENT_SYNC_HEADER = [
  {
    isFreeze: true,
    field: 'rowsId',
    name: '移行対象',
    width: '56px',
    type: 'checkbox',
  },
  {
    isFreeze: true,
    field: 'user_id',
    name: 'ユーザーID',
    width: '100%',
    minWidth: '101px',
  },
  {
    field: 'before_sync_agency',
    name: '旧バージョンのエージェント権限設定',
    children: [
      {
        field: 'old_company_name',
        name: '会社名',
        defaultWidth: '120px',
      },
      {
        field: 'old_media',
        name: '媒体種別',
        defaultWidth: '121px',
        type: 'collapseByLine',
        maxLine: 3,
        isInline: true,
      },
      {
        field: 'old_ad_group1',
        name: '広告グループ1',
        defaultWidth: '121px',
        type: 'collapseByLine',
        maxLine: 3,
        isInline: true,
      },
      {
        field: 'old_ad_group2',
        name: '広告グループ2',
        defaultWidth: '121px',
        type: 'collapseByLine',
        maxLine: 3,
        isInline: true,
      },
    ],
  },
  {
    field: 'icon',
    width: '30px',
  },
  {
    field: 'after_sync_agency',
    name: '新バージョンの代理店設定',
    children: [
      {
        field: 'new_company_name',
        name: '代理店名',
        defaultWidth: '121px',
      },
      {
        field: 'new_media',
        name: '媒体種別',
        defaultWidth: '121px',
        type: 'collapseByLine',
        maxLine: 3,
        isInline: true,
      },
      {
        field: 'new_ad_group1',
        name: '広告グループ1',
        defaultWidth: '121px',
        type: 'collapseByLine',
        maxLine: 3,
        isInline: true,
      },
      {
        field: 'new_ad_group2',
        name: '広告グループ2',
        defaultWidth: '121px',
        type: 'collapseByLine',
        maxLine: 3,
        isInline: true,
      },
    ],
  },
  {
    field: 'cnt_link_ad',
    name: '広告への代理店紐付け件数',
    width: '122px',
    textAlign: 'right',
  },
];

export const INTRODUCE = 1;
export const INTRODUCE2 = 1.2;
export const CONFIRM = 2;
export const COMPLETED = 3;
export const STEPS_AGENT_SYNC_MODAL = [
  { step: INTRODUCE, label: '機能説明' },
  { step: CONFIRM, label: '変更内容の確認' },
  { step: COMPLETED, label: '完了' },
];

export const DEFAULT_INDEX_FUNCTION_KEY = 2;
