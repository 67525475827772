import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  communicationStatus,
  HttpStatus,
  isArrayEmpty,
  saveAs,
} from 'services/utils';
import mediaServiceApi from 'services/api/settings/MediaService';
import loggerActions from 'store/logger/actions';
import loggerConstants from 'store/logger/constant';
import sharedSagas from 'store/sharedSagas';
import handleError from 'services/error/handleScopeError';
import { getSortString, getOffset } from 'domain/utils';
import { TABLE_ROWS_PER_PAGE } from 'domain/consts';
import filterSelectors from 'store/filters/selectors';
import settingsSelectors from 'store/settings/selectors';
import {
  AD_MANAGEMENT_MEDIA_TYPE,
  AD_MANAGEMENT_AD_GROUP_1,
  AD_MANAGEMENT_AD_GROUP_2,
  AGENCY_MANAGEMENT,
} from 'services/routes/constants';
import {
  TYPE_MEDIA_TYPE,
  TYPE_AD_GROUP1,
  TYPE_AD_GROUP2,
} from 'domain/ad-management/media-group/constants';
import uploadActions from 'store/upload/actions';
import commonActions from 'store/common/actions';
import actions from './actions';
import selectors from './selectors';

import types from './types';

// TODO: Handle upload csv operation

const { LOADING, SUCCEEDED, FAILED } = communicationStatus;

function* handleCreate(action) {
  try {
    yield put(actions.setStatusDetail(LOADING));

    const { name, agencyId } = action.payload;

    const createdData = yield call(mediaServiceApi.create, name, agencyId);

    yield put(actions.setDataDetail(createdData));

    yield put(actions.setStatusDetail(SUCCEEDED));
  } catch (e) {
    const { error: response, scope } = handleError(e.response, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];
    yield put(actions.setErrorDetail(errors, scope));
    yield put(actions.setStatusDetail(FAILED));
  }
}

function* handleAdd(action) {
  try {
    yield put(actions.setStatusDetail(LOADING));
    const response = yield call(mediaServiceApi.add, action.payload);
    const page = yield select(settingsSelectors.getPage);
    const { type } = action.payload;
    // Not process when status is 202
    if (response.status === 200) {
      if (page === AGENCY_MANAGEMENT) {
        // Create items from Agency screen
        yield put(actions.setDataList(response.data.data.detail, type));
        yield put(actions.setErrorDetail([], loggerConstants.SCOPE_DONT_SHOW));
        yield put(actions.setStatusDetail(SUCCEEDED));
      } else {
        yield put(actions.setStatusDetail(SUCCEEDED));
        yield put(actions.fetchList(type));
      }
    }
  } catch (e) {
    const { error: response, scope } = handleError(e.response, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];
    yield put(actions.setErrorDetail(errors, scope));
    yield put(actions.setStatusDetail(FAILED));
  }
}

function* handleGetByID(action) {
  try {
    yield put(actions.setStatusFetchById(LOADING));
    const data = yield call(mediaServiceApi.getByID, action.payload);
    yield put(actions.setDataDetail(data));
    yield put(actions.setStatusFetchById(SUCCEEDED));
  } catch (e) {
    const { error: response, scope } = handleError(e.response, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];
    yield put(actions.setErrorDetail(errors, scope));
    yield put(actions.setStatusDetail(FAILED));
  }
}
function* handleDownloadCsvTemplate(action) {
  try {
    yield put(
      commonActions.setDownloadNotify('CSVファイルを作成しています...')
    );
    const response = yield call(
      mediaServiceApi.downloadCsvTemplate,
      action.payload
    );
    if (response.status === 200) {
      const { location } = response.data.data;
      saveAs(location);
    }
    yield put(commonActions.setDownloadNotify(''));
  } catch (e) {
    const { error: response, scope } = handleError(e.response, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];
    yield put(actions.setErrorDetail(errors, scope));
  }
}

function* handleFetchDeleteList(action) {
  try {
    yield put(actions.setStatusFetchDeleteList(LOADING));
    const data = yield call(mediaServiceApi.fetchDeleteList, action.payload);
    yield put(actions.setDataDetail(data));
    yield put(actions.setStatusFetchDeleteList(SUCCEEDED));
  } catch (e) {
    const { error: response, scope } = handleError(e.response, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];
    yield put(actions.setErrorDetail(errors, scope));
    yield put(actions.setStatusDetail(FAILED));
  }
}

function* handleUpdate(action) {
  try {
    yield put(actions.setStatusDetail(LOADING));
    yield call(mediaServiceApi.update, action.payload);
    yield put(actions.setStatusDetail(SUCCEEDED));
    const { type } = action.payload;
    yield put(actions.fetchList(type));
  } catch (e) {
    const { error: response, scope } = handleError(e.response, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];
    yield put(actions.setErrorDetail(errors, scope));
    yield put(actions.setStatusDetail(FAILED));
  }
}

function* handleRemove(action) {
  try {
    yield put(actions.setStatusDetail(LOADING));
    const response = yield call(mediaServiceApi.remove, action.payload);
    // Not process when status is 202
    if (response.status === 200) {
      yield put(actions.setStatusDetail(SUCCEEDED));
      const { type } = action.payload;
      yield put(actions.fetchList(type));
    }
  } catch (e) {
    const { error: response, scope } = handleError(e.response, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];
    yield put(actions.setErrorDetail(errors, scope));
    yield put(actions.setStatusDetail(FAILED));
  }
}

const getScreenIdByType = (type) => {
  if (type === TYPE_MEDIA_TYPE) {
    return TYPE_MEDIA_TYPE;
  }
  if (type === TYPE_AD_GROUP1) {
    return AD_MANAGEMENT_AD_GROUP_1;
  }
  if (type === TYPE_AD_GROUP2) {
    return AD_MANAGEMENT_AD_GROUP_2;
  }
  return AD_MANAGEMENT_MEDIA_TYPE;
};

// TODO: Handle upload csv operation
function* handleUpload(action) {
  try {
    const { type, file } = action.payload;
    yield put(uploadActions.requestStart(getScreenIdByType(type)));

    const {
      data: {
        data: { location: uploadPath },
      },
    } = yield call(mediaServiceApi.getPresignedUrl, type);

    yield call(mediaServiceApi.upload, uploadPath, file);
    const url = new URL(uploadPath);
    // Get last element after `/` character
    const filename = url.pathname.split('/').slice(-1).pop();

    // validate & import data
    const {
      data: {
        data: { file: filenameConfirm },
      },
    } = yield call(mediaServiceApi.validate, { type, filename });
    yield put(actions.setFetchUploadConfirmListStatus(LOADING));
    const { data } = yield call(mediaServiceApi.confirm, {
      type,
      filenameConfirm,
    });
    // Check confirm list is empty
    if (isArrayEmpty(data.data.detail)) {
      // Process upload confirm
      yield put(actions.uploadConfirm({ type, file: filename }));
    } else {
      // Handle prepare show confirm list
      yield put(actions.fetchedUploadConfirmList(data));
      yield put(actions.setFileNameConfirmUpload({ filename }));
      yield put(uploadActions.uploadSucceeded());
    }
  } catch (error) {
    const { response = { status: 500 } } = error;
    const status = response?.status ?? 500;
    if (status === 400) {
      // validate error
      yield put(uploadActions.requestFailed(response.data.errors));
    } else {
      // api or app error
      yield put(uploadActions.requestError(handleError(response)));
    }
  }
}

function* handleConfirmImport(action) {
  try {
    const { type } = action.payload;
    yield put(actions.setStatusDetail(LOADING));
    const response = yield call(
      mediaServiceApi.requestImportStatus,
      action.payload
    );
    if (response.status === 200) {
      yield put(uploadActions.uploadSucceeded());
      yield put(actions.setStatusDetail(SUCCEEDED));
      yield put(actions.fetchList(type));
    }
  } catch (e) {
    const { error: response, scope } = handleError(e.response, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];
    yield put(actions.setErrorDetail(errors, scope));
    yield put(actions.setStatusDetail(FAILED));
  }
}

function* errorHandler(err) {
  const errors = err.response
    ? err.response.data.errors
    : [{ message: err.message }];

  yield put(loggerActions.logError(errors, loggerConstants.LOG_LEVEL_ERROR));
}

function* handleFetchList(action) {
  try {
    const pagination = yield select(selectors.getPagination);
    const sort = yield select(selectors.getSort);
    const type = action.payload;

    yield put(actions.fetchListStart());
    const params = {
      mgroup12: '1',
      sort: getSortString(sort),
      limit: TABLE_ROWS_PER_PAGE,
      filters: yield select(filterSelectors.getForApi),
      offset: getOffset(pagination),
    };

    const response = yield call(mediaServiceApi.fetchAll, {
      type,
      params,
    });
    // Handle case last page is empty
    if (response.metadata.count > 0 && response.data.detail.length === 0) {
      // more items available in previous page
      // calculate final page
      const finalPage = Math.ceil(
        response.metadata.count / TABLE_ROWS_PER_PAGE
      );
      yield put(actions.updatePage({ page: finalPage }));
      yield put(actions.fetchList(type));
    } else {
      yield put(actions.fetchedList(response));
    }
  } catch (error) {
    yield put(
      actions.requestFailed({
        error: handleError(error.response),
      })
    );
  }
}

function* handleFetchPostBackList() {
  try {
    yield put(actions.fetchPostBackListStart());
    const params = { limit: 10 };
    const response = yield call(mediaServiceApi.fetchPostBackHistory, {
      params,
    });
    yield put(actions.fetchedPostBackList({ data: response.data }));
  } catch (error) {
    yield put(
      actions.requestFailed({
        error: handleError(error.response),
      })
    );
  }
}

function* handleDownloadCsvPostback() {
  try {
    yield put(
      commonActions.setDownloadNotify('CSVファイルを作成しています...')
    );
    yield call(mediaServiceApi.downloadCsvPostback);
    yield put(commonActions.setDownloadNotify(''));
  } catch (error) {
    yield put(
      actions.requestFailed({
        error: handleError(error.response),
      })
    );
  }
}

export default function* watch() {
  yield takeLatest(types.CREATE, sharedSagas.safe(errorHandler, handleCreate));
  yield takeLatest(types.ADD, sharedSagas.safe(errorHandler, handleAdd));
  yield takeLatest(types.UPDATE, sharedSagas.safe(errorHandler, handleUpdate));
  yield takeLatest(types.REMOVE, sharedSagas.safe(errorHandler, handleRemove));
  yield takeLatest(types.UPLOAD, sharedSagas.safe(errorHandler, handleUpload));
  yield takeLatest(
    types.UPLOAD_CONFIRM,
    sharedSagas.safe(errorHandler, handleConfirmImport)
  );
  yield takeLatest(
    types.GET_BY_ID,
    sharedSagas.safe(errorHandler, handleGetByID)
  );
  yield takeLatest(
    types.DOWNLOAD_CSV_TEMPLATE,
    sharedSagas.safe(errorHandler, handleDownloadCsvTemplate)
  );
  yield takeLatest(
    types.FETCH_DELETE_LIST,
    sharedSagas.safe(errorHandler, handleFetchDeleteList)
  );
  yield takeLatest(
    types.FETCH_LIST,
    sharedSagas.safe(errorHandler, handleFetchList)
  );
  yield takeLatest(
    types.FETCH_POSTBACK_HISTORY_LIST,
    sharedSagas.safe(errorHandler, handleFetchPostBackList)
  );
  yield takeLatest(
    types.DOWNLOAD_CSV_POSTBACK,
    sharedSagas.safe(errorHandler, handleDownloadCsvPostback)
  );
}
