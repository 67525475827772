import {
  CNAME_SETTING,
  MAX_ROW_DNS_SETTING,
  MASTERDATA_STATUS_KEY,
} from 'domain/system-setting/consts';
import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { getPermissions } from 'store/auth/selectors';
import CookieProgramApi from 'services/api/CookieProgramApi';
import SystemSettingApi from 'services/api/SystemSettingApi';
import handleError from 'services/error/handleScopeError';
import { HttpStatus, communicationStatus } from 'services/utils';
import { SETTING_USER_EDIT } from 'domain/permissions/contractPermissions';

import actions from './actions';
import { updateAcquisitionMediaClickId } from '../auth/actions';
import selectors from './selectors';
import types from './types';

const { LOADING, SUCCEEDED, FAILED, UPDATING } = communicationStatus;
const { BAD_REQUEST } = HttpStatus;

function* handleGetCookieProgramSettings(action) {
  try {
    yield put(actions.setStatusCookieProgramSettings(LOADING));
    const response = yield call(CookieProgramApi.fetchAll);
    yield put(actions.setCookieProgramSettings(response.data.data));

    yield put(actions.setStatusCookieProgramSettings(SUCCEEDED));
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];
    yield put(actions.setStatusCookieProgramSettings(FAILED));
    yield put(actions.updateCookieProgramSettingFailed(errors, scope));
  }
}

function* handleGetSettingBasic() {
  try {
    yield put(actions.setStatusBasicSetting(LOADING));
    const {
      data: { data },
    } = yield call(SystemSettingApi.getBasicSetting);

    yield put(actions.setDataBasicSetting(data));
    yield put(actions.getCrossDomainList({ host_name: data.host_name }));
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];
    yield put(actions.updateBasicSettingFailed(errors, scope));
  }
}

function* handleUpdateSettingBasic(action) {
  const { data: dataRequest, callback } = action.payload;
  try {
    yield call(SystemSettingApi.updateBasicSetting, dataRequest);
    yield put(actions.getBasicSetting());
    callback({ errors: [] });
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];
    yield put(actions.updateBasicSettingFailed(errors, scope));
    callback({ errors });
  }
}

function* handleGetCrossDomainList(action) {
  const { data: dataRequest, callback } = action.payload;
  try {
    yield delay(500);
    const {
      data: { data },
    } = yield call(SystemSettingApi.getCrossDomainList, dataRequest);
    yield put(actions.setCrossDomainList(data));
    callback(data.cross_domain_list || '');
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];
    yield put(actions.updateBasicSettingFailed(errors, scope));
  }
}

function* handleGetMeasurementSetting() {
  try {
    yield put(actions.setStatusMeasurementSetting(LOADING));
    const {
      data: { data },
    } = yield call(SystemSettingApi.getMeasurementSetting);

    const objAuth = data.dtb_user.reduce(
      (objUser, user) => {
        if (user.alert_log_page_limit) {
          objUser.alertAuthIds.push(user.auth_id);
          objUser.userIds.push(user.user_id);
        }
        return objUser;
      },
      {
        alertAuthIds: [],
        userIds: [],
      }
    );

    yield put(
      actions.setDataMeasurementSetting({
        ...data,
        alert_auth_ids: objAuth.alertAuthIds,
        userIds: objAuth.userIds,
      })
    );
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];
    yield put(actions.updateMeasurementSettingFailed(errors, scope));
  }
}

function* handleUpdateMeasurementSetting(action) {
  const { endpoint, data: dataRequest, callback } = action.payload;
  try {
    yield call(
      SystemSettingApi.updateMeasurementSetting,
      endpoint,
      dataRequest
    );
    yield put(actions.getMeasurementSetting());
    if (endpoint === 'click_id_setting') {
      yield put(
        updateAcquisitionMediaClickId({
          acquisitionMediaClickId: dataRequest.enable_flag,
        })
      );
    }
    callback({ errors: [] });
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];
    yield put(actions.updateMeasurementSettingFailed(errors, scope));
    callback({ errors });
  }
}

function* handleGetOtherSetting() {
  try {
    yield put(actions.setStatusOtherSetting(LOADING));
    const {
      data: { data },
    } = yield call(SystemSettingApi.getOtherSetting);

    const objAuth =
      data.dtb_user &&
      data.dtb_user.reduce(
        (objUser, user) => {
          if (user.alert_capi_limit) {
            objUser.alertUserIds.push(user.auth_id);
            objUser.userIds.push(user.user_id);
          }
          return objUser;
        },
        {
          alertUserIds: [],
          userIds: [],
        }
      );

    yield put(
      actions.setDataOtherSetting({
        ...data,
        alert_auth_ids: objAuth.alertUserIds,
        userIds: objAuth.userIds,
      })
    );
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];
    yield put(actions.updateOtherSettingFailed(errors, scope));
  }
}

function* handleUpdateOtherSetting(action) {
  const { data: dataRequest, callback } = action.payload;
  try {
    yield call(SystemSettingApi.updateOtherSetting, dataRequest);
    yield put(actions.getOtherSetting());
    callback({ errors: [] });
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];
    yield put(actions.updateOtherSettingFailed(errors, scope));
    callback({ errors });
  }
}

function* handleUpdateCookieProgramSettings(action) {
  const { data: dataRequest } = action.payload;
  try {
    yield put(actions.setStatusCookieProgramSettings(UPDATING));
    yield call(CookieProgramApi.update, dataRequest);
    yield put(actions.getCookieProgramSettings());
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];
    yield put(actions.setStatusCookieProgramSettings(FAILED));
    yield put(actions.updateCookieProgramSettingFailed(errors, scope));
  }
}

function* handleDeleteCookieProgramSettings(action) {
  const { data: dataRequest } = action.payload;
  try {
    yield put(actions.setStatusCookieProgramSettings(UPDATING));
    yield call(CookieProgramApi.remove, dataRequest);
    yield put(actions.getCookieProgramSettings());
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];
    yield put(actions.setStatusCookieProgramSettings(FAILED));
    yield put(actions.updateCookieProgramSettingFailed(errors, scope));
  }
}

function* handleCheckCookieProgramSettings(action) {
  yield put(actions.setStatusCookieProgramChecking(UPDATING));
  const { targets } = action.payload;
  const measurementFqdns = Object.keys(targets);
  if (measurementFqdns.length > 0) {
    yield put(actions.setCheckingCookieProgramUrl(measurementFqdns));
    yield call(CookieProgramApi.wait, 800);
    const entries = Object.entries(targets);
    for (const entry of entries) {
      const [measurementFqdn, url] = entry;
      const checkUrl = `${url}?name=_ebtd&value=testValue&test=true&domain=${measurementFqdn}`;
      let cookieValueStatus = 'NG';
      let responseStatus = '疎通不能';
      try {
        const response = yield call(fetch, checkUrl);
        responseStatus = response.status;
        if (responseStatus === 200) {
          const responseCookieValue = response.headers
            .get('X-Ebis-Set-Cookie-Test')
            .split(';')[0]
            .split('=')[1];
          if (responseCookieValue === 'testValue') {
            cookieValueStatus = 'OK';
          }
        }
      } catch (error) {
        // Nothing to do
      } finally {
        yield put(
          actions.setCookieProgramSettingsCheckStatus({
            measurement_fqdn: measurementFqdn,
            status: responseStatus,
            cookieValue: cookieValueStatus,
          })
        );
        yield put(actions.setStatusCookieProgramChecking(SUCCEEDED));
      }
    }
  }
}

function* handleGetDnsSetting() {
  try {
    const tab = yield select(selectors.getTab);
    yield put(actions.setStatusDnsSetting(LOADING));
    const {
      data: {
        data: list,
        metadata: {
          hostname_count: hostnameCount,
          set_etld1_count: etld1Count,
          health_status: healthStatus,
        },
      },
    } = yield call(SystemSettingApi.getDnsSetting, tab, 1);
    yield put(
      actions.setDataDnsSetting({
        list,
        isEmptyHostName: hostnameCount < 1,
        isDisabledRegister: etld1Count >= MAX_ROW_DNS_SETTING,
        healthStatusList: healthStatus,
        isScreenComplete: false,
      })
    );

    const {
      data: { data: dataDnsAdd },
    } = yield call(SystemSettingApi.getDnsSetting, tab, 0);

    yield put(actions.setDataDnsAddSetting(dataDnsAdd));
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];
    yield put(actions.getDnsSettingFailed(errors, scope));
  }
}

function* handleAddDnsSetting(action) {
  const {
    data: { listDomainRequest, isAddEmail },
    callback,
  } = action.payload;
  const tab = yield select(selectors.getTab);
  try {
    let dataResponse = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const entry of listDomainRequest) {
      const {
        data: { data },
      } = yield call(SystemSettingApi.addDnsSetting, tab, 1, entry);
      dataResponse = [...dataResponse, ...data];
    }
    const dataPayload = { data: dataResponse };
    if (tab === CNAME_SETTING) {
      dataPayload.email_authentication = isAddEmail;
    }
    yield call(SystemSettingApi.addDnsSetting, tab, 0, dataPayload);
    yield put(
      actions.setDataDnsSetting({ list: dataResponse, isScreenComplete: true })
    );
    yield put(actions.setStatusDnsSetting(LOADING));
    callback({ errors: [], isError: false });
    yield put(actions.setStatusDnsSetting(SUCCEEDED));
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];
    yield put(actions.addDnsSettingFailed(errors, scope));
    callback({ errors, isError: true });
  }
}

function* handleDeleteDnsSetting(action) {
  const { data: dataRequest, callback } = action.payload;
  const tab = yield select(selectors.getTab);
  try {
    yield call(SystemSettingApi.deleteDnsSetting, tab, dataRequest);
    yield put(actions.getDnsSetting());
    callback({ errors: [] });
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];
    yield put(actions.addDnsSettingFailed(errors, scope));
    callback({ errors });
  }
}

function* handleUpdateDnsSettingEmail(action) {
  const { data, callback } = action.payload;
  const tab = yield select(selectors.getTab);
  try {
    yield call(SystemSettingApi.updateDnsSetting, tab, data);
    yield put(actions.getDnsSetting());
    callback({ errors: [] });
  } catch (e) {
    console.log(e);
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];
    yield put(actions.addDnsSettingFailed(errors, scope));
    callback({ errors });
  }
}

function* handleGetMasterData() {
  yield put(actions.setStatus(LOADING, MASTERDATA_STATUS_KEY));

  const permissions = yield select(getPermissions);
  const response = {};
  if (permissions.includes(SETTING_USER_EDIT)) {
    const userRes = yield call(SystemSettingApi.fetchUsers);
    response.users = userRes.data.data.detail;
  }

  yield put(actions.setMasterData(response));
}

export default function* watch() {
  yield takeLatest(
    types.GET_COOKIE_PROGRAM_SETTINGS,
    handleGetCookieProgramSettings
  );
  yield takeLatest(
    types.UPDATE_COOKIE_PROGRAM_SETTINGS,
    handleUpdateCookieProgramSettings
  );
  yield takeLatest(
    types.DELETE_COOKIE_PROGRAM_SETTINGS,
    handleDeleteCookieProgramSettings
  );
  yield takeEvery(
    types.CHECK_COOKIE_PROGRAM_SETTINGS,
    handleCheckCookieProgramSettings
  );
  yield takeLatest(types.GET_DATA_SETTING_BASIC, handleGetSettingBasic);
  yield takeLatest(types.UPDATE_DATA_SETTING_BASIC, handleUpdateSettingBasic);
  yield takeLatest(types.GET_CROSS_DOMAIN_LIST, handleGetCrossDomainList);
  yield takeLatest(
    types.GET_DATA_MEASUREMENT_SETTING,
    handleGetMeasurementSetting
  );
  yield takeLatest(
    types.UPDATE_MEASUREMENT_SETTING,
    handleUpdateMeasurementSetting
  );
  yield takeLatest(types.GET_DATA_OTHER_SETTING, handleGetOtherSetting);
  yield takeLatest(types.UPDATE_DATA_OTHER_SETTING, handleUpdateOtherSetting);
  yield takeLatest(types.GET_DATA_DNS_SETTING, handleGetDnsSetting);
  yield takeLatest(types.ADD_DATA_DNS_SETTING, handleAddDnsSetting);
  yield takeLatest(types.DELETE_DATA_DNS_SETTING, handleDeleteDnsSetting);
  yield takeLatest(
    types.UPDATE_DATA_DNS_EMAIL_SETTING,
    handleUpdateDnsSettingEmail
  );
  yield takeLatest(
    types.GET_MASTERDATA,
    // sharedSagas.safe(errorHandler, handleGetMasterData)
    handleGetMasterData
  );
}
