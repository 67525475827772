import { isEmpty, isObject, isArray, chain } from 'lodash';
import { getErrorMessageByCode } from 'services/utils';
import { minLine } from 'services/validations/commonValidations';
import * as messageError from 'services/validations/messageErrorByCode';
import {
  cvRules,
  pvRules,
  matchingUrlsRules,
  contentCategoryNameRules,
} from 'services/validations/tagManagementRules';
import tagTypes from 'store/tag-management/types';
import {
  CV_CONDITION,
  CV_URLS,
  ROUTE_URLS,
  USE_FRAGMENT,
  CONTENT_CATEGORY_ID,
  CONTENT_CATEGORY_NAME,
  PAGE_URLS,
  OWNED_MEDIA_FLAG,
} from 'domain/fields';
import {
  TAG_MANAGER_GTM,
  CONDITION_CV,
  TAGS,
  TAG_MANAGEMENT_TAB,
  ATTRIBUTE_FIELDS,
  ATTRIBUTE_LABELS,
  FIELD_REQUEST_CV,
  FIELD_REQUEST_PV,
  TAG_FIELD,
  TABS,
  STEP_CONVERSION,
  STEP_ATTRIBUTE,
  FIELD_FORM_CONTENT_CATEGORY,
  URL_ITEM_MIN,
} from 'domain/tag-management/consts';

const {
  PC_ID,
  BUTTON,
  READ,
  ATTRIBUTE_PC,
  ATTRIBUTE_PC_ID,
  ATTRIBUTE_BUTTON_ATTRIBUTE,
  ONLY_CT,
} = TAGS;

export const getTagMode = ({
  displayMode = 'detail',
  condition = 1,
  hasAttribute = false,
  isUsingGtm = false,
}) => {
  const { PAGE_PV_URL, PAGE_PV_ID, BUTTON_CLICK, SCROLL } = CONDITION_CV;
  const isDisplayModeInfo = displayMode === 'info';

  switch (condition) {
    case PAGE_PV_URL:
      if (!isUsingGtm && isDisplayModeInfo) return ATTRIBUTE_PC;
      return hasAttribute ? ATTRIBUTE_PC : ONLY_CT;

    case PAGE_PV_ID:
      return hasAttribute ? ATTRIBUTE_PC_ID : PC_ID;

    case BUTTON_CLICK:
      if (isUsingGtm) {
        return hasAttribute ? ATTRIBUTE_PC : ONLY_CT;
      }
      return hasAttribute ? ATTRIBUTE_BUTTON_ATTRIBUTE : BUTTON;

    case SCROLL:
      return READ;

    default:
      return ONLY_CT;
  }
};

export const getTagString = ({ tagMode = '', tags = {} }) => {
  if (isEmpty(tags) || isEmpty(tagMode)) return '';

  return tags[tagMode] || '';
};

/**
 * Validate field
 * @param {object} values
 * @param {object} labels
 * @param {object} rules
 * @return object
 */
export const validateDataSettings = (values, labels, rules) => {
  if (isEmpty(values) || isEmpty(rules)) return {};

  return chain(values)
    .mapValues((value, field) => ({
      label: labels[field],
      value: value || null,
    }))
    .mapValues((value, field) => {
      if (isEmpty(rules[field])) return [];

      const rulesByField = [...rules[field]];
      const errors = rulesByField
        .map((rule) => rule(value))
        .filter((rule) => !isEmpty(rule));
      return errors;
    })
    .pickBy((errors) => !isEmpty(errors))
    .mapValues((errors) => errors[0])
    .value();
};

/**
 * Validate each item of urls
 * @param {array} urls
 * @param {string} label
 * @return object
 */
export const validateDataMatchingUrls = (urls, label, hasError = false) => {
  const defaultValue = { value: [], error: hasError };
  if (isEmpty(urls)) return defaultValue;

  const rules = { ...matchingUrlsRules };

  return urls.reduce((acc, item) => {
    let newItem = { ...item };

    const error = validateDataSettings(item, { url: label }, rules);
    if (!isEmpty(error)) {
      newItem = Object.keys(error).reduce((accItem, field) => {
        return { ...accItem, [`${field}_error`]: error[field] };
      }, item);
    }

    return {
      value: [...acc.value, newItem],
      error: acc.error || !isEmpty(error),
    };
  }, defaultValue);
};

/**
 * Check data has errors
 * @param {object} data
 * @return object
 */
export const checkDataSettingError = (data) => {
  return Object.keys(data).reduce(
    (acc, field) => {
      const item = data[field];
      const hasError =
        acc.hasError || !isEmpty(item.error) || item.error === true;

      return {
        hasError,
        stepError: hasError
          ? Math.min(acc.stepError, item.step)
          : acc.stepError,
      };
    },
    { hasError: false, stepError: STEP_ATTRIBUTE }
  );
};

/**
 * Format cv data initial for settings
 * @param {object} form
 * @param {object} data
 * @return object
 */
export const getCvDataSettingInitial = (form, data) => {
  if (isEmpty(data) || !isObject(data)) return form;

  const {
    condition,
    attributes = {},
    gtm_attributes: gtmAttributes = {},
    route_urls: routeUrls = [],
  } = data;

  const pageConditions = [CONDITION_CV.PAGE_PV_URL, CONDITION_CV.PAGE_PV_ID];
  const { PAGE_CONDITION, HAS_ROUTE, ATTRIBUTES, GTM_ATTRIBUTES } = TAG_FIELD;

  return Object.keys(form).reduce((acc, field) => {
    const defaultValue = form[field].value;
    let actualValue = data[field];

    switch (field) {
      case CV_CONDITION:
        if (condition === CONDITION_CV.PAGE_PV_ID) {
          actualValue = CONDITION_CV.PAGE_PV_URL;
        }
        break;
      case PAGE_CONDITION:
        actualValue = pageConditions.includes(condition)
          ? condition
          : defaultValue;
        break;
      case HAS_ROUTE:
        actualValue = routeUrls.length > 0;
        break;
      case ROUTE_URLS:
      case CV_URLS:
        actualValue = (data[field] || []).map((item) => ({
          ...item,
          match_type: `${item.match_type}`,
        }));
        break;
      case ATTRIBUTES:
      case GTM_ATTRIBUTES:
        {
          const attributeData =
            field === ATTRIBUTES ? attributes : gtmAttributes;
          actualValue = ATTRIBUTE_FIELDS.reduce((value, name) => {
            return { ...value, [name]: attributeData[name] };
          }, defaultValue);
        }
        break;
      default:
        break;
    }

    return {
      ...acc,
      [field]: { ...acc[field], value: actualValue ?? defaultValue },
    };
  }, form);
};

/**
 * Format cv data to request
 * @param {object} data
 * @return object
 */
export const getCvDataSettingRequest = (data) => {
  const { PAGE_PV_URL, PAGE_PV_ID } = CONDITION_CV;
  const { ATTRIBUTES, GTM_ATTRIBUTES } = TAG_FIELD;
  const {
    page_condition: { value: pageCondition },
    condition: { value: cvCondition },
    has_attribute: { value: hasAttribute },
    has_route: { value: hasRoute },
  } = data;

  return Object.keys(FIELD_REQUEST_CV).reduce((acc, field) => {
    const defaultValue = FIELD_REQUEST_CV[field];
    const dataField = data[field] || {};
    let actualValue = dataField.value;

    switch (field) {
      case CV_CONDITION:
        if (cvCondition === PAGE_PV_URL && pageCondition === PAGE_PV_ID) {
          actualValue = pageCondition;
        }
        break;
      case ROUTE_URLS:
        actualValue = defaultValue;
        if (hasRoute) {
          actualValue = dataField.value.filter((item) => !!item.url);
        }
        break;
      case CV_URLS:
        actualValue = defaultValue;
        if (cvCondition === PAGE_PV_URL && pageCondition === PAGE_PV_URL) {
          actualValue = dataField.value.filter((item) => !!item.url);
        }
        break;
      case USE_FRAGMENT:
        if (!hasRoute) {
          actualValue = defaultValue;
        }
        break;
      case ATTRIBUTES:
      case GTM_ATTRIBUTES:
        if (!hasAttribute) {
          actualValue = defaultValue;
        } else if (!isEmpty(actualValue)) {
          actualValue = ATTRIBUTE_FIELDS.reduce((value, name) => {
            return { ...value, [name]: dataField.value[name] || '' };
          }, dataField.value);
        }
        break;
      default:
        break;
    }

    return { ...acc, [field]: actualValue ?? defaultValue };
  }, {});
};

/**
 * Format cv data when error
 * @param {object} form
 * @param {array} errors
 * @param {object} params
 * @return object
 */
export const getCvDataSettingError = (form, errors, params = {}) => {
  if (isEmpty(errors) || !isArray(errors)) return form;

  const { ATTRIBUTES, GTM_ATTRIBUTES } = TAG_FIELD;

  return errors.reduce((acc, error) => {
    const [field, index, name] = error.field.split('.');
    const item = { ...acc[field] };

    if (isEmpty(item)) return { ...acc };

    const label = [ATTRIBUTES, GTM_ATTRIBUTES].includes(field)
      ? ATTRIBUTE_LABELS[name]
      : item.label;

    const message = getErrorMessageByCode(error, messageError, {
      ...params,
      label,
    });

    switch (field) {
      case CV_URLS:
      case ROUTE_URLS:
        if (item.value[index]) {
          item.error = true;
          item.value[index][`${name}_error`] = message;
        }
        break;

      case ATTRIBUTES:
      case GTM_ATTRIBUTES:
        item.error = { ...item.error, [index]: message };
        break;

      default:
        item.error = message;
        break;
    }

    return { ...acc, [field]: item };
  }, form);
};

/**
 * Validate cv data input
 * @param {object} data
 * @param {number} currentStep
 * @param {array} list
 * @return object
 */
export const validateCvDataSetting = (data, currentStep) => {
  const {
    cv_urls: cvUrls,
    route_urls: routeUrls,
    has_route: { value: hasRoute },
    condition: { value: cvCondition },
    page_condition: { value: pageCondition },
    attributes: { value: attributes },
    gtm_attributes: { value: gtmAttributes },
    has_attribute: { value: hasAttribute },
    tag_manager_type: { value: tagManagerType },
  } = data;

  // Handle rules to validate
  const ruleValidate = {
    ...cvRules,
    [CV_URLS]: [
      ...cvRules[CV_URLS],
      cvCondition === pageCondition
        ? minLine(URL_ITEM_MIN, messageError.MIN_ITEM_CHECK)
        : () => {},
    ],
  };

  const dataValidate = { ...data };

  // Only get field need to validate
  const { values, labels } = Object.keys(data).reduce(
    (acc, field) => {
      const item = { ...data[field] };
      const skipValidateField =
        currentStep === STEP_ATTRIBUTE || currentStep !== item.step;

      if (skipValidateField) return acc;

      // Remove item has url empty to check min/max items has data
      if ([CV_URLS, ROUTE_URLS].includes(field)) {
        item.value = data[field].value.filter(
          (url) => !!url[TAG_FIELD.MATCH_URL]
        );
      }

      return {
        values: { ...acc.values, [field]: item.value },
        labels: { ...acc.labels, [field]: item.label },
      };
    },
    { values: {}, labels: {} }
  );

  const error = validateDataSettings(values, labels, ruleValidate);

  // Validate each item of cv_urls, route_urls
  if (currentStep === STEP_CONVERSION) {
    if (hasRoute) {
      dataValidate[ROUTE_URLS] = {
        ...routeUrls,
        ...validateDataMatchingUrls(
          routeUrls.value,
          routeUrls.label,
          routeUrls.error
        ),
      };
    }
    if (cvCondition === pageCondition) {
      dataValidate[CV_URLS] = {
        ...cvUrls,
        ...validateDataMatchingUrls(cvUrls.value, cvUrls.label, cvUrls.error),
      };
    }
  }

  // Validate each item of attribites, gtm_attribites
  if (currentStep === STEP_ATTRIBUTE && hasAttribute) {
    const { ATTRIBUTES, GTM_ATTRIBUTES } = TAG_FIELD;
    dataValidate[ATTRIBUTES].error = validateDataSettings(
      attributes,
      ATTRIBUTE_LABELS,
      ruleValidate
    );
    if (tagManagerType === TAG_MANAGER_GTM) {
      dataValidate[GTM_ATTRIBUTES].error = validateDataSettings(
        gtmAttributes,
        ATTRIBUTE_LABELS,
        ruleValidate
      );
    }
  }

  // Update error into each fields
  return Object.keys(error).reduce((acc, field) => {
    return { ...acc, [field]: { ...acc[field], error: error[field] } };
  }, dataValidate);
};

/**
 * Handle error by actions
 * @param {string} currentTab
 * @param {string} action
 * @param {bool} isError
 * @param {array} errors
 * @return object
 */
export const handleErrorResponse = (currentTab, action, isError, errors) => {
  const isDeleteAction = action === tagTypes.DELETE;
  const isUpdateRankPriority =
    action === tagTypes.UPDATE_RANK_PRIORITY_CONTENT_CATEGORY;

  let contentError = '';
  let isErrorCommon = false;
  let isReloadList = !isError && (isDeleteAction || isUpdateRankPriority);
  let isErrorConflictData = false;
  const isErrorSystem = isError && isEmpty(errors);

  if (isError && !isEmpty(errors) && isArray(errors)) {
    const [error] = errors;

    let errorsNeedReloadList = [
      'PAGE_ID_EXIST_CHECK',
      'PAGE_ID_LIMIT_REGISTER_CHECK',
      'PAGE_ID_IS_NOT_CONVERSION_CHECK',
      'CONVERSION_ID_EXIST_CHECK',
      'CONVERSION_ID_LIMIT_REGISTER_CHECK',
      'CONTENT_CATEGORY_LIMIT_REGISTER_CHECK',
      'CONTENT_CATEGORY_RANK_UNIQUE_CHECK',
    ];
    if (currentTab === TAG_MANAGEMENT_TAB.CONTENT_CATEGORY) {
      errorsNeedReloadList = [
        ...errorsNeedReloadList,
        'CONTENT_CATEGORY_ID_EXIST_CHECK',
        'CONTENT_CATEGORY_UPLOAD_SAME_TIME',
      ];
      if (error.code === 'CONTENT_CATEGORY_UPLOAD_SAME_TIME') {
        isErrorConflictData = true;
      }
    }
    const errorsNeedToCheck = [
      ...errorsNeedReloadList,
      'PAGE_ID_LINKED_POSTBACK_CHECK',
      'PAGE_ID_CAPI_CHECK',
      'PAGE_ID_LINE_ADD_FRIEND_CHECK',
    ];
    isReloadList = errorsNeedReloadList.includes(error.code);
    if (errorsNeedToCheck.includes(error.code)) {
      isErrorCommon = true;
      const { label } = TABS.find((tab) => tab.key === currentTab);
      contentError = getErrorMessageByCode(error, messageError, { label });
    }
  }

  const isCloseModal =
    isErrorSystem || isErrorCommon || isDeleteAction || isUpdateRankPriority;

  return {
    title: isErrorConflictData
      ? '並べ替えが正常に処理できませんでした。'
      : `データ${isDeleteAction ? '削除' : '更新'}に失敗しました`,
    content: contentError,
    isCloseModal,
    isErrorCommon,
    isErrorSystem,
    isReloadList,
    isErrorConflictData,
  };
};

export const validateFormContentCategory = (dataValidate = {}) => {
  // Run validate
  const error = validateDataSettings(
    dataValidate,
    FIELD_FORM_CONTENT_CATEGORY,
    contentCategoryNameRules
  );

  // run validate page urls
  if (dataValidate[PAGE_URLS] && dataValidate[PAGE_URLS].length > 0) {
    const { error: hasError, value } = validateDataMatchingUrls(
      dataValidate[PAGE_URLS],
      FIELD_FORM_CONTENT_CATEGORY[PAGE_URLS]
    );
    if (hasError) {
      error.page_urls = value;
    }
  }

  return error;
};

export const getErrorsDataUrls = ({
  pageUrls = [],
  errorsResponse = [],
  label = FIELD_FORM_CONTENT_CATEGORY[PAGE_URLS],
  field = PAGE_URLS,
}) => {
  const cloneDataPageUrls = [...pageUrls];
  const errorsPageUrls = errorsResponse.filter((error) =>
    error?.field.includes(field)
  );
  if (errorsPageUrls.length > 0) {
    errorsPageUrls.map((error) => {
      const arrayFieldError = error.field.split('.');
      const indexError = arrayFieldError[1];
      const typeError = arrayFieldError[2];
      cloneDataPageUrls[indexError] = {
        ...cloneDataPageUrls[indexError],
        [`${typeError}_error`]: getErrorMessageByCode(
          { ...error, PAGE_URLS },
          messageError,
          {
            label,
          }
        ),
      };
      return cloneDataPageUrls;
    });
  }
  return cloneDataPageUrls;
};

export const checkContainFieldError = (errors = [], field = '') => {
  if (isEmpty(errors) || isEmpty(field)) return false;
  return errors.some((error) => error?.field.includes(field));
};

export const formatErrorMessage = (errors = []) => {
  if (isEmpty(errors)) return [];
  return errors.reduce((acc, error) => {
    const field = Object.keys(FIELD_FORM_CONTENT_CATEGORY).find(
      (key) => key === error.field
    );
    return {
      ...acc,
      [field]: getErrorMessageByCode({ ...error, field }, messageError, {
        label: FIELD_FORM_CONTENT_CATEGORY[field],
      }),
    };
  }, {});
};

/**
 * Format pv data initial for settings
 * @param {object} form
 * @param {object} data
 * @return object
 */
export const getPvDataSettingInitial = (form, data) => {
  if (isEmpty(data) || !isObject(data)) return form;

  return Object.keys(form).reduce((acc, field) => {
    const defaultValue = form[field].value;
    let actualValue = data[field];
    if (field === CONTENT_CATEGORY_ID && data[field]) {
      actualValue = { id: data[field], name: data[CONTENT_CATEGORY_NAME] };
    }

    return {
      ...acc,
      [field]: { ...acc[field], value: actualValue ?? defaultValue },
    };
  }, form);
};

/**
 * Format pv data to request
 * @param {object} data
 * @return object
 */
export const getPvDataSettingRequest = (data, hasContractLog = false) => {
  return Object.keys(FIELD_REQUEST_PV).reduce((acc, field) => {
    if (
      !hasContractLog &&
      [CONTENT_CATEGORY_ID, OWNED_MEDIA_FLAG].includes(field)
    ) {
      return acc;
    }
    const defaultValue = FIELD_REQUEST_PV[field];
    let actualValue = data[field]?.value;
    if (field === CONTENT_CATEGORY_ID) {
      actualValue = actualValue.id;
    }

    return { ...acc, [field]: actualValue ?? defaultValue };
  }, {});
};

/**
 * Format pv data when error
 * @param {object} form
 * @param {array} errors
 * @param {object} params
 * @return object
 */
export const getPvDataSettingError = (form, errors, params = {}) => {
  if (isEmpty(errors) || !isArray(errors)) return form;

  return errors.reduce((acc, error) => {
    if (isEmpty(acc[error.field])) return acc;

    return {
      ...acc,
      [error.field]: {
        ...acc[error.field],
        error: getErrorMessageByCode(error, messageError, {
          ...params,
          label: acc[error.field].label,
        }),
      },
    };
  }, form);
};

/**
 * Validate pv data input
 * @param {object} data
 * @param {number} currentStep
 * @param {array} list
 * @return object
 */
export const validatePvDataSetting = (data) => {
  // Get value & label each fields
  const { values, labels } = Object.keys(data).reduce(
    (acc, field) => {
      return {
        values: { ...acc.values, [field]: data[field].value },
        labels: { ...acc.labels, [field]: data[field].label },
      };
    },
    { values: {}, labels: {} }
  );

  const error = validateDataSettings(values, labels, pvRules);

  // Update error into each fields
  return Object.keys(error).reduce((acc, field) => {
    return { ...acc, [field]: { ...acc[field], error: error[field] } };
  }, data);
};

export const getColumnsTableConfirm = (isSortPriorities) => {
  return [
    {
      name: 'line',
      title: '対象行',
      width: 280,
    },
    {
      name: 'message',
      title: isSortPriorities ? 'エラーの理由' : '対応方法',
      width: 0,
    },
  ];
};
