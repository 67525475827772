import React from 'react';
import _ from 'lodash';
import {
  UNREGISTERED_ID_FILTER_KEY,
  MAX_ITEMS_DISPLAY_IN_COLUMN,
} from 'domain/consts';
import {
  ROLE_VIEW_AD,
  ROLE_VIEW_ALL,
  ROLE_VIEW_ALL_AD_ONLY,
  ROLE_SETTING_OTHER_AGENT,
  ROLE_LTV_UNLIMITED,
  ROLE_SETTING_MEDIA_SYNC,
  ROLE_CAPI_AGENT_SETTING,
  ROLE_SEARCH_CONSOLE_SETTING,
  ROLE_LINE_ADD_FRIEND_SETTING,
} from 'domain/permissions/roles';
import { MEDIA, AGENCY_NAME } from 'domain/fields';
import {
  FIELD_REQUEST_API,
  AGENT_SYNC_HEADER,
  AGENT_SYNC_MAX_ITEM_AD_CATEGORY,
  DEFAULT_INDEX_FUNCTION_KEY,
} from 'domain/agency-management/consts';
import {
  AD_GROUP_MAX_VALUE,
  MEDIA_MAX_VALUE,
  TEXT_DATA_MAX,
} from 'domain/ad-management/consts';
import {
  notEqualValue,
  requiredValue,
  maxLength,
  maxLine,
  duplicateValue,
} from 'services/validations/commonValidations';
import { getErrorMessageByCode, arrToStr } from 'services/utils';
import * as messageError from 'services/validations/messageErrorByCode';

export const addNewKeyForDataList = (list, selectedRows) => {
  if (!_.isArray(list)) return [];

  const selected = selectedRows ?? [];

  return list.map((item) => {
    const media = item.media.map((v) => v.media_name);
    const adGroup1 = item.ad_group1.map((v) => v.ad_group_name);
    const adGroup2 = item.ad_group2.map((v) => v.ad_group_name);

    return {
      ...item,
      rowId: item.agency_id,
      selected: selected.includes(item.agency_id),
      media_name: arrToStr(media, true, MAX_ITEMS_DISPLAY_IN_COLUMN),
      ad_group1_name: arrToStr(adGroup1, true, MAX_ITEMS_DISPLAY_IN_COLUMN),
      ad_group2_name: arrToStr(adGroup2, true, MAX_ITEMS_DISPLAY_IN_COLUMN),
      view_all: item.agency_roles.some((role) =>
        [ROLE_VIEW_ALL, ROLE_VIEW_ALL_AD_ONLY].includes(role)
      ) ? (
        <i className="fas fa-check" />
      ) : null,
      view_ad: item.agency_roles.includes(ROLE_VIEW_AD) ? (
        <i className="fas fa-check" />
      ) : null,
      agent_setting: item.agency_roles.some((role) =>
        [ROLE_SETTING_OTHER_AGENT, ROLE_LTV_UNLIMITED].includes(role)
      ) ? (
        <i className="fas fa-check" />
      ) : null,
      media_sync_setting: item.agency_roles.includes(
        ROLE_SETTING_MEDIA_SYNC
      ) ? (
        <i className="fas fa-check" />
      ) : null,
      capi_agent_setting: item.agency_roles.includes(
        ROLE_CAPI_AGENT_SETTING
      ) ? (
        <i className="fas fa-check" />
      ) : null,
      search_console_setting: item.agency_roles.includes(
        ROLE_SEARCH_CONSOLE_SETTING
      ) ? (
        <i className="fas fa-check" />
      ) : null,
      line_add_friend_agent_setting: item.agency_roles.includes(
        ROLE_LINE_ADD_FRIEND_SETTING
      ) ? (
        <i className="fas fa-check" />
      ) : null,
    };
  });
};

export const formatDataOptions = (data) => {
  if (!_.isObject(data)) return {};

  return Object.keys(data)
    .filter((id) => id !== UNREGISTERED_ID_FILTER_KEY)
    .reduce(
      (obj, id) => ({
        ...obj,
        [id]: {
          label: data[id]?.name ?? '',
          value: id,
        },
      }),
      {}
    );
};

export const convertDataState = (data, keyName, keyId) => {
  if (!_.isArray(data) || !keyName || !keyId) return {};

  return data.reduce((obj, item) => {
    if (item[keyId] === undefined) return obj;

    return {
      ...obj,
      [item[keyId]]: {
        label: item[keyName],
        value: item[keyId],
      },
    };
  }, {});
};

export const convertDataDetailResponse = (data) => {
  if (_.isEmpty(data) || !_.isObject(data)) return {};

  const { agency_roles: agencyRoles = [] } = data;
  return {
    ...data,
    agency_roles: agencyRoles.includes(ROLE_VIEW_ALL)
      ? [...agencyRoles, ROLE_VIEW_ALL_AD_ONLY]
      : agencyRoles,
  };
};

export const validateForm = (label, dataList, dataValidate, ruleValidate) => {
  // Add new rule
  const ruleClone = { ...ruleValidate };
  const { agency_id: agencyId = 0 } = dataValidate;
  const agencyNames = dataList
    .filter((item) => item.agency_id !== agencyId)
    .map((item) => item.agency_name);
  const agencyNameRules = [...ruleClone[AGENCY_NAME]];
  agencyNameRules.push(
    notEqualValue(agencyNames, messageError.AGENCY_NAME_UNIQUE_CHECK)
  );
  ruleClone[AGENCY_NAME] = agencyNameRules;

  // Run validate
  const error = _.chain(dataValidate)
    .mapValues((value, key) => ({
      label: label[key],
      value: _.isEmpty(value) ? null : value,
    }))
    .mapValues((value, key) => {
      if (_.isEmpty(ruleClone[key])) return [];

      const rules = [...ruleClone[key]];

      const errors = rules
        .map((rule) => rule(value))
        .filter((rule) => !_.isEmpty(rule));

      return errors;
    })
    .pickBy((errors) => !_.isEmpty(errors))
    .mapValues((errors) => errors[0])
    .value();

  return error;
};

export const validateRegisterMasterdata = (
  name,
  label,
  dataValidate,
  ruleValidate
) => {
  // Validate root data
  const rules = {
    required: requiredValue(),
    maxLine: maxLine(name === MEDIA ? MEDIA_MAX_VALUE : AD_GROUP_MAX_VALUE),
    maxLength: maxLength(TEXT_DATA_MAX),
    duplicate: duplicateValue(),
  };

  const items = dataValidate.split('\n');

  let errors = Object.keys(rules).reduce((acc, key) => {
    const messages = [...acc];
    const rule = rules[key];
    const message = rule({
      label,
      value: ['maxLine', 'duplicate'].includes(key) ? items : dataValidate,
    });

    if (message) messages.push(message);

    return messages;
  }, []);

  if (errors.length > 0) return errors[0];

  // validate data by line
  errors = items.reduce((acc, data) => {
    const messages = [...acc];
    const field = { value: data, label };
    ruleValidate
      .filter((rule) => rule(field))
      .map((rule) => messages.push(rule(field)));

    return messages;
  }, []);

  return errors[0] || '';
};

export const formatErrorMessage = (errors, labels) => {
  return errors.reduce((acc, error) => {
    const field = Object.keys(FIELD_REQUEST_API).find(
      (key) => FIELD_REQUEST_API[key] === error.field
    );
    return {
      ...acc,
      [field]: getErrorMessageByCode({ ...error, field }, messageError, {
        label: labels[field],
      }),
    };
  }, {});
};

const formatNameSync = (value, isSynced) => {
  if (!isSynced) return value;

  return <span className="ebis--disabled">{`${value}*`}</span>;
};

const formatMasterdataSync = (data) => {
  return data
    .slice(0, AGENT_SYNC_MAX_ITEM_AD_CATEGORY)
    .reduce((acc, item, index) => {
      if (index + 1 === AGENT_SYNC_MAX_ITEM_AD_CATEGORY) return [...acc, '...'];

      return [...acc, formatNameSync(item.name, item.is_synced)];
    }, []);
};

export const checkSyncedData = (data) => {
  return data.every((item) => item.is_synced);
};

export const prepareAgentSyncHeader = (displayConfig) => {
  const displayName = {
    old_ad_group1: displayConfig.ad_group1 || '広告グループ1',
    old_ad_group2: displayConfig.ad_group2 || '広告グループ2',
    new_ad_group1: displayConfig.ad_group1 || '広告グループ1',
    new_ad_group2: displayConfig.ad_group2 || '広告グループ2',
  };

  return AGENT_SYNC_HEADER.reduce((acc, item) => {
    if (!['before_sync_agency', 'after_sync_agency'].includes(item.field)) {
      return [...acc, item];
    }

    return [
      ...acc,
      {
        ...item,
        children: item.children.map((child) => {
          return { ...child, name: displayName[child.field] || child.name };
        }),
      },
    ];
  }, []);
};

export const prepareAgentSyncData = (data, { createButtonRedirect }) => {
  if (_.isEmpty(data)) return [];

  return data.reduce((acc, item) => {
    const {
      before_sync_agency: oldAgency,
      after_sync_agency: newAgency,
    } = item;

    const isExistedAgency = !!item.agency_id;

    const syncedData = {
      new_company_name: [{ is_synced: isExistedAgency }],
      new_media: newAgency.media,
      new_ad_group1: newAgency.ad_group1,
      new_ad_group2: newAgency.ad_group2,
      cnt_link_ad: [{ is_synced: +item.cnt_link_ad === 0 }],
    };

    const highlightCells = Object.keys(syncedData).filter(
      (key) => !checkSyncedData(syncedData[key])
    );

    const dataItem = {
      highlightCells,
      isDisabledCheckbox: item.is_synced,
      tooltipCheckbox:
        '旧バージョンで設定されている情報は全て新バージョンに移行されているため、移行不要です。',
      rowId: item.user_id,
      user_id: item.user_id,
      old_company_name: oldAgency.company_name,
      old_media: formatMasterdataSync(oldAgency.media),
      old_ad_group1: formatMasterdataSync(oldAgency.ad_group1),
      old_ad_group2: formatMasterdataSync(oldAgency.ad_group2),
      icon: <i className="fas fa-chevron-right" />,
      new_company_name: formatNameSync(newAgency.company_name, isExistedAgency),
      new_media: formatMasterdataSync(newAgency.media),
      new_ad_group1: formatMasterdataSync(newAgency.ad_group1),
      new_ad_group2: formatMasterdataSync(newAgency.ad_group2),
      cnt_link_ad: createButtonRedirect(item),
    };

    return [...acc, dataItem];
  }, []);
};

export const addHeaderObject = (resultHeader, newObj) => {
  const indexNewRoleAgent =
    resultHeader.findIndex((item) => item.field === 'function') ||
    DEFAULT_INDEX_FUNCTION_KEY;

  resultHeader[indexNewRoleAgent] = {
    ...resultHeader[indexNewRoleAgent],
    children: [...resultHeader[indexNewRoleAgent].children, newObj],
  };
  resultHeader[indexNewRoleAgent].children.push();
  return resultHeader;
};
