import {
  CONTENT_CATEGORY_NAME,
  OWNED_MEDIA_FLAG,
  PAGE_ID,
  PAGE_TITLE,
  PAGE_URL,
  REGIST_DATE,
  UPDATE_DATE,
  CONTENT_CATEGORY_ID,
} from 'domain/fields';
import * as LABELS from 'domain/labels';

export const LIMIT_PER_PAGE = 200;
export const PAGE_TITLE_MAX = 200;

export const TEMPLATE_UPDATE_PAGE =
  '/templates/page-management/page_log_update.csv';
export const TEMPLATE_DELETE_PAGE =
  '/templates/page-management/page_log_delete.csv';

export const MODAL_IDS = {
  UPDATE: 'update',
  UPLOAD_DELETE: 'upload_delete',
  UPLOAD_UPDATE: 'upload_update',
  CONFIRM_DELETE: 'confirm_delete',
  ERROR: 'error',
};

export const HEADER = [
  {
    field: 'rowId',
    fieldResponse: PAGE_ID,
    name: '',
    type: 'checkbox',
    isFreeze: true,
    isEdit: true,
  },
  {
    field: PAGE_ID,
    name: LABELS.PAGE_ID,
    isFreeze: true,
  },
  {
    field: PAGE_TITLE,
    name: 'ページタイトル',
  },
  {
    field: PAGE_URL,
    name: 'ページURL',
  },
  {
    field: OWNED_MEDIA_FLAG,
    name: LABELS.OWNED_MEDIA,
    isContentCenter: true,
  },
  {
    field: CONTENT_CATEGORY_NAME,
    name: LABELS.CONTENT_CATEGORY,
  },
  {
    field: REGIST_DATE,
    name: LABELS.REGIST_DATE,
  },
  {
    field: UPDATE_DATE,
    name: LABELS.UPDATE_DATE,
  },
];

export const COLUMNS_TABLE_CONFIRM_DELETE = [
  {
    name: PAGE_ID,
    title: LABELS.PAGE_ID,
    width: 140,
  },
  {
    name: PAGE_TITLE,
    title: 'タイトル',
    width: 115,
  },
  {
    name: PAGE_URL,
    title: 'ページURL',
    width: 125,
  },
  {
    name: 'comment',
    title: 'メッセージ',
    width: 0,
  },
];

export const UPLOAD_LIST = [
  {
    key: MODAL_IDS.UPLOAD_UPDATE,
    title: 'CSV一括更新',
  },
  {
    key: MODAL_IDS.UPLOAD_DELETE,
    title: 'CSV一括削除',
  },
];

export const FIELD_FORM_EDIT = {
  [PAGE_ID]: {
    label: LABELS.PAGE_ID,
    value: '',
  },
  [PAGE_TITLE]: {
    label: 'タイトル',
    value: '',
    note: '200文字以内',
    tooltip:
      'ページの識別が可能なわかりやすい任意のタイトルを入力してください。\n 例）PC購入完了、問合せ',
  },
  [OWNED_MEDIA_FLAG]: {
    label: LABELS.OWNED_MEDIA,
    value: false,
  },
  [CONTENT_CATEGORY_ID]: {
    label: LABELS.CONTENT_CATEGORY,
    value: {},
    tooltip:
      '複数のページIDをグルーピングすることができます。\nタグ管理＞コンテンツカテゴリで登録した、コンテンツカテゴリが選択可能となります。',
  },
};

export const FIELD_IMPORT_CSV = {
  ...FIELD_FORM_EDIT,
  [CONTENT_CATEGORY_NAME]: {
    label: LABELS.CONTENT_CATEGORY,
  },
};
