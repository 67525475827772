import React from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import UploadCsvPopupMenu from 'views/molecules/UploadCsvPopupMenu';
import UploadCsvDropdown from 'views/atoms/dropdown/UploadCsvDropdown';
import { getButtonToolTip } from 'domain/utils';
import 'views/molecules/control-bar/control-bar.scss';
import { UNLIMITED_VALUE, CSV_MAX_SIZE } from 'domain/consts';

function ControlBar({
  page,
  currentItems,
  maximumItems,
  checkedItemsNum,
  handleShowSettingModal,
  setShowConfirmModal,
  handleShowUploadModal,
  handleShowCopyModal,
  buttonConfirmLabel,
  isSelectedItemOutOfAd,
  onDownloadTemplate,
  buttonAddTooltipText,
  customSubjectText,
  handleSortList,
  isShowDeleteButton,
  disabledRegister,
  csvConfig,
  uploadModalDropdownList,
  onSelectUploadDropdown,
  handleOpenUploadModal,
  uploadModalOptionNode,
  disableUploadModalDefaultTemplateBtn,
  pageSubTitle,
  messageValidate,
  handleShowStopLinked,
  overlayState: {
    placementTrigger = 'top',
    classNameTooltip = '',
    variantTooltip = '',
  },
  disabledBtnStopLinked,
}) {
  const buttonLabel = buttonConfirmLabel || '削除';

  const isDisabledRegister = disabledRegister || currentItems >= maximumItems;

  const onClickCopy = (isAllowed) => {
    if (isAllowed) {
      handleShowCopyModal(true);
    }
  };

  const controlClass = classNames({
    controls: true,
    'controls-not-show__delete': !isShowDeleteButton,
  });

  const trigger = () => {
    if (disabledRegister) return ['hover'];
    if (isDisabledRegister) return '';
    return ['hover', 'click'];
  };
  return (
    <div className={controlClass}>
      {pageSubTitle && <div className="page-sub-title">{pageSubTitle}</div>}
      <div className="controls__button">
        {handleShowSettingModal && (
          <OverlayTrigger
            placement={placementTrigger}
            trigger={trigger()}
            overlay={
              <Tooltip
                variant={variantTooltip}
                className={`tooltip-common ${classNameTooltip}`}
              >
                {buttonAddTooltipText || getButtonToolTip(page, '追加')}
              </Tooltip>
            }
          >
            <div className="btn p-0 border-0 cursor-default">
              <Button
                variant="secondary"
                size="sm"
                className="btn-icon--no-text rounded-circle"
                disabled={isDisabledRegister}
                onClick={() => handleShowSettingModal(null)}
              >
                <i className="fal fa-plus" style={{ fontSize: 22 }} />
              </Button>
            </div>
          </OverlayTrigger>
        )}
        {handleShowUploadModal && (
          <UploadCsvPopupMenu
            screenId={page}
            config={csvConfig}
            onChange={handleShowUploadModal}
            onDownloadTemplate={onDownloadTemplate}
            customSubjectText={customSubjectText}
            handleOpenUploadModal={handleOpenUploadModal}
            uploadModalOptionNode={uploadModalOptionNode}
            disableDefaultTemplateBtn={disableUploadModalDefaultTemplateBtn}
            messageValidate={messageValidate}
          />
        )}
        {uploadModalDropdownList.length > 0 && (
          <UploadCsvDropdown
            items={uploadModalDropdownList}
            onSelect={onSelectUploadDropdown}
          />
        )}
      </div>

      {isShowDeleteButton && (
        <div className="controls__selected">
          <span>{checkedItemsNum}</span>
          <span>件選択中</span>
        </div>
      )}
      <div className="controls__button">
        {handleShowCopyModal && (
          <OverlayTrigger
            placement="top"
            trigger={isSelectedItemOutOfAd ? ['hover', 'click'] : ''}
            overlay={
              <Tooltip className="tooltip-common">
                広告以外はコピーできません
              </Tooltip>
            }
          >
            <Button
              size="sm"
              variant="secondary"
              disabled={checkedItemsNum === 0}
              className={isSelectedItemOutOfAd ? 'disabled' : ''}
              onClick={() =>
                onClickCopy(!isSelectedItemOutOfAd && checkedItemsNum > 0)
              }
            >
              コピー
            </Button>
          </OverlayTrigger>
        )}
        {handleShowStopLinked && (
          <Button
            size="sm"
            variant="secondary"
            disabled={disabledBtnStopLinked || checkedItemsNum === 0}
            onClick={handleShowStopLinked}
            className=""
          >
            一時停止
          </Button>
        )}
        {isShowDeleteButton && (
          <Button
            size="sm"
            variant="secondary"
            disabled={checkedItemsNum === 0}
            onClick={() => setShowConfirmModal(true)}
            className={handleSortList ? 'controls__button-margin-right' : ''}
          >
            {buttonLabel}
          </Button>
        )}
        {handleSortList && (
          <div className="controls__button-sort">
            <Button
              size="sm"
              variant="secondary"
              disabled={currentItems === 0}
              onClick={handleSortList}
            >
              優先順位の並べ替え
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
ControlBar.propTypes = {
  currentItems: PropTypes.number,
  maximumItems: PropTypes.number,
  checkedItemsNum: PropTypes.number,
  handleShowSettingModal: PropTypes.func,
  setShowConfirmModal: PropTypes.func,
  handleShowCopyModal: PropTypes.func,
  handleShowUploadModal: PropTypes.func,
  handleShowStopLinked: PropTypes.func,
  buttonConfirmLabel: PropTypes.string,
  page: PropTypes.string,
  isSelectedItemOutOfAd: PropTypes.bool,
  onDownloadTemplate: PropTypes.func,
  buttonAddTooltipText: PropTypes.string,
  customSubjectText: PropTypes.string,
  handleSortList: PropTypes.func,
  isShowDeleteButton: PropTypes.bool,
  disabledRegister: PropTypes.bool,
  csvConfig: PropTypes.shape({
    isMergeError: PropTypes.bool,
    maxLine: PropTypes.number,
    maxSize: PropTypes.number,
    field: PropTypes.shape({}),
    minSize: PropTypes.number,
  }),
  uploadModalDropdownList: PropTypes.shape([]),
  onSelectUploadDropdown: PropTypes.func,
  handleOpenUploadModal: PropTypes.func,
  uploadModalOptionNode: PropTypes.node,
  disableUploadModalDefaultTemplateBtn: PropTypes.bool,
  pageSubTitle: PropTypes.string,
  messageValidate: PropTypes.shape({
    maxSize: PropTypes.string,
    minSize: PropTypes.string,
  }),
  overlayState: {
    placementTrigger: PropTypes.string,
    classNameTooltip: PropTypes.string,
    variantTooltip: PropTypes.string,
  },
  disabledBtnStopLinked: PropTypes.bool,
};

ControlBar.defaultProps = {
  currentItems: 0,
  maximumItems: 0,
  checkedItemsNum: 0,
  handleShowSettingModal: null,
  setShowConfirmModal: null,
  handleShowUploadModal: null,
  handleShowCopyModal: null,
  handleShowStopLinked: null,
  buttonConfirmLabel: null,
  page: null,
  isSelectedItemOutOfAd: false,
  onDownloadTemplate: null,
  buttonAddTooltipText: null,
  customSubjectText: null,
  handleSortList: null,
  isShowDeleteButton: true,
  disabledRegister: false,
  csvConfig: {
    isMergeError: false,
    maxLine: UNLIMITED_VALUE,
    maxSize: CSV_MAX_SIZE,
    field: {}, // { <key>: { label: '', message: {} } }
    minSize: null,
  },
  uploadModalDropdownList: [],
  onSelectUploadDropdown: () => {},
  handleOpenUploadModal: () => {},
  uploadModalOptionNode: <></>,
  disableUploadModalDefaultTemplateBtn: false,
  pageSubTitle: null,
  messageValidate: {},
  overlayState: {
    placementTrigger: 'top',
    classNameTooltip: '',
    variantTooltip: null,
  },
  disabledBtnStopLinked: false,
};
export default ControlBar;
